<app-navbar [loading]="loading" backroute=".." [leadId]="lead?.id" class="sticky-top">
    <a class="navbar-brand">
        <span>&nbsp;<b>Commesse / {{lead?.tracking_code}} - {{lead?.partner_id.name}}</b></span>
    </a>

    <div class="ms-auto dropdown">
        <button class="btn btn-link text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bars text-white"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" target="_blank"
                    href="//o3.galimberti.eu/web#id={{lead?.id}}&cids=1&menu_id=331&action=504&model=crm.lead&view_type=form">Apri
                    in Odoo</a></li>
            <li><a class="dropdown-item" target="_blank" (click)="delete()">Elimina</a></li>
        </ul>
    </div>
</app-navbar>

<!-- Stage Navigation -->
<nav class="bg-light border-bottom p-3">
    <div class="d-flex align-items-center gap-3">
        <ng-container *ngFor="let s of stages; let i = index">
            <div class="d-flex align-items-center">
                <a class="text-decoration-none" (click)="toStatus(s)" [class.text-primary]="s.id == lead?.stage_id?.id"
                    [class.text-muted]="s.id != lead?.stage_id?.id">
                    {{s.name}}
                </a>
                <i class="fa-solid fa-chevron-right ms-3 text-muted" *ngIf="i < stages.length - 1"></i>
            </div>
        </ng-container>
    </div>
</nav>

<div class="container-fluid p-4">
    <div class="row g-4 " style="max-width: 90%">
        <!-- Lead Information Card -->
        <div class="col-md-4" *ngIf="canShowRight">
            <div class="card shadow-sm mb-4">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Informazioni Commessa</h5>
                </div>
                <div class="card-body">
                    <!-- Title -->
                    <div class="mb-3">
                        <ng-container *ngIf="getStage().sequence > 0">
                            <h5 class="mb-2">{{lead.tracking_code}} - {{lead.name}}</h5>
                        </ng-container>
                        <input *ngIf="getStage().sequence == 0" class="form-control form-control-lg"
                            placeholder="*Inserisci descrizione commessa*" [(ngModel)]="lead.name"
                            (change)="updateDealToOdoo('name')">
                    </div>

                    <!-- Contact Information -->
                    <div class="mb-3">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <label class="form-label text-muted mb-0">Cliente</label>
                            <div class="dropdown">
                                <button class="btn btn-link btn-sm p-0" type="button" title="Modifica cliente"
                                    data-bs-toggle="dropdown">
                                    <i class="fa-solid fa-user-pen"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" style="min-width: 300px;z-index:9999999">
                                    <form class="px-3" style="min-width: 440px;">
                                        <app-contact [mode]="'embedded'" class="embedded"
                                            (onSelect)="onContact($event)">
                                        </app-contact>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <h5 class="ms-3 mb-0">{{lead.partner_id.name}}</h5>
                    </div>

                    <!-- Area -->
                    <div class="dropdown mb-3">
                        <label class="form-label text-muted mb-2">Area</label>
                        <div class="d-block ms-3">
                            <div class="badge" [ngClass]="getAreaBadgeClass(lead.area)" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false" style="cursor: pointer;">
                                {{lead.area}}
                                <i class="fa-solid fa-chevron-down ms-1"></i>
                            </div>
                            <ul class="dropdown-menu">
                                <li
                                    *ngFor="let area of ['Tetti', 'Case', 'Facciate e Decking', 'Aziendale', 'Pavimenti', 'Massello']">
                                    <a class="dropdown-item d-flex align-items-center" (click)="assignArea(area)"
                                        [class.active]="area === lead.area">
                                        {{area}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- user -->
                    <div class="dropdown mb-3">
                        <label class="form-label text-muted mb-2">Responsabile commessa</label>
                        <h6>
                            <div class="d-block">
                                <div class="badge bg-primary ms-3" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false" style="cursor: pointer;">
                                    {{lead.user_id.name}}
                                    <i class="fa-solid fa-chevron-down ms-1"></i>
                                </div>
                                <ul class="dropdown-menu">
                                    <li *ngFor="let user of users">
                                        <a class="dropdown-item" (click)="assignUser(user)"
                                            [class.active]="user.id === lead.user_id.id">
                                            {{user.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </h6>
                    </div>

                    <!-- Address -->
                    <div class="mb-3">
                        <label class="form-label text-muted mb-2">Indirizzo cantiere</label>
                        <input #placesRef="ngx-places" ngx-google-places-autocomplete class="form-control ms-3"
                            [(ngModel)]="lead.street" (onAddressChange)="onAddressChange($event)">
                    </div>

                    <!-- Delivery Type -->
                    <div class="mb-3">
                        <label class="form-label text-muted mb-2">Modalità fornitura</label>
                        <div class="d-flex align-items-center gap-2 ms-3">
                            <button class="btn btn-sm" (click)="toggleTag('Fornitura')"
                                [class.btn-muted]="hasTag('Fornitura')"
                                [class.btn-outline-muted]="!hasTag('Fornitura')">
                                Fornitura
                            </button>
                            <button class="btn btn-sm" (click)="toggleTag('Fornitura e posa')"
                                [class.btn-muted]="hasTag('Fornitura e posa')"
                                [class.btn-outline-muted]="!hasTag('Fornitura e posa')">
                                Fornitura e posa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Right Column -->
        <div class="col-md-8" *ngIf="canShowRight">
            <!-- Drive & Trello -->
            <div class="card shadow-sm mb-4" *ngIf="shouldShowSection('driveAndTrello')">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Drive & Trello</h5>
                </div>
                <div class="card-body p-0">
                    <div class="d-flex border-bottom">
                        <!-- Preventivo -->
                        <div class="d-flex flex-column align-items-center border-end py-3 flex-fill">
                            <app-connect-to type="Preventivo" [deal]="lead" [driveSource]="cfg?.src"
                                [driveTarget]="cfg?.dst" [driveFolders]="filterDriveFolders('Preventivo')"
                                [trelloCards]="filterTrelloCards('Preventivo')"
                                [trelloListId]="cfg?.preventivo_trello_list"
                                [trelloBoardId]="cfg?.preventivo_trello_board" [connectable]="lead" [pos]="'top'"
                                (onConnect)="loadDeal()">
                            </app-connect-to>
                            <small class="mt-2">Preventivo</small>
                        </div>

                        <!-- Progetto -->
                        <div class="d-flex flex-column align-items-center border-end py-3 flex-fill">
                            <app-connect-to type="Progetto" [deal]="lead" [connectable]="lead"
                                [driveSource]="cfg?.project_src" driveIsMerge="true"
                                [driveTarget]="filterDriveFolders('Preventivo')[0]?.value"
                                [driveFolders]="filterDriveFolders('Progetto')"
                                [trelloCards]="filterTrelloCards('Progetto')" [trelloListId]="cfg?.project_trello_list"
                                [trelloBoardId]="cfg?.project_trello_board" [trelloTemplateCard]="cfg?.project_trello"
                                [skipAttachments]="true" [pos]="'bottom'" (onConnect)="loadDeal()"
                                (loading)="loading = true">
                            </app-connect-to>
                            <small class="mt-2">Progetto</small>
                        </div>

                        <!-- POS -->
                        <div class="d-flex flex-column align-items-center py-3 flex-fill">
                            <app-connect-to type="POS" [deal]="lead" [driveSource]="cfg?.pos_src"
                                [driveTarget]="cfg?.pos_dst" [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                [driveLinkTitle]="cfg.pos_link_name" [driveFolders]="filterDriveFolders('POS')"
                                [trelloCards]="filterTrelloCards('POS')" [trelloListId]="cfg?.pos_trello_list"
                                [trelloBoardId]="cfg?.pos_trello_board" [trelloTemplateCard]="cfg?.pos_template_card"
                                [connectable]="lead" (onConnect)="loadDeal()" (loading)="loading = true">
                            </app-connect-to>
                            <small class="mt-2">POS</small>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Preventivi -->
            <div class="card shadow-sm mb-4" *ngIf="shouldShowSection('preventivi')">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Preventivi su lista</h5>
                    <button class="btn btn-link ms-auto " title="Espandi" (click)="toggleResolvePart(offersPart)" [disabled]="offersPart?.sale_order_ids.ids.length == 0">
                        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                    </button>

                    <!-- nuovo preventivo -->
                    <div class="dropdown">
                        <button class="btn btn-link text-white" type="button" (click)="attachNewOffer()"
                            title="Aggiungi nuovo preventivo" [disabled]="offersPart && !offersPart?.sale_order_ids.values && !offersPart?.sale_order_ids.ids.length == 0 ">
                            <i class="fa fa-plus text-primary fa-lg"></i>
                        </button>
                    </div>
                </div>

                <!-- righe preventivi -->

                <div *ngIf="offersPart && offersPart?.sale_order_ids.values" class="border" cdkDropList
                    id='part-{{offersPart.id}}' [cdkDropListConnectedTo]="partIds" [cdkDropListData]="offersPart"
                    (cdkDropListDropped)="drop($event)">


                    <div *ngFor="let s of offersPart?.sale_order_ids.values" cdkDrag [cdkDragData]="s">

                        <div class="border-bottom d-flex flex-row align-items-center py-2">
                            <i class="fa fa-bars fa-large mx-3"></i>



                            <a [routerLink]="['/leads', lead.id , 'sale' , s.id]" queryParamsHandling="preserve"
                                class="ps-3 text-nowrap">
                                <p class="mb-0 pb-0">{{s.name}}</p>
                            </a>

                            <input class="ms-3 flex-fill text-muted form-control bg-transparent border-0 px-1"
                                [ngModel]="s.ga_title" (ngModelChange)="renameSale(s, $event)"
                                [ngModelOptions]="{'updateOn':'blur'}">

                            <div class="ps-2 pe-3 ms-auto">
                                <div class="d-flex align-items-center justify-content-end text-nowrap"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <!-- Billing Component: too big to do here -->
            <app-billing *ngIf="shouldShowSection('billing')" [lead]="lead" (loading)="loading = $event">
            </app-billing>

            <!-- Production Section -->
            <div class="card shadow-sm mb-4" *ngIf="shouldShowSection('production')">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Produzione</h5>
                    <div class="dropdown ms-auto">
                        <button class="btn btn-link " type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" title="Importa sotto-commesse da template">
                            <i class="fa fa-file-import fa-lg"></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let i of importableLeads">
                                <a class="dropdown-item" target="_blank" (click)="importLead(i)">{{i.name}}</a>
                            </li>
                        </ul>
                    </div>

                    <button class="btn btn-link " title="Espandi" (click)="expandAll()" [disabled]="parts.length==0">
                        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                    </button>

                    <!-- nuovo gruppo -->
                    <div class="dropdown">
                        <button class="btn btn-link text-white" type="button" (click)="attachNewPart()"
                            title="Aggiungi sotto-commessa">
                            <i class="fa fa-plus text-primary fa-lg"></i>
                        </button>
                    </div>

                </div>

                <div class="d-flex flex-column">

                    <div *ngFor="let p of parts | sortBy:'name'" class="border" cdkDropList id='part-{{p.id}}'
                        [cdkDropListConnectedTo]="partIds" [cdkDropListData]="p" (cdkDropListDropped)="drop($event)">
                        <div class="border-bottom bg-light  d-flex flex-row align-items-center py-2">
                            <!-- <i class="fa fa-bars fa-large ms-3" cdkDragHandle ></i> -->

                            <i *ngIf="!p.sale_order_ids.values" class="px-3 fa-solid fa-caret-right"
                                title="Espandi sotto-commessa" (click)="toggleResolvePart(p)"></i>
                            <i *ngIf="p.sale_order_ids.values" class="px-3 fa-solid fa-caret-down"
                                title="Comprimi sotto-commessa" (click)="toggleResolvePart(p)"></i>

                            <input class="form-control bg-transparent border-0 " [ngModel]="p.name"
                             placeholder="*Inserisci nome sotto-commessa*"
                                [ngModelOptions]="{'updateOn':'blur'}" (ngModelChange)="updatePart(p, 'name', $event)">


                            <button class="btn btn-link text-white me-1" type="button" (click)="attachNewSale(p)"
                                title="Nuovo ordine">
                                <i class="fa fa-plus text-primary"></i>
                            </button>
                            <button class="btn btn-link text-white me-1" type="button" (click)="deletePart(p)"
                                title="Rimuovi sotto-commessa">
                                <i class="fa-solid fa-trash text-primary"></i>
                            </button>
                        </div>

                        <ng-container *ngIf="p.sale_order_ids.values">

                            <div *ngFor="let s of p.sale_order_ids.values  | sortBy:'ga_order'" cdkDrag
                                [cdkDragData]="s">

                                <div class="border-bottom d-flex flex-row align-items-center py-2 ">

                                    <i class="fa fa-bars fa-large mx-3"></i>

                                    <a [routerLink]="['/leads', lead.id , 'sale' , s.id]" queryParamsHandling="preserve"
                                        class=" text-nowrap pe-2" target="_blank"
                                        >
                                        <p class="mb-0 pb-0">{{s.name}}</p>
                                    </a>

                                    <!-- function could be used to get the output string and colours insted of here                                          -->

                                    <span title="Confermato" class="badge bg-primary "
                                        *ngIf="s._delivery_state =='Confermato'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Spedito interamente" class="badge  bg-success "
                                        *ngIf="s._delivery_state =='Spedito interamente'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Confermato - acquisti arrivati" class="badge  bg-primary "
                                        *ngIf="s._delivery_state =='Confermato - acquisti arrivati'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Preparato - non spedito" class="badge  bg-warning "
                                        *ngIf="s._delivery_state =='Preparato - non spedito'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Spedito parzialmente" class="badge  bg-warning "
                                        *ngIf="s._delivery_state =='Spedito parzialmente'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Bozza" class="badge bg-dark "
                                        *ngIf="s._delivery_state =='Bozza'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Annullato" class="badge bg-light text-dark"
                                        *ngIf="s._delivery_state =='Annullato'">{{s._delivery_state}}&nbsp;</span>



                                    <input class="ms-3 flex-fill text-muted form-control bg-transparent border-0 px-1"
                                        [ngModel]="s.ga_title" (ngModelChange)="renameSale(s, $event)"
                                        placeholder="*Inserisci descrizione ordine*"
                                        [ngModelOptions]="{'updateOn':'blur'}">

                                    <div class="ps-2 pe-3 ms-auto">

                                        <!-- <span *ngIf="isFirstSale(s)" class="text-danger">PRIMANOTA</span><br> -->
                                        <div class="d-flex align-items-center justify-content-end text-nowrap">


                                            <app-activity-scheduler
                                                *ngIf="(s.state == 'sale' && s.delivery_status != 'full' && s.delivery_status)"
                                                class="mb-2" [sale]="s"
                                                (loading)="loading = $event"></app-activity-scheduler>

                                            <span *ngIf="(s.state != 'sale')" class="text-muted mb-2 me-2">
                                                <button class="btn btn-sm bg-muted text-white" type="button"
                                                    [routerLink]="['/leads', lead.id , 'sale' , s.id]">
                                                    Conferma
                                                </button>
                                            </span>


                                            <app-connect-to class="d-block-inline" [connectable]="s" type="Produzione"
                                                [deal]="lead" [driveFolders]="filterProductionDriveFolders(s)"
                                                [driveSource]="cfg?.produzione_src" [driveTarget]="cfg?.produzione_dst"
                                                [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                                [driveLinkTitle]="cfg.produzione_link_name + ' > ' + s.name + ' ' + (s?.ga_title ? s.ga_title : '')"
                                                [driveAdditionalTitle]="' > ' + s.name + ' ' + (s?.ga_title ? s?.ga_title : '')"
                                                [trelloCards]="filterProductionTrelloFolders(s)"
                                                [trelloListId]="cfg?.produzione_trello"
                                                [trelloBoardId]="cfg?.produzione_trello_board"
                                                [trelloDateLabel]="'Spedizione'" (onConnect)="loadDeal()">
                                            </app-connect-to>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div>