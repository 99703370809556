import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';

import { Partner } from './partner';

export class MailFollower extends OdooModel implements OdooSerializableInterface<MailFollower> {
  public readonly ODOO_MODEL = 'mail.followers';
_last_update: string = "";
display_name: string = "";
email: string = "";
id: number;
is_active: boolean = false;
name: string = "";
partner_id: OdooRelationship<Partner> = new OdooRelationship<Partner>();
res_id : number;
res_model: string = "";

  create(): MailFollower {
    return new MailFollower();
  }
}


