import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PRINT_ATTENDANCE_CFG } from 'src/app/models/deal';
import { SaleOrder } from '../models/sale-order.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { HrAttendance } from '../models/hr-attendance.model';
import { Product } from '../models/product.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  socket: any;

  constructor(private router: Router, public odooEm: OdooEntityManager) { }

  ngOnInit() {


    // (window as any).global = {}
    // var s = this.odoo.searchRead("res.partner")
    // console.log("SS", s)
    // check redirect uri , setted by auth.guard
    // if (localStorage.getItem("redirect_uri") !== null) {
    //   var path = localStorage.getItem("redirect_uri")
    //   localStorage.removeItem("redirect_uri")
    //   this.router.navigate([path]);
    // }

  }



}
