import {
  Component,
  OnInit,
  ElementRef,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  firstValueFrom,
} from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { PurchaseOrder } from "src/app/models/purchase-order.model";
import { SaleOrder } from "src/app/models/sale-order.model";
import { StockPicking } from "src/app/models/stock-picking";
import { OdooEntityManager } from "src/app/shared/services/odoo-entity-manager.service";
import { ODOO_IDS } from "../models/deal";
import { ProcurementGroup } from "../models/procurement.group.model";
import { MailActivity } from "../models/mail.message";
import { CrmLeadPart } from "../models/crm.lead.part.model";
import { Lead } from "../models/crm.lead.model";
import { MrpProduction } from "../models/mrp-production";

@Pipe({ name: "sortByActivityDate" })
export class SortByActivityDate implements PipeTransform {
  transform(value: StockPicking[], order): any[] {
    if (!value) return [];

    return value.sort((a, b) => {
      // if (!a || !b || !a.sale_id.value || !b.sale_id.value ||!a.sale_id.value.activity_ids.ids || !b.sale_id.value.activity_ids.ids)
      //   return 1

      try {
        let da: Date = new Date(
          a.sale_id.value.activity_ids.values.find(
            (a) => a.activity_type_id.id == ODOO_IDS.activity_todo
          )?.date_deadline
        );
        let db = new Date(
          b.sale_id.value.activity_ids.values.find(
            (b) => b.activity_type_id.id == ODOO_IDS.activity_todo
          )?.date_deadline
        );
        return da.getTime() - db.getTime();
      } catch (e) {
        return 1;
      }
    });
  }
}

@Pipe({ name: "Date4Humans" })
export class Date4Humans implements PipeTransform {
  transform(value: string, order = "", column: string = ""): string {
    // Get today's date
    var todaysDate = new Date();
    var inputDate = new Date(value);

    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      return "Oggi";
    } else return new Date(value).toLocaleDateString();
  }
}

@Component({
  selector: "app-production-search",
  templateUrl: "picking-search.component.html",
})
export class PickingSearchComponent implements OnInit {
  groupedPickings: { [key: string]: (StockPicking | MrpProduction)[] } = {};
  isGroupedView: boolean = false;
  parts: CrmLeadPart[];
  loading: boolean;
  pickings: StockPicking[];
  title = "";
  currentFilter: string = "";

  filters = {
    purchase: false,
    sale: false,
  };

  searchInput: BehaviorSubject<string> = new BehaviorSubject("");
  params: any;
  keybuffer: string = "";
  picking_search_options: {};
  activePickingOption: any;
  partsLoaded: boolean = false;
  productions: MrpProduction[] = [];

  constructor(
    public odooEM: OdooEntityManager,
    public router: Router,
    public elementRef: ElementRef,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.picking_search_options = ODOO_IDS.picking_search_options;
    let s = localStorage.getItem("activePickingOption");
    console.log("ACTIVE PICKING OPTION", s);
    if (s != "undefined") this.activePickingOption = JSON.parse(s);
    else this.activePickingOption = ODOO_IDS.picking_search_options.E;

    this.params = this.activatedRoute.snapshot.params;

    if (this.activatedRoute.snapshot.queryParamMap.get("search")) {
      this.activePickingOption = ODOO_IDS.picking_search_options.E;
      this.searchInput.next(
        this.activatedRoute.snapshot.queryParamMap.get("search")
      );
    }

    this.title = this.params["type"];
    this.searchInput
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((s) => {
        this.load();
      });
  }

  async onSearchChange($event: any) {
    await this.router.navigate([], { queryParams: { search: $event } });
  }

  compareByString(a, b) {
    return a?.toString() == b?.toString();
  }

  toggleFilter(f: string) {
    this.filters[f] = !this.filters[f];

    this.load();
  }

  getGroupName(p: StockPicking | MrpProduction): string {
    let s = null;
    if (p instanceof MrpProduction) {
      s = p._sale_id.value;
    } else {
      s = p.sale_id.value;
    }
    if (!s) {
      return "No Group";
    }
    let trackingCode = s.opportunity_id?.value?.tracking_code;
    let city = s.opportunity_id?.value?.city;
    let partnerName = s.partner_id?.name;
    let partName = s._part_name;

    let groupNameParts = [];

    if (trackingCode) {
      groupNameParts.push(trackingCode);
    }
    if (partnerName) {
      groupNameParts.push(partnerName);
    }
    if (city) {
      groupNameParts.push(city);
    }
    if (partName) {
      groupNameParts.push(partName);
    }

    let groupName = groupNameParts.join(" - ");

    return groupName || "No Group";
  }

  groupPickingsByGroupName(): {
    [key: string]: (StockPicking | MrpProduction)[];
  } {
    const groupedSales: { [key: string]: (StockPicking | MrpProduction)[] } =
      {};

    //map picking sales and prodcutoion sales into one array

    this.pickings.forEach((picking) => {
      const groupName = this.getGroupName(picking);
      if (!groupedSales[groupName]) {
        groupedSales[groupName] = [];
      }
      groupedSales[groupName].push(picking);
    });
    //repeat for productions
    this.productions.forEach((production) => {
      const groupName = this.getGroupName(production);
      if (!groupedSales[groupName]) {
        groupedSales[groupName] = [];
      }
      groupedSales[groupName].push(production);
    });

    return groupedSales;
  }

  persist() {
    localStorage.setItem(
      "activePickingOption",
      JSON.stringify(this.activePickingOption)
    );
    this.load();
  }

  getPickingPage(p: StockPicking | MrpProduction) {
    if (p instanceof MrpProduction) {
      return "production";
    } else {
      if (p.picking_type_id.id == ODOO_IDS.picking_type_receipt) {
        return "in";
      }
    }

    return "internal";
  }

  async load() {
    this.loading = true;

    // Step 1: Fetch pickings
    const pickings = await this.fetchPickings();
    console.log("xxx pickings", pickings);

    //the following only if not Picking option Arrivi
    console.log("xxx activePickingOption", (this.activePickingOption.toString() ===  ODOO_IDS.picking_search_options["Arrivi"].toString()));

    if   (!(this.activePickingOption.toString() ===  ODOO_IDS.picking_search_options["Arrivi"].toString())){ 

    // Step 2: Process pickings (assign sales, remove invalid ones): some picking may not have a sale_id, in this case we try to find a related sale and assign it
    this.pickings = await this.processPickings(pickings);

    // Step 3: Fetch and process productions if necessary: Linea taglio and Edilizia produzione
    this.productions = await this.fetchProductions();

    // Step 4: Resolve related data for pickings and productions
    await this.resolveRelatedData();

    // Step 5: Assign parts to sales
    await this.assignPartsToSales();}

    else{
      this.pickings = pickings;
      // Resolve purchase orders for pickings
      await firstValueFrom(this.odooEM.resolveArrayOfSingle(new PurchaseOrder(), this.pickings,"purchase_id" ));
    }

    // Step 6: Group pickings and productions
    this.groupedPickings = this.groupPickingsByGroupName();

    this.loading = false;
    this.partsLoaded = true;
  }

  private async fetchPickings(): Promise<StockPicking[]> {
    const criteria = this.buildSearchCriteria();
    return await this.odooEM.search<StockPicking>(new StockPicking(),criteria, 1000, "", "scheduled_date").toPromise();
  }

  private buildSearchCriteria(): any[] {
    let criteria: any[] = [];
    const searchTerm = this.searchInput.value;

    if (searchTerm) {
      criteria = [
        "|",
        "|",
        "|",
        ["purchase_id", "ilike", searchTerm],
        ["sale_id", "ilike", searchTerm],
        ["name", "ilike", searchTerm],
        ["partner_id", "ilike", searchTerm],
      ];
    }

    if (this.activePickingOption) {
      criteria = criteria.concat(this.activePickingOption);
    }

    return criteria;
  }

  private async processPickings(pickings: StockPicking[]): Promise<StockPicking[]> {
    const pickingsWithoutSale = pickings.filter(p => !p.sale_id.id);
    
    if (pickingsWithoutSale.length === 0) {
      return pickings; // No processing needed
    }
  
    // Fetch all related productions in one query
    const productionOrigins = pickingsWithoutSale.map(p => p.origin);
    const relatedProductions = await this.fetchRelatedProductions(productionOrigins);
  
    // Fetch all related sales in one query
    const saleOrigins = relatedProductions.map(p => p.origin);
    const relatedSales = await this.fetchRelatedSales(saleOrigins);
  
    // Process pickings and mark for deletion if needed
    const idsToDelete: number[] = [];
    for (const picking of pickingsWithoutSale) {
      const production = relatedProductions.find(p => p.name === picking.origin);
      const sale = relatedSales.find(s => s.name === production?.origin);
  
      if (sale) {
        this.assignSaleToPickingFromProduction(picking, sale);
      } else {
        idsToDelete.push(picking.id);
      }
    }
  
    // Remove pickings without a sale_id
    return pickings.filter(p => !idsToDelete.includes(p.id));
  }
  
  private async fetchRelatedProductions(origins: string[]): Promise<MrpProduction[]> {
    return await firstValueFrom(this.odooEM.search<MrpProduction>(
      new MrpProduction(),
      [["name", "in", origins]],
      500
    ));
  }
  
  private async fetchRelatedSales(origins: string[]): Promise<SaleOrder[]> {
    return await firstValueFrom(this.odooEM.search<SaleOrder>(
      new SaleOrder(),
      [
        ["name", "in", origins],
        ["activity_ids.activity_type_id", "in", [4]]
      ],
      500
    ));
  }
  
  private assignSaleToPickingFromProduction(picking: StockPicking, sale: SaleOrder) {
    picking.sale_id.id = sale.id;
    picking.sale_id.name = sale.name;
    picking.sale_id.value = sale;
  }

  private async fetchProductions(): Promise<MrpProduction[]> {
    if (this.shouldFetchProductions()) {
      const criteria = this.buildProductionCriteria();
      const productions = await this.odooEM.search<MrpProduction>(new MrpProduction(), criteria).toPromise();
      await this.assignSaleToProductions(productions);
      return productions;
    }
    return [];
  }

  private shouldFetchProductions(): boolean {
    return (
      this.activePickingOption.toString() ===
        ODOO_IDS.picking_search_options["Linea taglio"].toString() 
      //   ||
      // this.activePickingOption.toString() ===
      //   ODOO_IDS.picking_search_options["Edilizia produzione"].toString()
    );
  }

  private buildProductionCriteria(): any[] {
    const baseCriteria = [
      ["location_dest_id", "=", ODOO_IDS.stock_location_shipment],
      ["state", "in", ["confirmed", "progress"]],
    ];

    if (
      this.activePickingOption.toString() ===
      ODOO_IDS.picking_search_options["Linea taglio"].toString()
    ) {
      return [...baseCriteria, ["picking_type_id", "=", 57]];
    } else {
      return [...baseCriteria, ["picking_type_id", "in", [81, 82]]];
    }
  }

  private async assignSaleToProductions(productions: MrpProduction[]) {

    let criterias = productions.map((p) => p.origin);

    const sales = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(), [["name", "in" , criterias]],500));

    for (const production of productions) {
      const sale = sales.find((s) => s.name === production.origin);
      if (sale) {
        production._sale_id.id = sale.id;
        production._sale_id.name = sale.name;
        production._sale_id.value = sale;
      }
    }

   /*  for (const production of productions) {
      const sale = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(), [["name", "=", production.origin],],500));
      if (sale.length > 0) {
        production._sale_id.id = sale[0].id;
        production._sale_id.name = sale[0].name;
        production._sale_id.value = sale[0];
      }
    } */
  }

  private async resolveRelatedData() {
    // Resolve sale orders for pickings
    await firstValueFrom(this.odooEM.resolveArrayOfSingle(new SaleOrder(),this.pickings, "sale_id"));

    const sales = this.pickings.filter((p) => p.sale_id.value).map((p) => p.sale_id.value);

    if (sales.length > 0) {
      // Resolve activities for sale orders
      await firstValueFrom(this.odooEM.resolveArray(new MailActivity(), sales, "activity_ids"));

      // Resolve leads for sale orders
      await firstValueFrom(
        this.odooEM.resolveArrayOfSingle(new Lead(), sales, "opportunity_id")
      );

      // Fetch parts for leads
      const leads = sales.map((s) => s.opportunity_id.value).filter((l) => l);
      this.parts = await this.odooEM.search<CrmLeadPart>(new CrmLeadPart(), [["lead_id", "in", leads.map((l) => l.id)]], 500 ).toPromise();

    }
  }

  private async assignPartsToSales() {
    const sales = this.pickings.filter((p) => p.sale_id.value).map((p) => p.sale_id.value);
    sales.forEach((s) => {
      const part = this.parts.find((p) => p.sale_order_ids.ids.includes(s.id));
      s._part_name = part ? part.name : "Vendita diretta";
    });
  }

  getDisplayTextNoGroup(p: StockPicking | MrpProduction): string {
    if (
      p instanceof StockPicking &&
      p.picking_type_id.id == ODOO_IDS.picking_type_receipt
    ) {
      return `${p.origin} - <b>${p.partner_id.name}</b> <br>${
        p.purchase_id.value?.partner_ref || ""
      }`;
    } else {
      let sale = null;
      if (p instanceof MrpProduction) {
        sale = p._sale_id.value;
      } else {
        sale = p.sale_id.value;
      }
      if (!sale) {
        return "No Sale";
      }
      let displayText = "<b>";
      if (sale?.opportunity_id?.value?.tracking_code) {
        displayText += `${sale.opportunity_id.value.tracking_code} - `;
      }
      displayText += `${sale.partner_id?.name || ""} <br>`;

      if (sale?.opportunity_id?.value?.city) {
        displayText += ` ${sale.opportunity_id.value.city} - `;
      }
      if (sale._part_name) {
        displayText += `${sale._part_name}`;
      }
      displayText += "</b>";

      if (sale.name || sale.ga_title) {
        displayText += "<br>";
        if (sale.name) {
          displayText += sale.name;
        }
        if (sale.name && sale.ga_title) {
          displayText += " - ";
        }
        if (sale.ga_title) {
          displayText += sale.ga_title;
        }
      }
      return displayText.trim();
    }
  }

  getDisplayTextGroup(p: StockPicking | MrpProduction): string {
    if (
      p instanceof StockPicking &&
      p.picking_type_id.id == ODOO_IDS.picking_type_receipt
    ) {
      return `<b>${p.origin || "N/A"}</b> - ${
        p.purchase_id.value?.partner_ref || ""
      }<br>${p.name || "N/A"} - ${(p.picking_type_id.name || "").replace(
        "LOMAGNA:",
        ""
      )}`;
    } else {
      let sale = null;
      if (p instanceof MrpProduction) {
        sale = p._sale_id.value;
      } else {
        sale = p.sale_id.value;
      }
      if (!sale) {
        return "No Sale";
      }
      let displayText = "<b>";
      if (sale.name) {
        displayText += sale.name;
      }
      if (sale.ga_title) {
        displayText += sale.name ? ` - ${sale.ga_title}` : sale.ga_title;
      }
      displayText += "</b>";

      displayText += "<br>";
      if (p instanceof MrpProduction) {
        displayText += `${p.name || "N/A"} - Produzione`;
      } else {
        displayText += `${p.name || "N/A"} - ${(
          p.picking_type_id.name || ""
        ).replace("LOMAGNA:", "")}`;
      }

      console.log("xxx built group text", displayText);
      return displayText.trim();
    }
  }

  getDisplayDate(p: StockPicking | MrpProduction): Date | string {
    if (p instanceof MrpProduction) {
      return p.date_planned_start;
    } else if (this.getActivityDate(p)) {
      return this.getActivityDate(p);
    } else {
      return p.scheduled_date || "No date available";
    }
  }

  getDisplayUser(p: StockPicking | MrpProduction): string {
    if (p instanceof MrpProduction) {
      return p.activity_user_id?.name || "Unknown User";
    } else if (p.picking_type_id.id == ODOO_IDS.picking_type_receipt) {
      // Assuming 1 is the ID for receipt pickings
      return p.purchase_id?.value?.user_id?.name || "Unknown User";
    } else {
      return p.sale_id?.value?.user_id?.name || "Unknown User";
    }
  }

  assignParts(sales: SaleOrder[]) {
    //assign parts to sales through field sales._part_name
    sales.forEach((s) => {
      let part = this.parts.find((p) => p.sale_order_ids.ids.includes(s.id));
      if (part) {
        s._part_name = part.name;
      } else {
        s._part_name = "Vendita diretta";
      }
    });
    this.partsLoaded = true;
  }

  getActivityDate(p: StockPicking) {
    if (p && p.sale_id && p.sale_id.value) {
      let a = p.sale_id?.value?.activity_ids?.values?.find(
        (a) => a.activity_type_id.id == ODOO_IDS.activity_todo
      );

      if (a) return a.date_deadline;
    }
  }

  getPickingClass(p: StockPicking | MrpProduction) {
    let c = "";
    if (p instanceof MrpProduction) {
      if (p.backorder_sequence > 0) c = "fa-circle-half-stroke ";
      if (
        p.state == "confirmed" ||
        p.state == "progress" ||
        p.state == "waiting_material"
      )
        return "fa-circle text-warning";
      if (p.state == "done") return "fa-circle text-success";
      if (p.state == "cancel") return "fa-circle text-danger";
      if (p.state == "waiting_material") return "fa-circle text-muted";
      if (p.state == "assigned") return "fa-circle text-info";
    } else {
      if (p.backorder_id.id) c = "fa-circle-half-stroke ";
      else c = "fa-circle ";

      if (p.state == "waiting")
        // backorder
        return c + "text-muted";
      if (p.state == "assigned" || p.state == "done") return c + "text-success";
      if (p.state == "confirmed") return c + "text-warning";
    }
  }
}
