import { Component, OnInit } from '@angular/core';
import { Contact } from '../models/contact.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { SaleOrder } from '../models/sale-order.model';
import { firstValueFrom } from 'rxjs';
import { CrmTag } from '../models/crm.tag.model';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-flash-sale-order',
  templateUrl: './flash-sale-order.component.html',
  styleUrls: ['./flash-sale-order.component.scss']
})
export class FlashSaleOrderComponent implements OnInit {
  
  loading:boolean = true
  newSalePartner?:Contact
  tag: CrmTag;
  sales: SaleOrder[];

  constructor(
    private odooEm:OdooEntityManager,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    // retrive named tag
    let tags = await firstValueFrom(this.odooEm.search<CrmTag>(new CrmTag()))
    this.tag = tags.find(t => 
      t.name === 'FLASH'
    )
    this.loading = false
  }
  

  onContact(e:Contact) {
    this.newSalePartner = e
  }

  async newSale() {
    // the partner could be temp
    if (!this.newSalePartner.id) {
      this.newSalePartner = await firstValueFrom(this.odooEm.create<Contact>(this.newSalePartner, {
        name: this.newSalePartner.name,
        type: "delivery",
        parent_id: this.newSalePartner.parent_id.id,
        street: this.newSalePartner.street
      }))
    }

    let res = await firstValueFrom(this.odooEm.create<SaleOrder>(new SaleOrder(), {
      partner_id: this.newSalePartner.id,
      tag_ids: [this.tag.id]
    }))

    if (!res)
      return alert("Errore creazione ordine")
      
    this.router.navigate([res.id], {relativeTo: this.route})
  }

}
