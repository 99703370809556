
<div class="d-flex flex-column h-100 ">
    <canvas #canvas></canvas>
    <video #video></video>
    <button (click)="selectCode(null)" class="btn btn-link" style="position:fixed; top:20px;right: 20px;z-index:200000;" >
        <i class="fa fa-close fa-3x"></i>
    </button>
</div>

<div class="app-toolbar d-flex flex-column gap-3 px-3 align-items-center">
    

    <a class="btn p-1 bg-primary text-white w-100" (click)="selectCode('nopacco')">
        Senza pacco
    </a>


    <div class="d-flex">
    <i class="fa fa-keyboard fa-2x" (click)="keyboard = !keyboard"></i>
    <input #input *ngIf="keyboard" class="form-control ms-3" [(ngModel)]="manualBarcode" (keyup.enter)="selectCode(input.value)"   type="text" placeholder="Inserisci codice a barre" />
    
    <button *ngIf="keyboard" [disabled]="!manualBarcode" class="btn btn-success ms-2 text-white" (click)="selectCode(manualBarcode)">
        <i class="fa fa-check fa-lg"></i>
    </button>

    <a (click)="selectCode(candidateBarcode)" *ngIf="candidateBarcode" class="btn code ms-3 p-3 bg-success text-white w-100" >
        {{candidateBarcode}}
    </a>
</div>

    
</div>
