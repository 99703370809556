import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import { MailMessage, MailMessageReaction } from '../models/mail.message';
import * as moment from 'moment';
import 'moment/locale/it';
import { ActivatedRoute } from '@angular/router';
import { OdoorpcService } from '../shared/services/odoorpc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Partner } from '../models/partner';

@Component({
  selector: 'app-message-widget',
  templateUrl: './message-widget.component.html',
  styleUrls: ['./message-widget.component.scss']
})
export class MessageWidgetComponent implements OnInit, AfterViewInit {
  @Input() model: string;
  @Input() res_id?: number;
  @ViewChild('scrollableContent') private myScrollContainer: ElementRef;
  @ViewChild('messageInput') private messageInput: ElementRef;

  message: string = "";
  attachmentIds: number[] = [];
  selectedFiles: File[] = [];
  suggestions: any[] = [];
  showSuggestions: boolean = false;
  showReactions: boolean = false;
  currentReactionMessageId: number | null = null;
  selectedSuggestionIndex: number = -1;
  atSignDetected: boolean = false;
  csrfToken: any;
  userId: any;
  groupMessage: { dateFromNow: string; messages: any; }[];
  isUploading: boolean = false;

  constructor(
    private odooEm: OdooEntityManager,
    private http: HttpClient,
    public route: ActivatedRoute,
    public odoorpcService: OdoorpcService
  ) { }

  ngOnInit(): void {
    this.initializeComponent();
  }

  ngAfterViewInit(): void {
    this.setupPasteEventListener();
  }

  private async initializeComponent(): Promise<void> {
    const result: any = await this.odooEm.odoorpcService.getSessionInfo();
    this.userId = result.result.partner_id;
    await this.load();
    this.scrollToBottom();
  }

  private setupPasteEventListener(): void {
    this.messageInput.nativeElement.addEventListener('paste', (e: ClipboardEvent) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
    });
  }

  @HostListener('document:show.bs.dropdown', ['$event'])
  onShow(event: any): void {
    setTimeout(() => this.scrollToBottom(), 1);
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { console.error('Error scrolling to bottom:', err); }
  }

  toggleReactions(messageId: number): void {
    this.currentReactionMessageId = this.currentReactionMessageId === messageId ? null : messageId;
  }

  customFromNow(date: string): string {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const dateMoment = moment(date);
  
    if (dateMoment.isSame(today, 'd')) return 'Oggi';
    if (dateMoment.isSame(yesterday, 'd')) return 'Ieri';
    const daysAgo = today.diff(dateMoment, 'days');
    return `${daysAgo} giorni fa`;
  }

  async load(): Promise<void> {
    try {
      const messages: any = await firstValueFrom(this.odooEm.search<MailMessage>(
        new MailMessage(),
        [["model", "=", this.model], ["res_id", "=", this.res_id]],
        null,
        null,
        "date asc"
      ));

      const groupedMessages = this.groupMessagesByDate(messages);
      this.groupMessage = Object.entries(groupedMessages).map(([dateFromNow, messages]) => ({
        dateFromNow,
        messages
      }));
    } catch (error) {
      console.error('Error loading messages:', error);
    }
  }

  private groupMessagesByDate(messages: any[]): { [key: string]: any[] } {
    const groupedMessages = {};
    for (const message of messages) {
      message.dateHour = moment(message.date).format('HH:mm');
      message.dateFromNow = this.customFromNow(message.date);
      this.resolveMessageDetails(message);
      
      if (!groupedMessages[message.dateFromNow]) {
        groupedMessages[message.dateFromNow] = [];
      }
      groupedMessages[message.dateFromNow].push(message);
    }
    return groupedMessages;
  }

  private async resolveMessageDetails(message: any): Promise<void> {
    await firstValueFrom(this.odooEm.resolve(message.reaction_ids));
    await firstValueFrom(this.odooEm.resolve(message.attachment_ids));
  }

  async getSuggestion(key: string): Promise<void> {
    try {
      const params = {
        model: new Partner().ODOO_MODEL,
        method: "get_mention_suggestions",
        kwargs: {
          "search": key,
          "context": {
            "lang": "it_IT",
            "tz": "Europe/Rome",
            "uid": 59,
            "allowed_company_ids": [1]
          }
        },
        args: []
      };

      const response: any = await this.odoorpcService.sendRequest('/api/web/dataset/call_kw/' + params.model + "/" + params.method, params);
      this.suggestions = Array.isArray(response.result) ? response.result : [];
      this.showSuggestions = this.suggestions.length > 0;
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      this.suggestions = [];
      this.showSuggestions = false;
    }
  }

  selectSuggestion(suggestion: any): void {
    const atPosition = this.message.lastIndexOf('@');
    if (atPosition !== -1) {
      this.message = `${this.message.substring(0, atPosition)}@${suggestion.name} `;
      this.suggestions = [];
      this.showSuggestions = false;
      this.atSignDetected = false;
    }
  }

  isImage(attachment: any): boolean {
    return /^image\//.test(attachment.mimetype);
  }

  onKeyup(event: KeyboardEvent): void {
    const inputValue: string = (event.target as HTMLInputElement).value;
    const atPosition = inputValue.lastIndexOf('@');

    if (atPosition !== -1) {
      this.atSignDetected = true;
      const query = inputValue.substring(atPosition + 1);
      this.getSuggestion(query);
    } else {
      this.resetSuggestionState();
    }
  }

  private resetSuggestionState(): void {
    this.atSignDetected = false;
    this.suggestions = [];
    this.showSuggestions = false;
  }

  async fetchCSRFToken(): Promise<string> {
    try {
      const url = '/api/get_csrf_token';
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const response: any = await this.http.post(url, {}, { headers }).toPromise();
      return response.result.csrf_token;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      return null;
    }
  }

  async confirmMessage(): Promise<void> {
    if (!this.message.trim() && this.attachmentIds.length === 0) return;

    try {
      const messageData: any = {
        body: this.message,
        model: this.model,
        res_id: Number(this.res_id),
        message_type: "comment",
        attachment_ids: [[6, 0, this.attachmentIds]]
      };

      await firstValueFrom(this.odooEm.create<MailMessage>(new MailMessage(), messageData));
      this.resetMessageState();
      await this.load();
      this.scrollToBottom();
      console.log('Message sent successfully', messageData);
    } catch (error) {
      console.error('Error sending message:', error);
      // Implement user feedback for error
    }
  }

  private resetMessageState(): void {
    this.attachmentIds = [];
    this.selectedFiles = [];
    this.message = "";
  }

  async uploadFile(event: any): Promise<void> {
    const files: FileList = event.target.files;
    if (!files || files.length === 0) return;

    this.isUploading = true;
    try {
      if (!this.csrfToken) {
        this.csrfToken = await this.fetchCSRFToken();
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = this.createFormData(file);
        const responseData = await this.sendAttachment(formData);
        this.attachmentIds.push(responseData.id);
        this.selectedFiles.push(file);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      // Implement user feedback for error
    } finally {
      this.isUploading = false;
    }
  }

  private createFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('ufile', file, file.name);
    formData.append('thread_model', 'res.partner');
    formData.append('thread_id', this.res_id.toString());
    formData.append('name', file.name);
    formData.append('mimetype', file.type);
    formData.append('csrf_token', this.csrfToken);
    formData.append('type', 'binary');
    return formData;
  }

  private async sendAttachment(formData: FormData): Promise<any> {
    const response = await fetch('/api/mail/attachment/upload', {
      method: 'POST',
      body: formData
    });
    return response.json();
  }

  async insertSmile(event: Event, message: MailMessage, body: string): Promise<void> {
    event.stopPropagation();
    this.message += body;
    this.showReactions = false;
  }

  async setReaction(event: Event, message: MailMessage, body: string): Promise<void> {
    event.stopPropagation();
    try {
      const result: any = await this.odooEm.odoorpcService.getSessionInfo();
      const reaction = {
        message_id: message.id,
        content: body,
        partner_id: result.result.partner_id
      };

      const newReaction = await firstValueFrom(this.odooEm.create<MailMessageReaction>(new MailMessageReaction(), reaction));
      message.reaction_ids.values.push(newReaction);
      this.toggleReactions(message.id);
    } catch (error) {
      console.error('Error setting reaction:', error);
      // Implement user feedback for error
    }
  }
}