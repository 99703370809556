import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../models/product.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import Decimal from 'decimal.js';
import { ProductPackaging } from '../models/product.packaging.model';

@Component({
  selector: 'app-product-quantity-editor',
  templateUrl: './product-quantity-editor.component.html',
  styleUrls: ['./product-quantity-editor.component.scss']
})
export class ProductQuantityEditorComponent implements OnInit, AfterViewInit {
  @Input() product: Product;
  @Input() quantity?: number = 0;
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<Number>();

 // descriptive array: [quantity of packs, packaging, placeholder]
 descriptiveArray: [string, ProductPackaging, number][] = [];
 loading: boolean = false;

 constructor(
   private odooEm: OdooEntityManager
 ) { }
  
 ngOnInit(): void {
  console.log("product", this.product);
  console.log("quantity", this.quantity);
} 

async ngAfterViewInit(): Promise<void> {
  this.descriptiveArray = await this.getDescriptiveArrayOr(this.product, this.quantity); 
  console.log("descriptiveArray", this.descriptiveArray);
}

async getDescriptiveArrayOr(product: Product, q: number): Promise<[string, ProductPackaging, number][]> {
  if (!product.packaging_ids.values || product.packaging_ids.values.length != product.packaging_ids.ids.length) {
    await firstValueFrom(this.odooEm.resolve(product.packaging_ids));
  }
  
  if (!product.packaging_ids.values) {
    return [];
  }
    
  // Order packagings by quantity in descending order
  const ps = product.packaging_ids.values.slice().sort((a, b) => b.qty - a.qty);
  
  // Map each packaging to its quantity representation
  return ps.map((packaging) => {
    const packQuant = Number.parseFloat(
      new Decimal(q).div(packaging.qty).toPrecision(6).toString()
    ).toString();
    return [packQuant, packaging, 0];
  });
}
    // this is an old versin that added up the packaging quanitties. Giacomo wants them to be separate
    // ps.forEach((p, i) => {
    //     let x = Number.parseFloat(
    //       new Decimal(q).div(p.qty).toPrecision(5).toString()
    //       ).toString()
    //     a.push([x ,p,0])
  //   // })
  //   return a
  // }

  async updateDescriptiveOr(d: [string, ProductPackaging, number], value: number): Promise<void> {
    // Update just this specific packaging quantity
    // d[0] = value.toString();
    const uom_qty = value * d[1].qty;
    this.updateQuantity(uom_qty);
  
    // No need to recalculate all quantities as per new requirements
    // Just update the base unit quantity
  }

  async updateQuantity(q: number): Promise<void> {
    this.loading = true;
    try {
      this.quantity = q;
      this.descriptiveArray = await this.getDescriptiveArrayOr(this.product, q);
    } finally {
      this.loading = false;
    }
  }



  // getDescriptive(product:Product, q) {

  //   if (q == 0) return 0
    
  //   if (product.packaging_ids.values.length != product.packaging_ids.ids.length)    // dont want to show 
  //     this.odooEm.resolve(product.packaging_ids)

  //   if (!product.packaging_ids.values.length)    // dont want to show 
  //     return q +  " " + product.uom_id.name 
    
    
  //   var ps = product.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
  //   // var q = line.move_id.value.product_uom_qty
  //   var d = ""

  //   ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
  //   var totale = new Decimal(q)

  //   ps.forEach((p, i) => {

  //     if (!p.sales || p.name.includes("netti")) 
  //       return

  //     if (totale.toNumber() <= 0)
  //       return 

  //     let quo;
  //     //if last
  //     if (i == ps.length - 1)
  //       quo = totale.div(p.qty)
  //     else
  //       quo = totale.divToInt(p.qty)
  //     totale = totale.minus(quo.mul(p.qty))
      
  //     console.log("QUO", quo.toNumber())
  //     if (quo.toNumber() > 0)
  //       d = d + "" + Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name + "\n"
  //   })
    
  //   return d
  // }


  back() {
    this.onCancel.emit()
  }

  // assignMoveLine(){

  // }

  save() {
    this.onSave.emit(this.quantity)
  }


  // deleteLin() {

  // }

}
