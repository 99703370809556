import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import * as bootstrap from "bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

// Odoo Models
import { OdooEntityManager } from "../shared/services/odoo-entity-manager.service";
import { SaleOrder } from "../models/sale-order.model";
import { Product } from "../models/product.model";
import { SaleOrderLine } from "../models/sale-order-line.model";
import { StockLocationRoute } from "../models/stock-location-route.model";
import { StockQuantPackage } from "../models/stock-quant-package";
import { ProductPackaging } from "../models/product.packaging.model";
import { ProductPricelist } from "../models/product.pricelist";
import { Contact } from "../models/contact.model";
import { ODOO_IDS } from "../models/deal";
import { SaleOrderCancel } from "../models/sale-order-cancel.model";

@Component({
  selector: "app-flash-sale-order-editor",
  templateUrl: "./flash-sale-order-editor.component.html",
  styleUrls: ["./flash-sale-order-editor.component.scss"],
})
export class FlashSaleOrderEditorComponent implements OnInit, AfterViewInit {
  // Core properties
  loading: boolean = true;
  order: SaleOrder;
  route?: StockLocationRoute;
  pricelists: ProductPricelist[];
  isEditMode: boolean = false;

  // Scanner related
  scanning: boolean = false;
  keybuffer: string = "";

  // Product handling
  product: Product;
  package: StockQuantPackage;

  // Quantity editor states
  showQuantityEditor: boolean = false;
  showQuantityEditorOnLine: boolean = false;
  productForEditor: any = null;
  productForEditorOnLine: any = null;
  lineQuantity: number = 0;
  updatingLine: SaleOrderLine;

  // View references
  @ViewChild("offcanvas") offCanvasElement: ElementRef<HTMLDivElement>;
  offCanvas: any;

  constructor(
    private odooEm: OdooEntityManager,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  // INITIALIZATION
  ngAfterViewInit(): void {
    this.offCanvas = new bootstrap.Offcanvas(
      this.offCanvasElement.nativeElement
    );
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.initializeOrder();
    this.setupBarcodeScanner();
    this.loading = false;
  
  }

  private async initializeOrder(): Promise<void> {
    this.activeRoute.params.subscribe(async (params) => {
      // Get default route
      let route = await firstValueFrom(
        this.odooEm.search<StockLocationRoute>(new StockLocationRoute(), [
          ["id", "=", ODOO_IDS.diretto_route_id],
        ])
      );
      if (!route.length) return alert("manca route per vendita immediata");
      this.route = route[0];

      // Load order and related data
      let order = new SaleOrder(Number(params["order_id"]));
      await this.refreshSale(order);
      await this.resolveSale(order);
      await this.loadPricelists();
      this.order = order;
      console.log("order", this.order);
    });
  }

  private async refreshSale(order: SaleOrder): Promise<void> {
    const result = await firstValueFrom(
      this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", order.id]])
    );

    if (result.length) {
      const updatedOrder = result[0];
      await firstValueFrom(this.odooEm.resolve(updatedOrder.order_line));
      await firstValueFrom(
        this.odooEm.resolveArrayOfSingle(
          new Product(),
          updatedOrder.order_line.values,
          "product_id"
        )
      );
      Object.assign(order, updatedOrder);
      this.solvePackagings(order);
    }
  }

  async solvePackagings(o: SaleOrder) {
    let prods = o.order_line.values.map((l) => l.product_id.value);
    await firstValueFrom(
      this.odooEm.resolveArray(new ProductPackaging(), prods, "packaging_ids")
    );
  }

  private async resolveSale(order: SaleOrder): Promise<void> {
    await firstValueFrom(
      this.odooEm.resolveSingle(new Contact(), order.partner_id)
    );
  }

  // BARCODE SCANNER
  // Setup barcode scanner
  private setupBarcodeScanner(): void {
    window.addEventListener("keypress", (e) => {
      if (e.key == "Enter") {
        this.onCode(this.keybuffer);
        this.keybuffer = "";
      } else this.keybuffer = this.keybuffer + e.key;
    });
  }

  scanWithCamera(): void {
    this.scanning = true;
  }

  //handle barcode result
  async onCode(c) {
    if (!c) return this.router.navigate(["/"]);
    this.loading = true;

    var rs = await firstValueFrom(
      await this.odooEm.search<StockQuantPackage>(new StockQuantPackage(), [
        ["name", "=like", c + "%"],
      ])
    );
    if (rs && rs.length > 0) {
      // if it's a package
      await this.onPackageOrig(rs[0]);
    } else {
      // search for barcode
      let ps = await firstValueFrom(
        await this.odooEm.search<Product>(new Product(), [["barcode", "=", c]])
      );
      let prod = ps[0];
      if (!prod) {
        // if not package and not product
        alert("Collo o prodotto non trovato");
        this.loading = false;
        return;
      } else {
        // if product
        console.log("xxxxxxPRODUCT FOUND", prod);
        this.onProduct(ps[0]);
      }
    }
    this.offCanvas.show();
    this.loading = false;
  }

  async onProduct(p: Product) {
    this.product = p;
    this.package = null;

    //solve packagings
    await firstValueFrom(
      this.odooEm.resolveArray(new ProductPackaging(), [p], "packaging_ids")
    );

    // Load price
    try {
      const prices = await this.loadPrice([p]);
      this.product._lst_price = prices[p.id];
    } catch (error) {
      console.error("Error loading price:", error);
    }
  }

  async onPackageOrig(p: StockQuantPackage) {
    //solve quants and products
    await firstValueFrom(this.odooEm.resolve(p.quant_ids));
    await firstValueFrom(
      this.odooEm.resolveArrayOfSingle(
        new Product(),
        p.quant_ids.values,
        "product_id"
      )
    );
    //save products to display
    let prods = [];
    p.quant_ids.values.forEach((m) => {
      prods.push(m.product_id.value);
    });
    // Load prices for all products in the package
    try {
      const prices = await this.loadPrice(prods);
      prods.forEach((prod) => {
        prod._lst_price = prices[prod.id];
      });
    } catch (error) {
      console.error("Error loading prices:", error);
    }
    //solve packagings
    await firstValueFrom(
      this.odooEm.resolveArray(new ProductPackaging(), prods, "packaging_ids")
    );
    this.package = p;
    this.product = null;
  }

  // management functions for displaying

  getPartnerName(order: SaleOrder): string {
    //fetch parent name if partner is a contact and slice to the 30th character
  let partner = ""
    if (order?.partner_id.value?.parent_id?.id)
    partner = order?.partner_id.value.parent_id?.name
    else
    partner = order?.partner_id.name

    return partner?.slice(0, 30) + (partner?.length > 30 ? "..." : "");
  }

  getDescriptive(product: Product, quantity: number): string {
    return product
      ? "(" + this.odooEm.getDescriptive(product, quantity) + ")"
      : "";
  }

  getFree(product: Product): number {
    if (product.detailed_type === "consu") return 9999;

    if (product.detailed_type === "product") {
      const available = product.qty_available - product.outgoing_qty;
      return available > 0 ? available : 0;
    }

    return 0;
  }

  async loadPrice(products: Product[]) {
    var params = {
      model: "product.pricelist",
      method: "get_products_price",
      args: [[2], products.map((x) => x.id)],
      kwargs: {
        context: null,
      },
    };
    var r: any = await this.odooEm.odoorpcService.sendRequest(
      "/api/web/dataset/call_kw/" + params.model + "/" + params.method,
      params
    );
    return r.result ? r.result : [];
  }

  // Edit quantities and prices

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  async loadPricelists(): Promise<void> {
    try {
      this.pricelists = await firstValueFrom(
        this.odooEm.search<ProductPricelist>(new ProductPricelist())
      );
    } catch (error) {
      console.error("Error loading pricelists:", error);
    }
  }

  async updatePricelist(
    order: SaleOrder,
    newPricelistId: number
  ): Promise<void> {
    this.loading = true;
    try {
      await firstValueFrom(
        this.odooEm.update<SaleOrder>(order, { pricelist_id: newPricelistId })
      );
      if (confirm("Vuoi aggiornare i prezzi?")) {
        await this.odooEm.call2(order.ODOO_MODEL, "action_update_prices", [
          order.id,
        ]);
      }
      await this.refreshSale(order);
    } finally {
      this.loading = false;
    }
  }

  async updateLinePrice(line: SaleOrderLine, price: number): Promise<void> {
    this.loading = true;
    try {
      await firstValueFrom(
        this.odooEm.update<SaleOrderLine>(line, { price_unit: price })
      );
      await this.refreshSale(this.order);
    } catch (error) {
      console.error("Error updating price:", error);
      alert("Errore durante l'aggiornamento");
    } finally {
      this.loading = false;
    }
  }

  // Quantity editor for sale order lines
  async onUpdateQty(line: SaleOrderLine): Promise<void> {
    this.loading = true;
    this.productForEditorOnLine = line.product_id.value;
    this.lineQuantity = line.product_uom_qty;
    this.showQuantityEditorOnLine = true;
    this.updatingLine = line;
    this.loading = false;
  }

  async onQuantityUpdate(quantity: number): Promise<void> {
    this.loading = true;
    try {
      await this.odooEm.update<SaleOrderLine>(this.updatingLine, {
        product_uom_qty: quantity,
      });
      await this.refreshSale(this.order);
    } finally {
      this.updatingLine = null;
      this.showQuantityEditorOnLine = false;
      this.productForEditorOnLine = null;
      this.lineQuantity = 0;
      this.loading = false;
    }
  }

  // Quantity editor for new lines
  async onAddProduct(product: Product): Promise<void> {
    this.productForEditor = product;
    this.showQuantityEditor = true;
  }

  async onQuantity(quantity: number): Promise<void> {
    let product = this.productForEditor;
    let line = await firstValueFrom(
      this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), {
        order_id: this.order.id,
        product_id: product.id,
        product_uom_qty: quantity,
        route_id: this.route.id,
      })
    );

    if (!line) return alert("Errore durante il salvataggio");

    await this.refreshSale(this.order);
    await this.resolveSale(this.order);
    this.productForEditor = null;
    this.showQuantityEditor = null;
  }

  // Order Actions

  async deleteLine(line: SaleOrderLine): Promise<void> {
    if (!confirm("Confermi eliminazione ?")) return;

    this.loading = true;
    try {
      await firstValueFrom(this.odooEm.delete(new SaleOrderLine(), [line.id]));
      await this.refreshSale(this.order);
      await this.resolveSale(this.order);
    } finally {
      this.loading = false;
    }
  }

  async confirm(order: SaleOrder): Promise<void> {
    if (!confirm("Vuoi confermare l'ordine e passare al trasferimento ?"))
      return;

    const invalidLines = order.order_line.values.filter(
      (line) =>
        line?.product_id?.value &&
        line?.product_type == "product" &&
        !line.route_id.id
    );

    if (invalidLines.length > 0) {
      return alert("Tutti i prodotti devono avere un percorso");
    }

    this.loading = true;
    try {
      await this.odooEm
        .call(new SaleOrder(), "action_confirm", order.id)
        .toPromise();
      await this.refreshSale(order);
    } finally {
      this.loading = false;
    }
  }

  async resetOrder(order: SaleOrder): Promise<void> {

    //resolve pickings
    await firstValueFrom(this.odooEm.resolve(order.picking_ids));

    //stop if pickings are already done
    if (order.picking_ids.values.some((p) => p.state == "done")) {
      return alert("Impossibile reimpostare l'ordine, il picking è già stato completato");
    }
    
    
    if (!confirm('Vuoi reimpostare l\'ordine in bozza per modificarlo?')) return;
  
    this.loading = true;
    try {
      // First cancel the order
      const cancel = await firstValueFrom(
        this.odooEm.create<SaleOrderCancel>(new SaleOrderCancel(), {
          order_id: order.id,
        })
      );
  
      await this.odooEm
        .call(new SaleOrderCancel(), "action_cancel", [cancel.id])
        .toPromise();
  
      // Then set it back to draft
      await this.odooEm
        .call(new SaleOrder(), "action_draft", order.id)
        .toPromise();
  
      // Refresh the order data
      await this.refreshSale(order);
      await this.resolveSale(order);
      
      // Reset edit mode
      this.isEditMode = false;
  
    } catch (error) {
      console.error('Error resetting order:', error);
      alert(error.message || 'Errore durante il ripristino dell\'ordine');
    } finally {
      this.loading = false;
    }
  }


  async openOutPicking(): Promise<void> {
    if (!this.order.picking_ids.values) {
      await firstValueFrom(this.odooEm.resolve(this.order.picking_ids));
    }

    const shipment = this.order.picking_ids.values.find(
      (picking) =>
        picking.location_dest_id.id == ODOO_IDS.stock_location_sent &&
        picking.picking_type_id.id == ODOO_IDS.picking_type_generic &&
        picking.state != "done" &&
        picking.state != "cancel"
    );

    if (!shipment) {
      alert("Errore - Picking di consegna non trovato");
      return;
    }

    this.router.navigate(["/pickings/internal/", shipment.id], {
      queryParams: { back: document.location.pathname.toString() },
    });
  }

 

  // async back() {
  //   this.loading = true;
  //   await this.resolveSale(this.order);
  //   this.loading = false;
  // }

  //old methods to eliminate order and complete pickings that are not used anymore

  // public async draft(s: SaleOrder) {
  //   this.loading = true;
  //   try {
  //     await this.odooEm.call(new SaleOrder(), "action_draft", s.id).toPromise();
  //   } catch (e) {
  //     this.loading = false;
  //     alert(e.message);
  //   }

  //   await this.refreshSale(s);
  //   this.loading = false;
  // }

  // public async cancel(s: SaleOrder) {
  //   this.loading = true;

  //   var cancel = await firstValueFrom(
  //     this.odooEm.create<SaleOrderCancel>(new SaleOrderCancel(), {
  //       order_id: s.id,
  //     })
  //   );

  //   try {
  //     await this.odooEm
  //       .call(new SaleOrderCancel(), "action_cancel", [cancel.id])
  //       .toPromise();
  //   } catch (e) {
  //     this.loading = false;
  //     alert(e.message);
  //   }
  //   await this.refreshSale(s);
  //   this.loading = false;
  // }

  // async toDeliveryNote() {
  //   await this.odooEm.call2(new StockPicking().ODOO_MODEL, "action_assign", [
  //     [this.firstPicking.id],
  //   ]);
  //   await this.odooEm.call2(
  //     new StockPicking().ODOO_MODEL,
  //     "action_set_quantities_to_reservation",
  //     [[this.firstPicking.id]]
  //   );
  //   await this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [
  //     [this.firstPicking.id],
  //   ]);
  //   await this.refreshSale(this.order);
  //   await this.resolveSale(this.order);
  // }
}
