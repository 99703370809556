import { Contact } from './contact.model';
import { CrmStage } from './crm.lead.stage.model';
import { DriveFolder } from './drive.folder';
import { OdooModel } from './odoo-model.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { OdooRelationship } from './odoo-relationship.model';
import { SaleOrder } from './sale-order.model';
import { Stage } from './stage.model';
import { IConnectable, TrelloCardEntry2 } from './trello-card';
import { User } from './user.model';

/**
 * crm.lead
 */
// export class Lead extends OdooModel implements IConnectable<Lead> {
export class CrmLeadPart extends OdooModel {
   
    public readonly ODOO_MODEL = 'crm.lead.part';
    name:string = ""
    lead_id:OdooRelationship<CrmLeadPart> = new OdooRelationship<CrmLeadPart>()
    sale_order_ids:OdooMultiRelationship<SaleOrder> = new OdooMultiRelationship<SaleOrder>(SaleOrder)
   
    create() {
        return new CrmLeadPart()
    }


   
}