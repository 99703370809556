import { Component, OnInit } from "@angular/core";
import { Product } from "../models/product.model";
import { OdooEntityManager } from "../shared/services/odoo-entity-manager.service";
import { firstValueFrom } from "rxjs";
import { ODOO_IDS } from "../models/deal";
import { ProductTemplate } from "../models/product.template.model";
import { StockPicking } from "../models/stock-picking";
import { StockMove } from "../models/stock-move";
import { ProductTemplateAttributeValue } from "../models/product.template.attribute.value.model";
import { ProductAttributeValue } from "../models/product.attribute.value";
import { StockMoveLine } from "../models/stock-move-line";
import { SaleOrder } from "../models/sale-order.model";
import { StockQuantPackage } from "../models/stock-quant-package";

interface GroupedProduct {
  templateName: string;
  variants: {
    product: Product;
    variantValues: string[];
  }[];
}

@Component({
  selector: "app-oddments",
  templateUrl: "./oddments.component.html",
})
export class OddmentsComponent implements OnInit {
  loading = false;
  activePicking = false;
  saleNumber: string;
  activeProduct: Product;
  pieces: { length: number; package: string }[] = [];
  sale: SaleOrder;
  groupedProducts: GroupedProduct[] = [];

  constructor(private odooEm: OdooEntityManager) {}

  async ngOnInit(): Promise<void> {}

  // Load sale order based on input
  async loadSale(): Promise<void> {
    const sales = await firstValueFrom(
      this.odooEm.search<SaleOrder>(new SaleOrder(), [
        ["name", "=", "V" + this.saleNumber],
      ])
    );
    if (sales.length === 0) {
      alert("Ordine non trovato");
      return;
    }
    this.sale = sales[0];
  }

  // Load sale order pickings and associated products
  async loadPicking(): Promise<void> {
    // Fetch pickings and moves
    const pickings = await firstValueFrom(
      this.odooEm.search<StockPicking>(new StockPicking(), [
        ["origin", "=", "V" + this.saleNumber],
      ])
    );
    const moves = await firstValueFrom(
      this.odooEm.search<StockMove>(new StockMove(), [
        ["picking_id", "in", pickings.map((p) => p.id)],
        [
          "product_id.product_tmpl_id.product_tag_ids",
          "in",
          [ODOO_IDS.tag_variant_search],
        ],
      ])
    );

    // Extract unique product IDs
    const productIds = [...new Set(moves.map((m) => m.product_id.id))];

    // Fetch products and resolve their attributes
    const products = await firstValueFrom(
      this.odooEm.search<Product>(new Product(), [["id", "in", productIds]])
    );
    await this.odooEm
      .resolveArray(
        new ProductTemplateAttributeValue(),
        products,
        "product_template_attribute_value_ids"
      )
      .toPromise();

    // Group products and activate picking
    this.groupProducts(products);
    this.activePicking = true;
  }

  // Group products by template for easier display: for each template, list all variants
  groupProducts(products: Product[]): void {
    const groupedMap = new Map<string, GroupedProduct>();

    for (const product of products) {
      const templateName = product.product_tmpl_id.name || "Unknown Template";

      if (!groupedMap.has(templateName)) {
        groupedMap.set(templateName, { templateName, variants: [] });
      }

      const group = groupedMap.get(templateName);
      const variantValues = this.extractVariantValues(product);

      group.variants.push({ product, variantValues });
    }

    this.groupedProducts = Array.from(groupedMap.values());
  }

  // Extract variant values from a product, if they exist and are not empty or '-' (in this case it's useless to display them)
  extractVariantValues(product: Product): string[] {
    if (!product.product_template_attribute_value_ids?.values) return [];

    return product.product_template_attribute_value_ids.values
      .map((attrValue) =>
        attrValue instanceof ProductTemplateAttributeValue &&
        attrValue.product_attribute_value_id
          ? attrValue.name || ""
          : ""
      )
      .filter((value) => value !== "" && value !== "-");
  }

  // selecting product creates a new oddment and selects the product
  onProduct(p: Product) {
    this.activeProduct = p;
    this.pieces = [{ length: null, package: "" }];
  }

  // Add a new piece
  addPiece(): void {
    this.pieces.push({ length: 0, package: "" });
  }

  // Remove a piece
  removePiece(index: number): void {
    this.pieces.splice(index, 1);
  }

  back() {
    this.activeProduct = null;
  }

  // Save the oddments
  async save(): Promise<void> {
    // Validate lengths: check if they meet the requirements
    const invalidLengths = this.pieces.filter(
      (piece) => !this.validateLength(piece.length)
    );
    if (invalidLengths.length > 0) {
      alert(
        "Alcune lunghezze non sono valide. Inserire valori tra 100 mm e 13.500 mm"
      );
      return;
    }

    this.loading = true;

    try {
      // Create picking
      const picking = await this.createPicking();

      // Process each piece and create a move for each one
      const moves = [];
      const moveLines = [];

      for (const piece of this.pieces) {
        const { pack, destination } = await this.findOrCreatePackage(
          piece.package
        ); // Find or create package
        const product = await this.createProduct(
          this.activeProduct,
          piece.length,
          "Lunghezza"
        ); // Create product variant
        await firstValueFrom(this.odooEm.resolve(product.packaging_ids));
        const pz = product.packaging_ids.values.find(
          (p) => p.name.toLowerCase() == "pz"
        );

        moves.push(this.prepareMoveData(product, pz, picking.id, destination));
        moveLines.push(
          this.prepareMoveLineData(
            product,
            pz,
            picking.id,
            pack.id,
            destination
          )
        );
      }

      // Create moves and move lines
      const createdMoves = await firstValueFrom(
        this.odooEm.create<StockMove>(new StockMove(), moves)
      );
      moveLines.forEach((moveLine, index) => {
        moveLine.move_id = createdMoves[index].id;
      });
      await firstValueFrom(
        this.odooEm.create<StockMoveLine>(new StockMoveLine(), moveLines)
      );

      // Validate picking
      await this.check(
        this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [
          [picking.id],
        ])
      );

      alert("Tutti i pezzi sono stati salvati.");
    } catch (error) {
      console.error("Error during save:", error);
      alert(
        "Si è verificato un errore durante il salvataggio. Contattare l'assistenza."
      );
    } finally {
      this.activeProduct = null;
      this.loading = false;
    }
  }

  // Validate length input: only integers between 100 and 13500 are allowed
  validateLength(length: number): boolean {
    return (
      Number.isInteger(length) &&
      length >= 100 &&
      length <= 13500 &&
      /^[1-9]\d{2,}$/.test(length.toString())
    );
  }

  // Create a new picking
  private async createPicking(): Promise<StockPicking> {
    const pickingData = {
      picking_type_id: ODOO_IDS.picking_type_restolegno,
      origin: "V" + this.saleNumber,
      location_id: ODOO_IDS.stock_location_customer,
      location_dest_id: ODOO_IDS.stock_location_stock,
      group_id: this.sale.procurement_group_id.id,
    };
    return firstValueFrom(
      this.odooEm.create<StockPicking>(new StockPicking(), pickingData)
    );
  }

  // Create a new product variant
  async createProduct(
    product: Product,
    length: number,
    attributeName: string
  ): Promise<Product> {
    // Resolve product attributes
    await firstValueFrom(
      this.odooEm.resolve(product.product_template_attribute_value_ids)
    );

    // Find the length attribute and its index
    const lengthAttr = product.product_template_attribute_value_ids.values.find(
      (v) => v.attribute_id.name.startsWith(attributeName)
    );
    const attrIndex =
      product.product_template_attribute_value_ids.values.indexOf(lengthAttr);

    // Resolve product template
    await this.odooEm
      .resolveSingle(new ProductTemplate(), product.product_tmpl_id)
      .toPromise();
    const tmpl = product.product_tmpl_id.value;

    // Find or create attribute value for length
    //find
    let attrValue = (
      await this.odooEm
        .search<ProductAttributeValue>(new ProductAttributeValue(), [
          ["name", "=", length.toString()],
          ["attribute_id", "=", lengthAttr.attribute_id.id],
        ])
        .toPromise()
    )[0];

    //create
    if (!attrValue) {
      attrValue = await this.odooEm
        .create<ProductAttributeValue>(new ProductAttributeValue(), {
          name: length.toString(),
          attribute_id: lengthAttr.attribute_id.id,
        })
        .toPromise();
    }
    // Update attribute line
    await this.odooEm.resolve(tmpl.attribute_line_ids).toPromise();
    const attrLine = tmpl.attribute_line_ids.values[attrIndex];
    await this.odooEm
      .update(attrLine, { value_ids: [[4, attrValue.id]] })
      .toPromise();

    // Create product template attribute value
    await firstValueFrom(
      this.odooEm.create(new ProductTemplateAttributeValue(), {
        product_attribute_value_id: attrValue.id,
        attribute_line_id: attrLine.id,
        ptav_active: true,
      })
    );

    // Find the new product template attribute value
    const ptav = (
      await this.odooEm
        .search<ProductTemplateAttributeValue>(
          new ProductTemplateAttributeValue(),
          [
            ["attribute_id", "=", lengthAttr.attribute_id.id],
            ["product_attribute_value_id", "=", attrValue.id],
            ["product_tmpl_id", "=", lengthAttr.product_tmpl_id.id],
            ["ptav_active", "=", true],
          ]
        )
        .toPromise()
    )[0];

    // Create and fetch the new product variant
    const attributeValueIds =
      product.product_template_attribute_value_ids.values.map((v) => v.id);
    attributeValueIds[attrIndex] = ptav.id;

    const result: any = await this.odooEm.odoorpcService.sendRequest(
      "/api/sale/create_product_variant",
      {
        product_template_attribute_value_ids: JSON.stringify(attributeValueIds),
        product_template_id: product.product_tmpl_id.id,
      }
    );

    await this.odooEm.run(666, result.result, "product.product");

    const newProduct = await firstValueFrom(
      this.odooEm.search<Product>(new Product(), [["id", "=", result.result]])
    );

    if (!newProduct.length) {
      throw new Error("Errore nella creazione della variante");
    }

    return newProduct[0];
  }

  // Find or create a package
  private async findOrCreatePackage(
    packageName: string
  ): Promise<{ pack: StockQuantPackage; destination: number }> {
    const existingPackages = await firstValueFrom(
      this.odooEm.search<StockQuantPackage>(new StockQuantPackage(), [
        ["name", "=", packageName],
      ])
    );
    if (existingPackages.length > 0) {
      return {
        pack: existingPackages[0],
        destination: existingPackages[0].location_id.id,
      };
    } else {
      const newPack = await firstValueFrom(
        this.odooEm.create<StockQuantPackage>(new StockQuantPackage(), {
          name: packageName,
          location_id: ODOO_IDS.stock_location_stock,
        })
      );
      return { pack: newPack, destination: ODOO_IDS.stock_location_stock };
    }
  }

  // Prepare move data
  private prepareMoveData(
    product: Product,
    pz: any,
    pickingId: number,
    destination: number
  ): any {
    return {
      name: this.activeProduct.name,
      location_id: ODOO_IDS.stock_location_customer,
      location_dest_id: destination,
      product_id: product.id,
      product_uom_qty: pz.qty,
      picking_id: pickingId,
      group_id: this.sale.procurement_group_id.id,
    };
  }

  // Prepare move line data
  private prepareMoveLineData(
    product: Product,
    pz: any,
    pickingId: number,
    packId: number,
    destination: number
  ): any {
    return {
      product_id: product.id,
      picking_id: pickingId,
      qty_done: pz.qty,
      result_package_id: packId,
      location_id: ODOO_IDS.stock_location_customer,
      location_dest_id: destination,
    };
  }

  async check(f) {
    let r = await f;
    if (r.error) {
      this.loading = false;
      alert(r.error.data.message);
      throw r;
    }
    return r;
  }
}
