<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
    Movimenti di magazzino
  </a>

  <div class="dropdown ms-auto">
    <button class="btn btn-link text-white" data-bs-toggle="dropdown">
      <i class="fa fa-bars"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li><a class="dropdown-item" [routerLink]="['oddments']">Resto legno</a></li>
    </ul>
  </div>
</app-navbar>
<div class="navbar bg-light px-3">
  <div class="form d-flex flex-row align-items-center w-100">
    <span>Cerca</span>
    <input class="form-control mx-3 w-100" [ngModel]="searchInput | async"
      (ngModelChange)="searchInput.next($event); onSearchChange($event)" />
    <select [compareWith]="compareByString" class="form-control" [(ngModel)]="activePickingOption"
      (ngModelChange)="persist()">
      <option *ngFor="let o of this.picking_search_options | keyvalue" [ngValue]="o.value">
        {{ o.key }}
      </option>
    </select>

    <button class="btn ms-3 btn-lg" [ngClass]="{ 'bg-primary': isGroupedView }"
      (click)="isGroupedView = !isGroupedView">
      <i class="fa-regular fa-list-dropdown"></i>
    </button>
  </div>
</div>

@if (!loading){

<!--  NON GROUPED-->
<div class="d-flex" *ngIf="!isGroupedView" style="overflow-y: auto; overflow-x: hidden;">


  <div class="table-wrapper h-100 d-flex flex-column w-100" style="overflow-y:scroll;" [hidden]="loading">
    <table class="table table-bordered align-middle">
      <thead class="bg-light">
        <tr>
          <th>Origine</th>
          <th>Data</th>
          <th>Operazione</th>
        </tr>
      </thead>
      <tbody *ngIf="partsLoaded" class="">
        <tr *ngFor="let p of pickings.concat(productions) | sortByActivityDate" [routerLink]="[getPickingPage(p),p.id]"
          queryParamsHandling="preserve">
          <td>
            <div class="d-flex align-items-center">
              <span class="fa {{getPickingClass(p)}}">&nbsp;</span>
              <div class="text-nowrap" [innerHTML]="getDisplayTextNoGroup(p)">
              </div>
            </div>
          </td>
          <td>
            {{getDisplayDate(p) | Date4Humans}}
            <br>
            {{getDisplayUser(p)}}
          </td>
          <td>
            {{p.name}} <br>
            {{p.picking_type_id.name.replace("LOMAGNA:","")}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Grouped View -->
<div *ngIf="isGroupedView" class="h-100" style="overflow-y: auto; overflow-x: hidden; " [hidden]="loading">

  <div *ngIf="partsLoaded">
    <div *ngFor="let group of groupedPickings | keyvalue">
      <div class="bg-light p-2"><b>{{ group.key }}</b></div>
      <!-- Display pickings in this group -->
      <div class="table-responsive">
        <table class="table table-bordered align-middle">

          <tbody>
            <tr *ngFor="let p of group.value | sortByActivityDate" [routerLink]="[getPickingPage(p), p.id]"
              queryParamsHandling="preserve">
              <!-- Columns -->
              <td>
                <div class="d-flex align-items-center">
                  <span class="fa {{getPickingClass(p)}}">&nbsp;</span>
                  <div class="text-wrap" [innerHTML]="getDisplayTextGroup(p)">
                  </div>
                </div>
              </td>
              <td>
                {{getDisplayDate(p) | Date4Humans}}
                <br>
                {{getDisplayUser(p)}}
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
}