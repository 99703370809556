import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { OdooModel } from './odoo-model.model';
import { PurchaseOrder } from './order';
import { MailActivity } from './mail.message';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { AccountMoveLine } from './account-move-line.model';
import { Partner } from './partner';
import { User } from './user.model';
import { AccountPaymentTerm } from './account-payment-term.model';
import { Contact } from './contact.model';

export class AccountMove extends OdooModel implements OdooSerializableInterface<AccountMove> {

  public ODOO_MODEL = 'account.move';

  id: number; // ID
  amount_residual: number = 0; // Amount not yet paid
  amount_paid: number = 0; // Amount paid
  amount_tax: number = 0; // Amount tax
  amount_untaxed: number = 0; // Amount untaxed
  amount_total: number = 0; // Total amount
  create_date: string = ""; // Creation date
  invoice_date: string = ""; // Invoice date
  activity_ids: OdooMultiRelationship<MailActivity> = new OdooMultiRelationship<MailActivity>(MailActivity);
  purchase_id: OdooRelationship<PurchaseOrder> = new OdooRelationship<PurchaseOrder>();
  invoice_line_ids: OdooMultiRelationship<AccountMoveLine> = new OdooMultiRelationship<AccountMoveLine>(AccountMoveLine);
  invoice_origin: string = ""; // Source document (Lead)
  invoice_payment_term_id: OdooRelationship<AccountPaymentTerm> = new OdooRelationship<AccountPaymentTerm>();
  invoice_user_id: OdooRelationship<User> = new OdooRelationship<User>();
  partner_id: OdooRelationship<Contact> = new OdooRelationship<Contact>();
  state: ""|"draft"|"posted"|"cancel"= ""; // Status
  type_name: string = ""; // Type
  move_type: ""|"out_invoice"|"in_invoice"= ""; // Type
  narration: string = ""; // Notes
  journal_id: number = 0; // Journal
  _expanded: boolean = false; // Expanded


  
  


  create(): AccountMove {
    return new AccountMove();
  }
}


