<!-- Main container for the entire component -->
<router-outlet>
</router-outlet>

<!-- Navigation bar at the top of the page -->
<app-navbar [loading]="loading" backroute=".." *ngIf="contact">
  <!-- Display the contact's name as the navbar brand -->
  <a href="" class="navbar-brand">{{contact?.name}}</a>

  <!-- Additional navbar content -->
  <ng-container>
    <!-- Dropdown for messages -->
    <div class="dropdown ms-auto" *ngIf="contact?.id">
      <i id="dropdownMenuButtonC" data-bs-toggle="dropdown"
        class="fa-duotone fa-lg fa-comment fa-lg text-white"></i>

      <div class="dropdown-menu dropdown-menu-end" 
        aria-labelledby="dropdownMenuButtonC"> 
        <!-- Message widget component -->
        <app-message-widget [model]="'res.partner'" [res_id]="contact.id"></app-message-widget>
      </div> 
    </div>

    <!-- Badge to show if the contact is archived -->
    <span *ngIf="!contact.active" class="badge bg-primary">In archivio</span>

    <!-- Dropdown menu for additional actions -->
    <div class="dropdown ms-2" >
      <button class="btn btn-link text-white " type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-bars"></i>
      </button>

      <ul class="dropdown-menu dropdown-menu-end">
        <li *ngIf="contact.active"><a class="dropdown-item" (click)="onDelete()">Download vcard</a></li>
        <li *ngIf="contact.active"><a class="dropdown-item" (click)="onDelete()">Archivia</a></li>
        <li *ngIf="!contact.active"><a class="dropdown-item" (click)="restore()">Ripristina</a></li>
      </ul>
    </div>
  </ng-container>
</app-navbar>

<!-- Main content area -->
<div *ngIf="contact" class="container mt-3">
  <!-- Form for contact details -->
  <!-- Each row represents a field in the contact form -->
  <div class="row py-1 mt-2">
    <div class="col-4 align-items-center d-flex">Tipo</div>
    <div class="col d-flex text-nowrap">
      <!-- Radio buttons for company/person selection -->
      <div class="form-check">
        <input class="form-check-input" type="radio" name="type" id="flexRadioDefault2" checked [value]="true"
          [(ngModel)]="contact.is_company" (ngModelChange)="update('is_company')">
        <label class="form-check-label" for="flexRadioDefault2">
          Azienda
        </label>
      </div>

      <div class="form-check ms-2 mb-2 ">
        <input class="form-check-input" type="radio" name="type" id="flexRadioDefault1" [value]="false"
          [(ngModel)]="contact.is_company" (ngModelChange)="update('is_company')">
        <label class="form-check-label" for="flexRadioDefault1">
          Persona
        </label>
      </div>
    </div>
  </div>

  <!-- Name field -->
  <div class="row py-1 mt-2">
    <div class="col-4 align-items-center d-flex">Nome</div>
    <div class="col">
      <input name="name" [(ngModel)]="contact.name" (change)="update('name')" class="form-control uppercase">
    </div>
  </div>

  <!-- VAT number field with validation button -->
  <div class="row py-1">
    <div class="col-4 align-items-center d-flex">IVA</div>
    <div class="col">
      <div class="input-group">
        <input class="form-control" name="vat" [(ngModel)]="contact.vat" (change)="update('vat')">
        <div class="input-group-append" *ngIf="contact.vat">
          <button class="btn btn-muted text-white" type="button" (click)="onVAT(contact.vat)">Controlla</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Address fields -->
  <div class="row py-1">
    <div class="col-4 align-items-center d-flex">Via</div>
    <div class="col">
      <input name="street" [(ngModel)]="contact.street" (change)="update('street')" class="form-control uppercase">
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center d-flex">Città</div>
    <div class="col">
      <input name="city" [(ngModel)]="contact.city" (change)="update('city')" class="form-control uppercase">
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center d-flex">CAP</div>
    <div class="col">
      <input name="zip" [(ngModel)]="contact.zip" (change)="update('zip')" class="form-control uppercase">
    </div>
  </div>

  <!-- ARCA code field -->
  <div class="row py-1">
    <div class="col-4 align-items-center d-flex">Codice ARCA</div>
    <div class="col">
      <input name="arca" [(ngModel)]="contact.ga_arca" (change)="update('ga_arca')" class="form-control uppercase">
    </div>
  </div>
</div>

<!-- Navigation tabs for additional information -->
<div class="navbar bg-info mt-4" *ngIf="contact">
  <div class="container">
    <!-- Tab navigation -->
    <ul *ngIf="!isViewingChild" class="nav nav-pills" id="contact-tabs" role="tablist">
      <!-- Recapiti (Details) tab -->
      <li class="nav-item" role="presentation">
        <button class="nav-link" [class.active]="activeTab === 'recapiti'" id="recapiti-tab" 
                (click)="setActiveTab('recapiti')" type="button" role="tab">
          <i class="fa fa-vcard"></i>&nbsp;Recapiti
        </button>
      </li>
      <!-- Commesse (Leads) tab -->
      <li class="nav-item" role="presentation">
        <button class="nav-link" [class.active]="activeTab === 'commesse'" id="commesse-tab" 
                (click)="setActiveTab('commesse')" type="button" role="tab">
          Commesse
        </button>
      </li>
      <!-- Vendite su lista tab -->
      <li class="nav-item" role="presentation">
        <button class="nav-link" [class.active]="activeTab === 'vendite'" id="vendite-tab" 
                (click)="setActiveTab('vendite')" type="button" role="tab">
          Vendite su lista
        </button>
      </li>
    </ul>
    <!-- Button to add new contact, only visible in Recapiti tab -->
    <div class="d-flex" *ngIf="activeTab === 'recapiti'">
      <button class="btn btn-dark" (click)="newPerson()"><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>

<!-- Tab content -->
<div *ngIf="!isViewingChild" class="container tab-content h-100 mt-2">
  <!-- Recapiti (Details) tab content -->
  <div class="tab-pane fade" [class.show]="activeTab === 'recapiti'" [class.active]="activeTab === 'recapiti'" id="tabcontatti" role="tabpanel">
    <app-contact-picker *ngIf="contact" #picker (loading)="loading = $event" [contact]="contact" [emptyForFalse]="true"></app-contact-picker>
  </div>

  <!-- Commesse (Leads) tab content -->
  <div class="tab-pane fade" [class.show]="activeTab === 'commesse'" [class.active]="activeTab === 'commesse'" id="tabcommesse" role="tabpanel">
    <!-- Table to display leads -->
    <div class="table-responsive mt-3">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Fascicolo</th>
            <th>Città</th>
            <th>Descrizione</th>
            <th>Stato</th>
            <th>Valore contratto</th>
            <th>Responsabile</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lead of leads" (click)="redirectDeal(lead.id)" style="cursor: pointer;">
            <td>{{ lead.tracking_code }}</td>
            <td>{{ lead.city }}</td>
            <td>{{ lead.name }}</td>
            <td>{{ lead.stage_id.name }}</td>
            <td>{{ lead._totalContractAmount | currency:'EUR':'symbol':'1.2-2' }}</td>
            <td>{{ lead.user_id.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!leads || leads.length === 0" class="alert alert-info mt-3">
      No leads found for this contact.
    </div>
  </div>

  <!-- Vendite su lista tab content -->
  <div class="tab-pane fade" [class.show]="activeTab === 'vendite'" [class.active]="activeTab === 'vendite'" id="tabvendite" role="tabpanel">
    <!-- Table to display sales -->
    <div class="table-responsive mt-3">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Vendita</th>
            <th>Descrizione</th>
            <th>Stato</th>
            <th>Importo</th>
            <th>Responsabile</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sale of sales" (click)="redirectSale(sale.id)" style="cursor: pointer;">
            <td>{{ sale.name }}</td>
            <td>{{ sale.ga_title }}</td>
            <td>{{ sale._delivery_state }}</td>
            <td>{{ sale.amount_untaxed | currency:'EUR':'symbol':'1.2-2' }}</td>
            <td>{{ sale.user_id.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!sales || sales.length === 0" class="alert alert-info mt-3">
      No sales found for this contact.
    </div>
  </div>
</div>