import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MailMessage } from 'src/app/models/mail.message';
import { MailNotification } from 'src/app/models/mail.notification';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  @Input() backroute?:string
  @Input() loading

  @Input() leadId?:string
  

  channels: any;
  messages: MailMessage[];
  notifications: MailNotification[];
  constructor(
  ) { }

}
