<app-navbar  [loading]="loading" backroute="..">
    <a class="navbar-brand">
      Da bollare
    </a>
</app-navbar>


<div class="navbar bg-light px-3">
    <div class="form d-flex flex-row align-items-center w-100">
        <span>Cerca</span>   
        <input class="form-control mx-3 w-100" [(ngModel)]="searchString"  (ngModelChange)="refresh()">
        <button 
            [ngClass]="{
                'btn-primary text-white': filterJustReady , 
                'btn-primary-outline text-primary': !filterJustReady, 
            }"
            (click)="filterJustReady = !filterJustReady; refresh();"
            class="btn btn-primary  text-nowrap me-2">
            Solo pronte
        </button>
    </div>
</div>

  <div class="table-wrapper h-100 overflow-scroll" style="overflow-y:scroll;" [hidden]="loading" >
    <table class="table table-bordered align-middle">
        <thead class="bg-light">
            <tr>
                <th>Operazione</th>
                <th>Fascicolo</th>
                <th>Fatturazione</th>
                <th>Per</th>
                <th>Nome</th>
                <th>Origine</th>
                <th>Data</th>
            </tr>
        </thead>
        <tbody >
             <tr *ngFor="let p of pickings" [routerLink]="[p.id]">
                <td class="text-muted text-nowrap">
                    <i class="fa fa-circle" 
                        [ngClass]="{
                            'text-success': p.state == 'assigned', 
                            'text-warning': p.state == 'waiting', 
                            'text-primary': p.state == 'done'  
                            }"
                    ></i>
                    {{p.name}} 
                </td>
                <td>
                    <span >
                        {{p.group_id.value.sale_id?.value.opportunity_id.value?.tracking_code  ? p.group_id.value.sale_id?.value.opportunity_id.value?.tracking_code : 'No'}}
                    </span>
                  
                </td>
                <td>
                    {{p.group_id.value.sale_id?.value?.pricelist_id?.name.replace("  (EUR)", "")}}
                </td>
                <td>
                    {{p.partner_id.name}}
                </td>
                <td>
                    {{p.group_id.value?.sale_id?.value?.ga_title}}
                </td>
                <td>
                    {{p.group_id.value?.sale_id?.name}} - {{p.group_id.value?.sale_id?.value.user_id.name}}
                </td>
                <td>
                    {{p.date_deadline | Date4Humans }}
                </td>
                <!-- <td>
                    <span *ngIf="p.group_id?.value">
                    {{p.group_id}} -
                    {{p.partner_id?.name}}
                    </span>
                    <span *ngIf="!p.group_id.value ">
                        {{p.origin}} -
                        {{p.partner_id?.name}}
                    </span>
                </td>
                
                <td class="text-muted">
                    <span>{{p.picking_type_id.name.split(":")[1]}}</span>
                </td>
                <td>
                    {{p.scheduled_date | Date4Humans }}
                </td> -->
            </tr> 
        </tbody>
        
    </table>
  </div>
