import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { SaleOrder } from './sale-order.model';


export class AccountTax extends OdooModel implements OdooSerializableInterface<AccountTax> {
  public readonly ODOO_MODEL = 'account.tax';
  
  id : number = 0
  name : string = ""
  type_tax_use : ""|"sale"|"purchase"|"none" = ""
  amount : number = 0
  
  constructor(id?: number) {
    super(id);
  }

  create(): AccountTax {
    return new AccountTax();
  }
}


