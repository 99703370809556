<style>



    #mainParent {
        height: 90vh;
        padding: 5px;
    }

    @media (min-width: 550px) {
        #mainParent {
            width: 550px;
        }
    }

    @media (max-width: 768px) {
        #mainParent {
            width: 300px;
        }
    }

    .gray {
        color: #6c757d
    }

    .alignLeft {
        left: 10px !important;
        right: auto !important;
    }

    .w-messages .icon-smile {
        display: none;
    }

    .w-messages:hover .icon-smile {
        display: block !important;
    }

    .suggestions-container {
        position: absolute;
        z-index: 1000;
    }

    .suggestion-item {
        cursor: pointer;
        text-align: start;
    }

    .scrollable-dropdown-content {

        height: 100%;
        overflow-y: auto;
    }

    .input-icon-container {
        position: relative;
    }

    .input-icon-container .form-control {
        padding-right: 30px;
        /* Adjust padding to ensure text does not overlap the icon */
    }

    .input-icon-container .icon {
        position: absolute;
        top: 5px;
        bottom: 0;
        right: 10px;
        /* Adjust based on preference */
        margin: auto 0;
        cursor: pointer;
        height: 20px;
        /* Adjust based on the size of your icon */
        /* pointer-events: none; */
        /* Makes the icon non-interactive */
    }

    .bg-gray {
        background-color: #eee;
    }

    .bg-green {
        background-color: #d4edda;
    }

    .w-messages {
        display: block;
    }

    .w-messages>div {
        max-width: 70%;
        position: relative;
        /* Make this a position reference for its children */
        margin-bottom: 20px;
    }

    .reactions {
        position: relative;
        bottom: -15px;
        z-index: 1;
    }

    .w-messages .smiles {
        position: relative;
        /* Adjust based on your layout */
        bottom: -20px
            /* Adjust based on your preference */
    }

    .smiles2 {
        bottom: 55px;
        right: 20px;
    }
</style>

<div id="mainParent" class="d-flex flex-column justify-content-between" (click)="$event.stopPropagation()">
    <div #scrollableContent class="scrollable-dropdown-content">
      <ng-container *ngFor="let group of groupMessage">
        <div class="d-flex justify-content-center mb-2">
          <div class="bg-gray text-dark p-2 rounded-5 px-3">
            {{ group.dateFromNow }}
          </div>
        </div>
  
        <div *ngFor="let message of group.messages" class="w-messages d-flex"
             [ngClass]="{'justify-content-end': message.author_id.id === userId}">
          
          <button *ngIf="message.author_id.id === userId"
                  (click)="$event.stopPropagation(); toggleReactions(message.id)" 
                  type="button"
                  class="btn btn-link icon-smile"
                  aria-label="Add reaction">
            <i class="fal fa-smile" title="Inserisci emoji"></i>
          </button>
  
          <div [ngClass]="{'bg-green': message.author_id.id === userId, 'bg-gray': message.author_id.id !== userId}"
               class="rounded-3 p-2 message-bubble">
            <div [ngClass]="{'text-end': message.author_id.id === userId}">
              <div class="d-flex flex-column justify-content-between h-100">
                <strong *ngIf="message.author_id.id != userId" class="message-author">
                  <small>{{ message.author_id.name }}</small>
                </strong>
                <p class="message-body">{{ message.body | removeHtml }}</p>
                <div class="mt-2 text-end"><small class="fs-6 message-time">{{message.dateHour}}</small></div>
              </div>
  
              <div *ngIf="message.attachment_ids?.values?.length > 0" class="mt-2 attachments-container">
                <div *ngFor="let attachment of message.attachment_ids.values" class="mt-2 attachment-item">
                  <a [href]="'/api/web/content/ir.attachment/' + attachment.id + '/datas?download=true'"
                     [title]="attachment.name" target="_blank" class="d-block attachment-link">
                    <i class="fa fa-download"></i> {{ attachment.name }}
                  </a>
                  <img *ngIf="isImage(attachment)" 
                       [src]="'/api/web/content/ir.attachment/' + attachment.id + '/datas'"
                       [alt]="'Preview of ' + attachment.name"
                       class="img-preview mt-2 img-fluid rounded">
                </div>
              </div>
  
              <div *ngIf="message.reaction_ids?.values?.length > 0" 
                   class="reactions mt-2"
                   [ngClass]="{'text-end': message.author_id.id === userId}">
                <span *ngFor="let reaction of message.reaction_ids.values" 
                      class="p-1 bg-gray rounded-4 reaction-bubble"
                      [attr.title]="reaction.partner_id.name" 
                      data-bs-toggle="tooltip"
                      data-bs-placement="top">
                  {{ reaction.content }}
                </span>
              </div>
            </div>
  
            <div *ngIf="currentReactionMessageId === message.id" class="smiles position-relative">
              <div class="btn-group btn-group-sm" role="group" aria-label="Message reactions">
                <button type="button" (click)="setReaction($event, message, '😀')" class="btn btn-light">😀</button>
                <button type="button" (click)="setReaction($event, message, '😍')" class="btn btn-light">😍</button>
                <button type="button" (click)="setReaction($event, message, '👍')" class="btn btn-light">👍</button>
                <button type="button" (click)="setReaction($event, message, '⭐')" class="btn btn-light">⭐</button>
                <button type="button" (click)="setReaction($event, message, '😲')" class="btn btn-light">😲</button>
              </div>
            </div>
          </div>
  
          <button *ngIf="message.author_id.id !== userId"
                  (click)="$event.stopPropagation(); toggleReactions(message.id)" 
                  type="button"
                  class="btn btn-link icon-smile"
                  aria-label="Add reaction">
            <i class="fal fa-smile" title="Inserisci emoji"></i>
          </button>
        </div>
      </ng-container>
    </div>
  
    <div class="d-flex mt-2 message-input-container">
      <button class="btn btn-link me-1" id="attachmentButton" (click)="$event.stopPropagation()">
        <label for="fileInput" role="button" aria-label="Attach file">
          <i class="fa fa-plus gray fa-lg"></i>
        </label>
        <input type="file" id="fileInput" class="d-none" (change)="uploadFile($event)" multiple accept="*/*">
      </button>
  
      <div class="position-relative w-100 me-2">
        <input #messageInput type="text" class="form-control rounded-5" [(ngModel)]="message" 
               (keyup)="onKeyup($event)"
               placeholder="Scrivi un messaggio" aria-label="Message input">
        <button (click)="$event.stopPropagation(); showReactions = !showReactions" 
                class="btn btn-link position-absolute end-0 top-50 translate-middle-y"
                aria-label="Show emoji picker">
          <i class="fal fa-smile gray"></i>
        </button>
      </div>
  
      <button *ngIf="message && message.trim() !== ''" 
              class="btn btn-link me-1" 
              (click)="$event.stopPropagation(); confirmMessage()"
              aria-label="Send message">
        <i class="fad fa-2x fa-arrow-circle-right text-primary"></i>
      </button>
    </div>
  
    <div *ngIf="showReactions" class="smiles2 position-absolute bottom-100 end-0 mb-2">
      <div class="btn-group btn-group-sm" role="group" aria-label="Emoji picker">
        <button type="button" (click)="insertSmile($event, null, '😀')" class="btn btn-light">😀</button>
        <button type="button" (click)="insertSmile($event, null, '😍')" class="btn btn-light">😍</button>
        <button type="button" (click)="insertSmile($event, null, '👍')" class="btn btn-light">👍</button>
        <button type="button" (click)="insertSmile($event, null, '⭐')" class="btn btn-light">⭐</button>
        <button type="button" (click)="insertSmile($event, null, '😲')" class="btn btn-light">😲</button>
      </div>
    </div>
  
    <div *ngIf="showSuggestions" class="suggestions-container mt-2 w-100">
      <ul class="list-group">
        <li *ngFor="let suggestion of suggestions; let i = index"
            class="list-group-item suggestion-item"
            (click)="$event.stopPropagation(); selectSuggestion(suggestion)"
            [class.active]="i === selectedSuggestionIndex"
            (mouseover)="selectedSuggestionIndex = i">
          {{ suggestion.name }}
        </li>
      </ul>
    </div>
  
    <div *ngIf="selectedFiles.length > 0" class="mt-2 selected-files-container">
      <ul class="list-group list-group-flush">
        <li *ngFor="let file of selectedFiles"
            class="list-group-item list-group-item-action list-group-item-success py-1">
          <small>{{ file.name }}</small>
        </li>
      </ul>
    </div>
  </div>