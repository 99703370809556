import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ODOO_IDS, AREAS_CFG } from "../models/deal";
import { FormsModule } from '@angular/forms';
import { NavbarComponent } from "../components/navbar/navbar.component";
import { MailActivity } from "../models/mail.message";
import { OdooEntityManager } from "../shared/services/odoo-entity-manager.service";
import { Contact } from "../models/contact.model";
import { BehaviorSubject, debounceTime, firstValueFrom } from "rxjs";
import { AccountMove } from "../models/account-move.model";
import { Lead } from "src/app/models/crm.lead.model";

import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../models/user.model";

@Component({
  selector: "app-invoice-search",
  templateUrl: "./invoice-search.component.html",
  styleUrls: ["./invoice-search.component.scss"],
})
export class InvoiceSearchComponent implements OnInit {
  loading: boolean = false;
  leads: Lead[] = [];
  selectedUser: User | null = null;
  selectedUserId: number | null = null;
  startDate: string | null = null;
  users: User[] = [];
  endDate: string | null = null;
  filteredInvoices: AccountMove[] = [];
  searchInput: BehaviorSubject<string> = new BehaviorSubject("");
  invoices: any[] = [];
  areas: { name: string; src: string; dst: string }[];
  selectedArea: string = "";
  @Output() onSelect: EventEmitter<boolean> = new EventEmitter();
  @Input() embedded: boolean = false;

  constructor(
    private odooEm: OdooEntityManager,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.areas = AREAS_CFG;
    this.loadInvoices();
    this.searchInput.pipe(debounceTime(250)).subscribe(() => {
      this.loadInvoices();
    });
  }

  async loadInvoices() {
    this.loading = true;

    // Step 1: Search all non-posted invoices with journal_id[0] == ODOO_IDS.sal_id
    this.filteredInvoices = await firstValueFrom(
      this.odooEm.search<AccountMove>(new AccountMove(), [
        ["journal_id", "=", ODOO_IDS.sal_id], ["state", "!=", "posted"]
      ])
    );

     // Step 2: Filter invoices with activities
     this.filteredInvoices = this.filteredInvoices.filter(
      (invoice) => invoice.activity_ids.ids.length > 0
    );

   // Step 3: Collect unique users from filtered invoices
   this.users = [];
   for (const invoice of this.filteredInvoices) {
     if (invoice.invoice_user_id && invoice.invoice_user_id.id && 
         !this.users.some(user => user.id === invoice.invoice_user_id.id)) {
       this.users.push(new User(invoice.invoice_user_id.id, invoice.invoice_user_id.name));
     }
   }

   console.log("Unique Users: ", this.users);
  

console.log("Unique Users: ", this.users);
console.log("selected user: ", this.selectedUser);

    console.log("Unique Users: ", this.users);
   // Step 4: Fetch leads for tracking codes
   let trackingCodes = this.filteredInvoices.map(
    (invoice) => invoice.invoice_origin
  );
  console.log("Tracking Codes", trackingCodes);

  let criteria = [["tracking_code", "in", trackingCodes]];
  if (this.selectedArea) {
    criteria.push(["area", "=", this.selectedArea]);
  }
  this.leads = await firstValueFrom(
    this.odooEm.search<Lead>(new Lead(), criteria)
  );

  // Step 5: Search input filter (Customer, tracking code, etc.)
  const searchInputLower = this.searchInput.value
    ? this.searchInput.value.toLowerCase()
    : "";
  const filteredLeads = this.leads.filter((lead) => {
    const partnerMatch = lead.partner_id.name
      ?.toLowerCase()
      .includes(searchInputLower);
    const trackingCodeMatch = lead.tracking_code
      ?.toLowerCase()
      .includes(searchInputLower);
    const nameMatch = lead.name?.toLowerCase().includes(searchInputLower);
    const streetMatch = lead.street?.toLowerCase().includes(searchInputLower);
    const cityMatch = lead.city?.toLowerCase().includes(searchInputLower);

    return (
      partnerMatch || trackingCodeMatch || nameMatch || streetMatch || cityMatch
    );
  });

  // Step 6: Get tracking codes from filtered leads
  const filteredTrackingCodes = filteredLeads.map(
    (lead) => lead.tracking_code
  );

  // Step 7: Filter invoices by tracking codes from leads
  let filteredInvoicesByLeads = this.filteredInvoices.filter((invoice) =>
    filteredTrackingCodes.includes(invoice.invoice_origin)
  );

  // Step 8: Apply user filter
  if (this.selectedUser) {
    console.log("filtering by user: ", this.selectedUser, this.filteredInvoices);
    filteredInvoicesByLeads = filteredInvoicesByLeads.filter(
      (invoice) => invoice.invoice_user_id.id === this.selectedUser.id
    );
  }

  // Step 9: Apply date filters
  if (this.startDate) {
    filteredInvoicesByLeads = filteredInvoicesByLeads.filter(
      (invoice) => new Date(invoice.invoice_date) >= new Date(this.startDate)
    );
  }
  if (this.endDate) {
    filteredInvoicesByLeads = filteredInvoicesByLeads.filter(
      (invoice) => new Date(invoice.invoice_date) <= new Date(this.endDate)
    );
  }

  console.log("Filtered Invoices: ", filteredInvoicesByLeads);
  this.filteredInvoices = filteredInvoicesByLeads;
  this.loading = false;
}

  getLead(trackingCode: string): Lead {
    const lead = this.leads.find((lead) => lead.tracking_code === trackingCode);
    return lead ? lead : null;
  }

  onUserChange(userId: number | null) {
    this.selectedUser = userId ? this.users.find(user => user.id === userId) || null : null;
    this.loadInvoices();
  }

  getAreaBadgeClass(area: string): string {
    switch (area) {
  case 'Tetti':
    return 'bg-success text-white';
  case 'Case':
    return 'bg-danger text-white';
  case 'Facciate e Decking':
    return 'bg-secondary text-white';
  case 'Aziendale':
    return 'bg-muted text-dark'; // 'bg-muted' is typically light gray; using 'bg-light' for clarity
  default:
    return 'bg-warning text-dark';

  }
}
  redirectDeal(id) {
    if (!this.embedded)
      // this.router.navigate([],  });


      window.open("leads/" + id, "_blank");
      //this.router.navigate(["leads/" + id]);
    else {
      this.onSelect.emit(id);
      // this.
    }
  }

  filterByDate(invoiceDate: string): boolean {
    const date = new Date(invoiceDate);
    const startDate = this.startDate ? new Date(this.startDate) : null;
    const endDate = this.endDate ? new Date(this.endDate) : null;

    if (startDate && date < startDate) return false;
    if (endDate && date > endDate) return false;

    return true;
  }

  resetFilters() {
    this.selectedUser = null;
    this.selectedUserId = null;
    this.startDate = null;
    this.endDate = null;
    this.loadInvoices();
  }
}
