import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RestapiService, QuerySearchOptions, ApiEvent, EventType, QueryPostOptions, QueryCriteria } from 'src/app/shared/services/rest-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AREAS_CFG } from '../../models/deal'
import { ViewChild } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooRelationship } from 'src/app/models/odoo-relationship.model';
import { AccountMove } from 'src/app/models/account-move.model';
import { ODOO_IDS } from '../../models/deal';
import { MailFollower } from 'src/app/models/mail.followers';

@Component({
  selector: 'app-deals-dashboard',
  templateUrl: './deals-dashboard.component.html',
})
export class DealsDashboardComponent implements OnInit, AfterViewInit {
  stages: CrmStage[] = []
  cards: Lead[] = []
  filteredCards: Lead[] = []
  @Input() loading: boolean = true
  searchDealsInput: string = ''
  areas: { name: string; src: string; dst: string; }[]
  newDeal:Lead
  filter: BehaviorSubject<string> 
  justRecents = true
  @Input() title = "Fascicoli"
  @ViewChild('searchInput', {static:true}) searchInput: ElementRef
  @Input() embedded:boolean = false
  @Output() onSelect:EventEmitter<boolean> = new EventEmitter()
  lastSearch: number;
  currentUserName: string = ''; // New property to hold the logged-in user's name

  
  // Updated filter variables
  users: string[] = [];
  selectedUser: string = '';
  startDate: string = '';
  endDate: string = '';

  constructor(
    public restapi : RestapiService,
    private router: Router,
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private odooEM: OdooEntityManager
  ) {
    // Cache filter value
    const i = localStorage.getItem('deals-dashboard-filter') || '';
    this.filter = new BehaviorSubject(i);
    this.filter.subscribe((f) => {
      // Sync new deal area default with active filter
      localStorage.setItem('deals-dashboard-filter', f);
      if (this.newDeal) this.newDeal.area = f;
      this.refresh(); // Call refresh to update the results
    });
  }

  async ngOnInit() {
    this.areas = AREAS_CFG;
    this.newDeal = new Lead();
    this.newDeal.area = this.filter.value;
    this.newDeal.partner_id = new OdooRelationship();
    this.stages = await firstValueFrom(this.odooEM.search<CrmStage>(new CrmStage(), []));

    // const user = await this.odooEM.getCurrentUser();
    // this.currentUserName = user.name;
    // this.selectedUser = this.currentUserName;  Set selectedUser to current user's name

    this.route.queryParams.subscribe((params) => {
      this.searchDealsInput = params.search || '';
      this.refresh();
    });
  }

  ngAfterViewInit(): void {
    // Debounce search input changes
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(debounceTime(400))
      .subscribe((e) => {
        this.searchDealsInput = (e as any).target.value;
        this.router.navigate([], { queryParams: { search: this.searchDealsInput } });
        this.refresh();
      });
  }

  async refresh() {
    this.loading = true;
    const x = Math.random();
    this.lastSearch = x;
  
    let conditions: any[] = [['company_id', '=', 1]];
  
    // Apply area filter
    if (this.filter.getValue()) {
      conditions.push(['area', '=', this.filter.getValue()]);
    }
  
    // Apply date filters
    if (this.startDate) {
      conditions.push(['create_date', '>=', this.startDate]);
    }
    if (this.endDate) {
      conditions.push(['create_date', '<=', this.endDate]);
    }
  
    // Apply "Solo Recenti" filter (based on write_date)
    if (this.justRecents) {
      const d = new Date();
      d.setMonth(d.getMonth() - 3);
      conditions.push(['write_date', '>=', d.toISOString()]);
    }
  
    // Apply user filter
    if (this.selectedUser) {
      conditions.push(['user_id.name', '=', this.selectedUser]);
    }
  
    // Check if the search input matches a Sale Order number or tracking code
    const saleOrderRegex = /^[vVfF]?\d{4,}$/;
    if (this.searchDealsInput && saleOrderRegex.test(this.searchDealsInput)) {
      // Remove leading V/v/F/f if present to get the numeric part
      const numberPart = this.searchDealsInput.replace(/^[vVfF]/, '');
  
      // Search for Sale Orders matching the number part
      const saleOrderConditions = [['name', 'ilike', numberPart]];
      const saleOrders = await firstValueFrom(
        this.odooEM.search<SaleOrder>(new SaleOrder(), saleOrderConditions)
      );
  
      if (saleOrders.length > 0) {
        const saleOrderIds = saleOrders.map((so) => so.id);
        // Combine conditions to find leads associated with these sale orders or with matching tracking code
        conditions = [
          ...conditions,
          '|',
          ['order_ids', 'in', saleOrderIds],
          ['tracking_code', 'ilike', numberPart],
        ];
      } else {
        // If no sale orders are found, search for leads with matching tracking code
        conditions.push(['tracking_code', 'ilike', numberPart]);
      }
    } else if (this.searchDealsInput) {
      // Apply search input
      conditions.push('|', '|', '|');
      conditions.push(['partner_id.name', 'ilike', this.searchDealsInput]);
      conditions.push(['name', 'ilike', this.searchDealsInput]);
      conditions.push(['tracking_code', 'ilike', this.searchDealsInput]);
      conditions.push(['street', 'ilike', this.searchDealsInput]);
    }
  
    try {
      const r = await firstValueFrom(
        this.odooEM.search<Lead>(new Lead(), conditions, 500, null, 'tracking_code asc')
      );
  
      if (this.lastSearch !== x) {
        return;
      }
      this.cards = r;

      //resolve message_follower_ids

      await firstValueFrom(this.odooEM.resolveArray(new MailFollower, this.cards, 'message_follower_ids'))
  
      // Extract unique users for the user filter dropdown
      this.users = Array.from(new Set(this.cards.map((c) => c.user_id.name))).filter(
        (user) => !!user
      );
  
      // Ensure current user is in the users list
      if (this.currentUserName && !this.users.includes(this.currentUserName)) {
        this.users.push(this.currentUserName);
      }
      this.calculateTotalContracts();

    } catch (error) {
      console.error('Error loading deals:', error);
    } finally {
      this.loading = false;
      this._cdr.detectChanges(); // Force change detection
    }
  }

  onContact(c) {
    if (c == null)
      this.newDeal.partner_id = new OdooRelationship()
    else {
      this.newDeal.partner_id = new OdooRelationship(c.id, c.name)
    }
    // this.newDeal.partner_id = [c.id,''];
  }
  
  onCreate() {
    this.createDeal()
  }


    // Calculate the total contracts for each lead
  async calculateTotalContracts()  {
    //sum the untexed amount of all the invoices related to the lead that have journal_id = ODOO_IDS.contratti_id
    
    let tracks = this.cards.map((c) => c.tracking_code);

    let Invoices = await firstValueFrom(this.odooEM.search<AccountMove>(new AccountMove(), [['invoice_origin', 'in', tracks] , ['journal_id', '=', ODOO_IDS.contratti_id]]));
    
    console.log('Invoices:', Invoices);
    // assign the total amount to the lead
    for (let l of this.cards) {
      let total = 0;
      for (let i of Invoices) {
        if (l.tracking_code == i.invoice_origin) {
          total += i.amount_untaxed;
        }
      }
      l._totalContractAmount = total;
    }
   
  }

  
  pickContact() {
    var w = window.open('/contact?mode=embedded','_blank','height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes' );
    
    var h = event => {
      var d = JSON.parse(event.data)
      this.newDeal.partner_id = new OdooRelationship(d.id,d.name)
      w.close()
    }
    window.addEventListener("message", h, false);
    w.onbeforeunload = () => {
      window.removeEventListener("message", h)
    }
  }

  async createDeal() {

    this.loading = true
    // todo move in abstarct model
    var x = {

              "area": this.newDeal.area, 
              "partner_id": this.newDeal.partner_id.id,
              "name":"",
              "description":"",
              "city":"",
              "street":"",
              "contact_name":  this.newDeal.partner_id.name,
              "type": "opportunity"
            }

   // if (this.newDeal._isExpressSale) {
     // x['stage_id'] = 4 // da fatturare
     // x['name'] = "Vendita banco"
   // }

    // var postOptions : QueryPostOptions = {
    //   table : "crm.lead",
    //   json_fields : JSON.stringify(x)
    // }
    
    await this.odooEM.create<Lead>(new Lead(),x).subscribe(async (res) => {

      // if (this.newDeal._isExpressSale) {
      //   let s = {
      //     partner_id: this.newDeal.partner_id.id,
      //     opportunity_id: res.id
      //   }
      //   await firstValueFrom(this.odooEM.create<SaleOrder>(new SaleOrder(), s)) 
      // }

      this.loading = false
      // Add the new deal to the cards array
      // this.cards.unshift(JSON.parse(JSON.stringify(res)))
      this.redirectDeal(res.id)
    })

  }

  redirectDeal(id) {
    if (!this.embedded)
      // this.router.navigate([],  });

      this.router.navigate(['leads/'+id], { queryParams: { search: this.searchDealsInput }});
    else {
      this.onSelect.emit(id)
      // this.
    }
  }

  resetFilters() {
    this.searchDealsInput = '';
    this.selectedUser = this.currentUserName; // Reset to current user
    this.startDate = '';
    this.endDate = '';
    this.justRecents = true;
    this.filter.next(''); // Reset area filter
    // No need to call refresh here since the filter subscription calls refresh
  }


  filterCards(stageId: number) {
    return this.cards.filter((c) => {
      // Only include deals in the specified stage
      return c.stage_id.id === stageId;
    });
  }


  toggleRecents() {
    this.justRecents = !this.justRecents
    this.refresh()
  }
  getAreaBadgeClass(area: string): string {
    switch (area) {
      case 'Tetti':
        return 'bg-success text-white';
      case 'Case':
        return 'bg-danger text-white';
      case 'Facciate e Decking':
        return 'bg-secondary text-white';
      case 'Aziendale':
        return 'bg-muted text-dark'; // 'bg-muted' is typically light gray; using 'bg-light' for clarity
      default:
        return 'bg-warning text-dark';
    }
  }
}
