import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService } from '../../shared/services/rest-api.service';
import {  first} from 'rxjs/operators';
import { Contact } from '../../models/contact.model';
import { firstValueFrom,} from 'rxjs';
import { SaleOrder } from '../../models/sale-order.model';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ContactPickerComponent } from '../contact-picker/contact-picker.component';
import { parseXMLVAT } from '../contact-search.component';
import { Lead } from 'src/app/models/crm.lead.model';
import { AccountMove } from 'src/app/models/account-move.model';
import { ODOO_IDS } from 'src/app/models/deal';



@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html'
})
export class ContactDetailComponent implements OnInit {
  activeTab: string = 'recapiti';
  id: number;
  contact: Contact;
  sales: SaleOrder[];
  leads: Lead[];
  purchases: any[];
  loading = false;
  isViewingChild: boolean;
  @ViewChild('picker') picker: ContactPickerComponent;

  constructor(
    private route: ActivatedRoute,
    public restapi: RestapiService,
    private router: Router,
    private odooEm: OdooEntityManager,
  ) {
  }

  ngOnInit() {
    
    this.route.params.subscribe(async params => {
      this.loading = true
      this.id = params.id
      await this.resolveContact()
      this.loading = false
    })
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    if (tab == 'commesse' && !this.leads) {
      this.fetchLeads();
    } else if (tab == 'vendite' && !this.sales) {
      this.fetchSales();
    }
    console.log("tab", tab)
  }




  async resolveContact() {
    this.isViewingChild = false
    this.loading = true;
    // odooEM.search non trova gli archiviati di default con il solo ID, quindi ...
    var cs = new Contact()
      cs = (await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [
        ['id', '=', this.id]
      ])))[0]
    // }
    this.contact = cs
    console.log("xxcon ", cs, this.contact)
    this.loading = false;
  } 

  newPerson() {
    this.picker.newLink()
    return
  }

  async fetchLeads() {
    this.loading = true;
    try {
      this.leads = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [
        ['partner_id.id', '=', this.id]
      ]));
      this.leads.sort((a, b) => {
        if (a.stage_id.name !== b.stage_id.name) {
          return a.stage_id.name.localeCompare(b.stage_id.name);
        }
        return a.tracking_code.localeCompare(b.tracking_code);
      });
      this.calculateTotalContractAmount(this.leads)
      console.log("leads", this.leads)
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      this.loading = false;
    }
  }

 async calculateTotalContractAmount(leads: Lead[]) {
    let tracks = this.leads.map((c) => c.tracking_code);

    let Invoices = await firstValueFrom(this.odooEm.search<AccountMove>(new AccountMove(), [['invoice_origin', 'in', tracks] , ['journal_id', '=', ODOO_IDS.contratti_id]]));
    
    console.log('Invoices:', Invoices);
    // assign the total amount to the lead
    for (let l of this.leads) {
      let total = 0;
      for (let i of Invoices) {
        if (l.tracking_code == i.invoice_origin) {
          total += i.amount_untaxed;
        }
      }
      l._totalContractAmount = total;
    }
  }

  async fetchSales() {
    console.log("fetchSales")
    this.loading = true;
    try {
      this.sales = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [
        ['partner_id.id', '=', this.id],
        ['opportunity_id', '=', false]
      ]));

      // Compute the delivery state for each related sale
       this.odooEm.call2('sale.order', 'ga_compute_order_state', [ null, this.sales.map(r => r.id)]).then(x => {
      // ga_compute_order_state returns an ordered array of strings in the result key
      this.sales.forEach((r, i) => {
        r._delivery_state = x.result[i]
      })  
    })

    } catch (error) {
      console.error('Error fetching sales:', error);
    } finally {
      this.loading = false;
      console.log("sales", this.sales)
    }
  }

  confirm() {
    // this.changeState('quotation');
  }

  async restore() {

    if (confirm("Vuoi annullare l'archivazione?")) {
      await firstValueFrom(this.odooEm.call(
        new Contact(),
        "action_unarchive",
        Number(this.id),
        null
      ))
      this.contact.active = true
    }
  }

  async obsolete() {
    await firstValueFrom(this.odooEm.call(
      new Contact(),
      "action_archive",
      [Number(this.id)],
      null,
    ))
    this.contact.active = false
  }

  async onDelete() {
    if (!confirm('Sei sicuro di voler archiviare il contatto ?'))
      return
    this.loading = true
    await this.obsolete()
    this.loading = false
  }

  async update(prop: string) {
    this.loading = true
    var p = {}
    p[prop] = this.contact[prop]

    await this.odooEm.update(this.contact, p).pipe(first()).toPromise()

    console.log("HERE ", p)
    this.loading = false
  }



  onVAT(vat) {
    this.loading = true
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', 'checkvat/taxation_customs/vies/services/checkVatService', true);

    // build SOAP request
    var sr =
      "<s11:Envelope xmlns:s11='http://schemas.xmlsoap.org/soap/envelope/'>" +
      "<s11:Body>" +
      "<tns1:checkVat xmlns:tns1='urn:ec.europa.eu:taxud:vies:services:checkVat:types'>" +
      "<tns1:countryCode>IT</tns1:countryCode>" +
      "<tns1:vatNumber>" + vat + "</tns1:vatNumber>" +
      "</tns1:checkVat>" +
      "</s11:Body>" +
      "</s11:Envelope>"

    xmlhttp.onreadystatechange = () => {
      this.loading = false
      if (xmlhttp.readyState == 4) {
        if (xmlhttp.status == 200) {
          var r = parseXMLVAT(xmlhttp.responseText)

            // this.contact.name = r.name
            // this.contact.city = r.city
            // this.contact.street = r.street
            // this.contact.zip = r.postalcode

            this.bulkUpdate(r)
        }
      }
    }
    // Send the POST request
    xmlhttp.setRequestHeader('Content-Type', 'text/xml');
    xmlhttp.send(sr);
  }

  async bulkUpdate(fields) {
    this.loading = true
    await this.odooEm.update<Contact>(this.contact, fields).pipe(first()).toPromise()
    await this.resolveContact()
    this.route
    this.loading = false
  }

  redirectDeal(id: number) {
    // Construct the URL
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/leads', id])
    );

    // Open the URL in a new window/tab
    window.open(url, '_blank');
  }

  redirectSale(id: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/immediate-sale/s', id])
    );
    window.open(url, '_blank');
  }
}
enum OrderState {
  ASSIGNED = 'assigned',
  DRAFT = 'preventivo',
  PRODUCTION = 'in_produzione',
  PLANNING = 'in_progettazione',
  SALE = 'sale'
}

