@if (production) {
  <app-navbar [loading]="loading" backroute="../..">
    <a class="navbar-brand">
      <span>{{production?.name}}</span>
    </a>
    <div class="ms-auto d-flex align-items-center">
      <span [ngClass]="getStateBadge().class" class="me-2">
        {{getStateBadge().title}}
      </span>
      <div class="dropdown" style="z-index:2000;">
        <button class="btn btn-link text-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-bars"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li *ngIf="production.state === 'draft'" class="dropdown-item">
            <button class="btn btn-primary text-white" type="button" (click)="confirmProduction(production)">
              Conferma
            </button>
          </li>
          <li class="dropdown-item" *ngIf="production.state !== 'draft'">
            <button class="btn btn-primary text-white" type="button" (click)="deleteProduction(production)">
              Elimina
            </button>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li>
            <a class="dropdown-item" target="_blank"
               href="//o3.galimberti.eu/web#id={{production.id}}&cids=1&menu_id=355&action=552&model=mrp.production&view_type=form">
              Apri in Odoo
            </a>
          </li>
        </ul>
      </div>
    </div>
  </app-navbar>

  <div class="table-container  overflow-x-auto overflow-y-auto">
    <table id="production" class="table table-bordered tableFixHead table-hover mb-0">
      <thead class="sticky-top">
        <tr valign="middle">
          <th class="bg-light ps-3"></th>
          <th class="bg-light ps-3">Prodotto</th>
          <th class="bg-light text-end">UdM</th>
          <th class="bg-light text-end">Qtà</th>
          <th class="bg-light text-end">Desc</th>
          <th class="bg-light text-end">Disp</th>
          <th class="bg-light text-end">Larg</th>
          <th class="bg-light text-end">Spes</th>
          <th class="bg-light text-end">Lung</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let move of production.move_raw_ids?.values" class="mw-100" id="tr{{move.id}}">
          <td class="align-middle">
            <i *ngIf="production.state != 'done'" class="fa fa-trash ms-3" 
            [disabled] = "loading || isMovePrepared(move)"
            (click)="deleteMove(move)"></i>
          </td>
          <td class="text-start align-middle" style="text-wrap:balance;">
            {{move.product_id.name.replaceAll(', -','')}}
            <a class="text-wrap btn btn-link ms-1" title="apri in Odoo"
               href="https://o3.galimberti.eu/web?debug=1#id={{move.product_id.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form"
               target="_blank">
              <i class="text-wrap fa-solid fa-magnifying-glass my-0 py-1"></i>
            </a>
          </td>
          <td class="text-nowrap editable align-middle">
            <div class="d-flex align-items-center justify-content-end">
              <input-number 
                [disabled]="loading || isMovePrepared(move)" 
                class="me-2" 
                [ngModel]="move.product_uom_qty | number : '1.0-5':'en-EN'"
                (ngModelChange)="updateFromQuantity(move, $event)">
              </input-number>
              <span>{{move.product_id.value?.uom_id?.name}}</span>
            </div>
          </td>
          <td class="editable align-middle">
            <div class="d-flex align-items-center justify-content-end">
              <input-number 
                [disabled]="loading || isMovePrepared(move)" 
                class="me-2" 
                [hidden]="!move.product_packaging_id?.id"
                [ngModel]="move._product_packaging_qty | number : '1.0-5':'en-EN'"
                (ngModelChange)="UpdateFromPackageQuantity(move, $event)">
              </input-number>
              <div class="dropdown d-inline-block">
                <button class="btn w-100 p-0 btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" (mousedown)="updateSelectablePackaging(move)">
                  {{move.product_packaging_id.name}}
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let r of selectablePackagings">
                    <button class="dropdown-item" (click)="updateMovePackage(move, r)">{{r.name}}</button>
                  </li>
                </ul>
              </div>
            </div>
          </td>
          <td class="align-middle fst-normal text-nowrap">
            <div class="d-flex align-items-center justify-content-start">
              <pre class="mb-0">{{getDescriptive(move)}}</pre>
            </div>
          </td>
          <td class="align-middle text-center">
            <div>
              <ng-container *ngFor="let i of getIconsForLine(move)">
                <i class="me-2" [ngClass]="i"></i>
              </ng-container>
            </div>
          </td>
          <td class="text-right align-middle">
            {{getVariantAttribute(move, 'Larghezza')?.name}}
          </td>
          <td class="text-right align-middle">
            {{getVariantAttribute(move, 'Altezza')?.name}}
          </td>
          <td class="text-right align-middle">
            {{getVariantAttribute(move, 'Lunghezza')?.name}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}