import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ContactSearchComponent } from './contact/contact-search.component';
import { ContactDetailComponent } from './contact/contact-detail/contact-detail.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TreeModule } from 'angular-tree-component';
import { LoaderComponent } from './loader/loader.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoadSearchComponent } from './purchase-picking/load-search.component';
// import { LoadDetailComponent } from './purchase-picking/load-detail/load-detail.component';
// import { LoadPickingComponent } from './purchase-picking/load-package-detail/load-package-detail.component';
// import { PickingDetailComponent } from './sale-picking/picking-detail/picking-detail.component';

import { Date4Humans, PickingSearchComponent, SortByActivityDate } from './picking-search/picking-search.component';
import { DealsDashboardComponent } from './deals/deals-dashboard/deals-dashboard.component';
import { DealDetailComponent, SortByPipe } from './deals/deal-detail/deal-detail.component';
import { PackageDetailComponent } from './sale-picking/package-detail/package-detail.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SearchComponent } from './search/search.component';
import { BarcodeReaderDynamsoftComponent } from './barcode-reader-dynamsoft/barcode-reader-dynamsoft.component';
import {AUTH_SERVICE, AuthModule, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI} from 'ngx-auth';
import {AuthenticationService} from './shared/services/authentication.service';
import {TokenStorage} from './shared/services/token-storage.service';
import { CallbackComponent } from './callback/callback.component';
import { InputwrapperDirective } from './shared/directives/inputwrapper.directive';
import { AutoLengthDirective } from './shared/directives/auto-length.directive';
import { OrderNavComponent } from './shared/order-nav/order-nav.component';

import { SalePickingModalComponent } from './sale-picking/picking-modal/picking-modal.component';
import { UserInfoComponent } from './shared/user-info/user-info/user-info.component';
import { SearchPackageComponent } from './search/search-package/search-package.component';
import { SearchResultsComponent } from './search/search-results/search-results.component';
import { ResizableModule } from 'angular-resizable-element';


import {
  NgGapiClientConfig,
} from 'ng-gapi';
import { TrelloInsertComponent } from './deals/trello-insert/trello-insert.component';
import { GooglePlaceModule } from '@yuvarajv/ngx-google-places-autocomplete';
import { InputNumberComponent } from './shared/input-number/input-number.component';
import { ConnectToComponent } from './connect-to/connect-to.component';
import { GaliNumberDirective } from './shared/directives/gali-number.directive';
import { SaleOrderDetailComponent } from './components/sale-order-detail/sale-order-detail.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order-detail/purchase-order-detail.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { OrderHeaderComponent } from './components/order-header/order-header.component';
import { PurchaseOrderTableComponent } from './components/purchase-order-table/purchase-order-table.component';
import { OrderInventoryComponent, SortAsNumber } from './components/order-inventory/order-inventory.component';
import { OutsideClickDirective } from './shared/directives/outside-click.directive';
// import { InfoSideOrderComponent } from './components/info-side-order/info-side-order.component';
import { ToastsContainerComponent } from './shared/components/toasts-container/toasts-container.component';
// import { AddEntityDropdownComponent } from './components/add-entity-dropdown/add-entity-dropdown.component';
import { ContactPickerComponent } from './contact/contact-picker/contact-picker.component';
// import { PickingMoveEditorComponent } from './picking/picking-move-editor/picking-move-editor.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContactNewComponent } from './contact/contact-new/contact-new.component';
import { TrelloInsertPosComponent } from './deals/trello-insert-pos/trello-insert-pos.component';
import { GmailImportComponent } from './gmail/gmail-import/gmail-import.component';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { ProductionTableComponent } from './components/production-table/production-table.component';
import { InputDoubleUmComponent } from './components/input-double-um/input-double-um.component';
import { DealCalendarListComponent } from './deals/deal-calendar-list/deal-calendar-list.component';
import { BudgetComponent } from './deals/budget/budget.component';
// import { ProductionHeadingComponent } from './production/production-heading/production-heading.component';
import { PickingEditorPage } from './picking/picking-editor-page/picking-editor-page.component';
import { SaleOrderEditorComponent } from './sale-order/sale-order-editor/sale-order-editor.component';
import { ScannerComponent } from './barcode-scanner/scanner/scanner.component';
import { PickingPackageLocationPickerComponent } from './picking/picking-package-location-picker/picking-package-location-picker.component';
import { SaleOrderTableComponent } from './components/sale-order-table/sale-order-table.component';
import { PickingModalComponent } from './purchase-picking/picking-modal/picking-modal.component';
import { InfoSideOrderComponent } from './components/info-side-order/info-side-order.component';
import { BadgeKioskComponent } from './badge-kiosk/badge-kiosk.component';
import { TimeOffComponent } from './time-off/time-off.component';
import { ForkliftPageComponent } from './forklift/forklift-page/forklift-page.component';
import { StatusComponent } from './status/status.component';
import { KeyboardScannerComponent } from './shared/components/keyboard-scanner/keyboard-scanner.component';
import { PurchasePrintComponent } from './purchase-print/purchase-print.component';
import { SalePrintComponent } from './sale-print/sale-print.component';
// import { PackPageComponent } from './picking/pack-page/pack-page.component';
import { BadgeReadComponent } from './badge-kiosk/badge-read/badge-read.component';
import { BadgeWriteComponent } from './badge-kiosk/badge-write/badge-write.component';
import { BadgeAdminComponent } from './badge-kiosk/badge-admin/badge-admin.component';
import { ExportComponent } from './export/export.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { ActionPlotComponent } from './flow/action-plot/action-plot.component';
import { ActionSearchComponent } from './flow/action-search/action-search.component';
import { DashboardComponent } from './flow/dashboard.component';
import { FlowDashboardSidebarComponent } from './flow/dashboard-sidebar/dashboard-sidebar.component';
// import { AuthInterceptor } from 'ngx-auth/auth.interceptor';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FlowSidebarComponent } from './flow/flow-sidebar/flow-sidebar.component';
import { ActionCardComponent } from './flow/action-card/action-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MindmapComponent } from './flow/mindmap/mindmap.component';
import { InspectorComponent } from './flow/inspector/inspector.component';
import { ScannerZxingComponent } from './barcode-scanner/scanner-zxing/scanner-zxing.component';
import { MilvusComponent } from './milvus/milvus.component';
import { VirtualKeypadComponent } from './shared/components/virtual-keypad/virtual-keypad.component';
import { SaleSearchComponent } from './sale-search/sale-search.component';
import { CatalogueSearchComponent } from './catalogue/search/search.component';
import { ContactPicker2Component } from './contact/contact-picker2/contact-picker2.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { FlashSaleOrderComponent } from './flash-sale-order/flash-sale-order.component';
import { FlashSaleOrderEditorComponent } from './flash-sale-order-editor/flash-sale-order-editor.component';
import { OrderInventoryMiniComponent } from './components/order-inventory-mini/order-inventory-mini.component';
import { DeliveryNoteSearchComponent } from './delivery-note-search/delivery-note-search.component';
import { DeliveryNoteViewerComponent } from './delivery-note-viewer/delivery-note-viewer.component';
import { CostCheckEmbeddedComponent } from './cost-check-embedded/cost-check-embedded.component';
import { MessageWidgetComponent } from './message-widget/message-widget.component';
import { RemoveHtmlPipe } from './message-widget/remove-html.pipe';
import { PickingInComponent } from './picking/picking-in/picking-in.component';
import { PackageChooserComponent } from './picking/package-chooser/package-chooser.component';
import { PickingInternalComponent, SortByPipe2 } from './picking/picking-internal/picking-internal.component';
import { SaleLineInfoComponent } from './sale-line-info/sale-line-info.component';
import { TimelineComponent } from './timeline/timeline.component';
import { OddmentsComponent } from './oddments/oddments.component';
import { ProductQuantityEditorComponent } from './product-quantity-editor/product-quantity-editor.component';
import { PickingDiyComponent } from './picking/picking-diy/picking-diy.component';
import { ActivitySchedulerComponent } from './deals/activity-scheduler/activity-scheduler.component';
import { ProductionEditorComponent } from './production-editor/production-editor.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { ProductionsSearchComponent } from './productions/productions-search/productions-search.component';
import { DeliveryNoteViewer2Component } from './delivery-note-viewer2/delivery-note-viewer2.component';
import { BillingComponent } from './billing/billing.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ModifyAttributesComponent } from './modify/modify-attributes/modify-attributes.component';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';

const gapiClientConfig: NgGapiClientConfig = {
  client_id: '470272109138-p98fm5g5qs8i21o01rir4tbteann1a1i.apps.googleusercontent.com',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
  scope: [
      'https://www.googleapis.com/auth/drive'
  ].join(' ')
};


registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ContactSearchComponent,
    ContactDetailComponent,
    NotFoundComponent,
    LoaderComponent,
    LoadSearchComponent,
    PickingSearchComponent,
    DealsDashboardComponent,
    DealDetailComponent,
    PackageDetailComponent,
    SearchComponent,
    BarcodeReaderDynamsoftComponent,
    CallbackComponent,
    InputwrapperDirective,
    AutoLengthDirective,
    OrderNavComponent,
    SaleOrderTableComponent,
    OutsideClickDirective,
    PurchaseOrderTableComponent,
    UserInfoComponent,
    SearchPackageComponent,
    SearchResultsComponent,
    InputNumberComponent,
    TrelloInsertComponent,
    GaliNumberDirective,
    SaleOrderDetailComponent,
    SalePickingModalComponent,
    PurchaseOrderDetailComponent,
    OrderDetailComponent,
    OrderHeaderComponent,
    PurchaseOrderTableComponent,
    OrderInventoryComponent,
    InfoSideOrderComponent,
    PickingModalComponent,
    TrelloInsertComponent,
    InputNumberComponent,
    ConnectToComponent,
    ToastsContainerComponent,
    ContactPickerComponent,
    SortByPipe,
    SortByPipe2,
    SortByActivityDate,
    SortAsNumber,
    NavbarComponent,
    ContactNewComponent,
    TrelloInsertPosComponent,
    GmailImportComponent,
    ProductionTableComponent,
    InputDoubleUmComponent,
    DealCalendarListComponent,
    BudgetComponent,
    PickingEditorPage,
    SaleOrderEditorComponent,
    ScannerComponent,
    PickingPackageLocationPickerComponent,
    BadgeKioskComponent,
    TimeOffComponent,
    ForkliftPageComponent,
    StatusComponent,
    KeyboardScannerComponent,
    PurchasePrintComponent,
    SalePrintComponent,
    ModifyAttributesComponent,
    // PackPageComponent,
    Date4Humans,
    BadgeReadComponent,
    BadgeWriteComponent,
    BadgeAdminComponent,
    ExportComponent,
    ExplorerComponent,
    ActionPlotComponent,
    ActionSearchComponent,
    FlowSidebarComponent,
    DashboardComponent,
    FlowDashboardSidebarComponent,
    ActionCardComponent,
    MindmapComponent,
    InspectorComponent,
    ScannerZxingComponent,
    MilvusComponent,
    VirtualKeypadComponent,
    SaleSearchComponent,
    CatalogueSearchComponent,
    PurchaseOrderComponent,
    DeliveryNoteViewer2Component,
    InvoiceSearchComponent,
    ContactPicker2Component,
         FlashSaleOrderComponent,
         FlashSaleOrderEditorComponent,
         OrderInventoryMiniComponent,
         DeliveryNoteSearchComponent,
         DeliveryNoteViewerComponent,
         CostCheckEmbeddedComponent,
         BillingComponent,
         ContractsComponent,
         MessageWidgetComponent,
         RemoveHtmlPipe,
         PickingInComponent,
         PackageChooserComponent,
         PickingInternalComponent,
         SaleLineInfoComponent,
         TimelineComponent,
         OddmentsComponent,
         ProductQuantityEditorComponent,
         PickingDiyComponent,
         ActivitySchedulerComponent,
         ProductionEditorComponent,
         CatalogueComponent,
         ProductionsSearchComponent,
         
  ],
  imports: [
    BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot([]),
    // TreeModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ResizableModule,
    DragDropModule,
    

    
  ],

  providers: [

    //   {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: AuthInterceptor,
    //     multi: true
    // },
    {
      provide: LOCALE_ID, useValue: 'it-IT',
    },
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
    TokenStorage,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // library.add( fas , far, fab);
  }
}
