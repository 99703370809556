import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { Product } from './product.model';
import { OdooModel } from './odoo-model.model';
import { PurchaseOrder } from './order';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { AccountTax } from './account-tax.model';
import { AccountMove } from './account-move.model';

export class AccountMoveLine extends OdooModel implements OdooSerializableInterface<AccountMoveLine> {

  public ODOO_MODEL = 'account.move.line';
  
  id: number = 0;
  purchase_line_id: OdooRelationship<PurchaseOrder> = new OdooRelationship<PurchaseOrder>();
  product_id: OdooRelationship<Product> = new OdooRelationship<Product>();
  price_unit: number = 0; // Unit price
  price_subtotal: number = 0; // Subtotal price without tax
  _tax_amount: number = 0; // Tax amount
  price_total: number = 0; // Total price which includes tax
  quantity: number = 0;
  account_id: number = 0;
  journal_id: number = 0;
  tax_ids: OdooMultiRelationship<AccountTax> = new OdooMultiRelationship<AccountTax>(AccountTax);
  move_id: OdooRelationship<AccountMove> = new OdooRelationship();
  discount: number = 0;
  account_type: string = ""; 
  name: string = "";
  


  create(): AccountMoveLine {
    return new AccountMoveLine();
  }
}


