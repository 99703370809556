<div class="d-flex flex-column h-100">
  <app-navbar [loading]="loading" backroute="..">
    <a class="navbar-brand me-auto" style="text-overflow: ellipsis;overflow: hidden; max-width: calc(100%);">
      Nuova vendita
    </a>
    <ng-content>
    </ng-content>
  </app-navbar>

  <div class="flex-fill">

    <div class="p-3">
      <form>
        <!-- Contact Picker Section -->
        <div class="mb-4">
          <label class=" text-dark  mb-2">Seleziona cliente e indirizzo</label>
          <app-contact-picker2 
            [showAddresses]="true"
            class="embedded bg-white" 
            [mode]="'embedded'" 
            (onSelect)="onContact($event)">
          </app-contact-picker2>
        </div>

        <!-- Selected Contact Preview -->
        <label *ngIf="newSalePartner" class=" text-dark  mb-2">Cliente e indirizzo selezionati:</label>
        <div *ngIf="newSalePartner" class="bg-light rounded p-3 mb-4">
          <div class="d-flex align-items-start justify-content-between">
            <div>
              <!-- Parent Company + Contact -->
              <div *ngIf="newSalePartner.parent_id.id" class="mb-1">
                <strong>{{newSalePartner.parent_id.name?.toUpperCase()}}</strong>
                <div>{{newSalePartner.name?.toUpperCase()}}</div>
              </div>
              
              <!-- Solo Contact -->
              <strong *ngIf="!newSalePartner.parent_id.id">
                {{newSalePartner.name?.toUpperCase()}}
              </strong>

              <!-- Address -->
              <div class="mt-2 text-muted small" *ngIf="newSalePartner.street || newSalePartner.city">
                <div *ngIf="newSalePartner.street">{{newSalePartner.street}}</div>
                <div *ngIf="newSalePartner.city">{{newSalePartner.city}}</div>
              </div>
            </div>
            
            <button class="btn btn-sm btn-link text-muted" (click)="newSalePartner = null">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>

        <!-- Create Button -->
        <button type="submit" 
                [disabled]="!newSalePartner" 
                class="btn btn-lg btn-primary w-100" 
                (click)="newSale()">
          Crea vendita
        </button>
      </form>
    </div>
  </div>
</div>