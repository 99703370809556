import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { firstValueFrom } from "rxjs";
import { OdooEntityManager } from "../../shared/services/odoo-entity-manager.service";
import { ODOO_IDS } from "src/app/models/deal";
import { Product } from "../../models/product.model";
import { StockQuant } from "src/app/models/stock-quant";
import { MrpProduction } from "src/app/models/mrp-production";
import { StockMove } from "src/app/models/stock-move";
import { StockMoveLine } from "src/app/models/stock-move-line";
import { StockPicking } from "src/app/models/stock-picking";
import { ProductTemplate } from "src/app/models/product.template.model";
import { ProductAttributeValue } from "src/app/models/product.attribute.value";
import { ProductTemplateAttributeValue } from "../../models/product.template.attribute.value.model";
import { SaleOrder } from "src/app/models/sale-order.model";

interface AttributeTableItem {
  attributeId: number;
  attributeName: string;
  currentValueId: number;
  currentValue: string;
  newValueId: number | null;
  newValue: string | null;
  possibleValues: { id: number; name: string }[];
}

@Component({
  selector: "app-modify-attributes",
  template: `
    <app-navbar [loading]="loading" backroute="..">
      <a class="navbar-brand"> Modifica Attributi </a>
    </app-navbar>

    <div class="h-100 bg-white">
      <div *ngIf="!showAttributes && quant">
        <app-product-quantity-editor
          [product]="product"
          [quantity]="quant.quantity"
          (onSave)="onQuantitySelected($event)"
          (onCancel)="onCancel()"
        >
        </app-product-quantity-editor>
      </div>

      <div *ngIf="showAttributes">
        <div class="d-flex align-items-center ps-2 py-1 text-primary">
          <h3>{{ product.product_tmpl_id.name }}</h3>
        </div>

        <div class="table-responsive">
          <table
            class="table table-bordered mw-100 text-wrap items-aling-center"
          >
            <thead>
              <tr class="items-aling-center text-center">
                <th>Attributo</th>
                <th>Valore corrente</th>
                <th *ngIf="!isReserved">Nuovo valore</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of attributeTableItems">
                <td class="text-wrap align-middle">
                  <strong>{{ item.attributeName }}</strong>
                </td>
                <td class="text-wrap align-middle">
                  {{ item.currentValue }}
                </td>
                <td *ngIf="!isReserved" class="text-wrap align-middle">
                  <ng-container
                    *ngIf="
                      isNumericAttribute(item.attributeId);
                      else selectInput
                    "
                  >
                    <input
                      type="number"
                      class="form-control"
                      [name]="item.attributeName"
                      [(ngModel)]="item.newValue"
                      (change)="onAttributeChange(item)"
                      [disabled]="!isModifiableAttribute(item.attributeId)"
                    />
                  </ng-container>
                  <ng-template #selectInput>
                    <select
                      class="form-select"
                      [name]="item.attributeName"
                      [(ngModel)]="item.newValueId"
                      (change)="onAttributeChange(item)"
                      [disabled]="!isModifiableAttribute(item.attributeId)"
                    >
                      <option
                        *ngFor="let value of item.possibleValues"
                        [ngValue]="value.id"
                      >
                        {{ value.name }}
                      </option>
                    </select>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

<!-- Reservation warning and quantity info -->
    <div *ngIf="isReserved" class="alert alert-danger mx-2">
      <h6 class="mb-0">
        Attenzione, questo prodotto è riservato. Per modificare, annullare la prenotazione
      </h6>
      <div class="d-flex justify-content-between align-items-center mt-2">
        <div class="text-dark">
          <strong>Disponibili:</strong>
          {{ quant.available_quantity | number : "1.0-2" }}
          {{ product.uom_id.name }}
        </div>
        <div class="text-dark">
          <strong>Selezionati:</strong>
          {{ selectedQuantity | number : "1.0-2" }}
          {{ product.uom_id.name }}
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="d-flex justify-content-end mt-3 pe-3">
      <button
        *ngIf="!isReserved"
        class="btn btn-primary text-white"
        (click)="createProduction()"
        [disabled]="!hasChanges"
      >
        Modifica
      </button>
      <button
        *ngIf="isReserved"
        class="btn btn-primary text-white"
        (click)="getReservations()"
      >
        Vedi prenotazioni
      </button>
    </div>

    <!-- Reservations list -->
    <div *ngIf="reservations.length > 0" class="table-responsive mt-3 bg-white">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Ordine</th>
            <th>Cliente</th>
            <th>Resp</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let reservation of reservations">
            <td>{{ reservation.name }}</td>
            <td>{{ reservation.partner_id.name }}</td>
            <td>{{ reservation.user_id.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  `,
})
export class ModifyAttributesComponent implements OnInit {
  @Input() quant: StockQuant;
  //@Output() onComplete = new EventEmitter<void>();

  @Output() onDone = new EventEmitter<void>();
  product: Product;
  productTemplate: ProductTemplate;
  showAttributes = false;
  selectedQuantity: number;
  hasChanges = false;
  attributeTableItems: AttributeTableItem[] = [];
  newVariant: Product;
  productionCreated: MrpProduction;
  templateAttributeLines: any[] = [];
  variantAttributes: ProductTemplateAttributeValue[] = [];
  loading: boolean = false;
  isReserved: boolean;
  reservations: SaleOrder[] = [];

  constructor(
    private odooEm: OdooEntityManager,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    //fetch product, quant and initialize attributes
    this.loading = true;
    try {
      if (this.quant.product_id.value) {
        this.product = this.quant.product_id.value;
        this.selectedQuantity = 0; //initialize quantity so that checking if it is reserved works
        console.log("xxxProduct", this.product);
        console.log("xxxQuant", this.quant);
        console.log(
          "xxx quantity reserver of this quant",
          this.quant.reserved_quantity
        );
        console.log(
          "xxx free quantity of this quant",
          this.quant.available_quantity
        );
        console.log("xxx selected quantity", this.selectedQuantity);
        console.log(
          "xxx is reserved",
          this.quant.reserved_quantity > this.selectedQuantity
        );
        await this.initializeAttributes();
        this.populateAttributeTableItems();
      }

      //here quantity is selected through html
    } catch (error) {
      console.error("Error initializing attributes:", error);
      // Handle error in UI
    } finally {
      this.loading = false;
    }
  }

  onQuantitySelected(quantity: number) {
    this.selectedQuantity = quantity;
    this.showAttributes = true;
    //round quantities to 5 decimals
    this.isReserved = this.quant.available_quantity.toFixed(5)  < this.selectedQuantity.toFixed(5);
  }

  onCancel() {  
    this.onDone.emit();
  }

  async initializeAttributes() {
    //Resolve product
    await firstValueFrom(this.odooEm.resolve(this.product.attribute_line_ids));
    await firstValueFrom(
      this.odooEm.resolve(this.product.product_template_attribute_value_ids)
    );
    await firstValueFrom(
      this.odooEm.resolve(this.product.product_template_variant_value_ids)
    );
    this.variantAttributes =
      this.product.product_template_variant_value_ids.values || [];

    //resolve product template (we need it to resolve attribute values to check variants later)
    await firstValueFrom(
      this.odooEm.resolveSingle(
        new ProductTemplate(),
        this.product.product_tmpl_id
      )
    );
    this.productTemplate = this.product.product_tmpl_id.value;
    if (!this.productTemplate) throw new Error("Product template not found");

    // Resolve product attributes
    await firstValueFrom(
      this.odooEm.resolve(
        this.productTemplate.valid_product_template_attribute_line_ids
      )
    );
    this.templateAttributeLines =
      this.productTemplate.valid_product_template_attribute_line_ids.values ||
      [];
    await firstValueFrom(
      this.odooEm.resolveArray(
        new ProductAttributeValue(),
        this.templateAttributeLines,
        "value_ids"
      )
    );
  }

  async getReservations() {
    //search move lines with this quant as origin package and this product as product
    this.loading = true;

    let lines = await this.odooEm
      .search<StockMoveLine>(new StockMoveLine(), [
        ["package_id", "=", this.quant.package_id.id],
        ["product_id", "=", this.product.id],
      ])
      .toPromise();

    //sales = lines.origin;
    let origins = [];
    for (let line of lines) {
      if (!origins.includes(line.origin) && line.origin) {
        origins.push(line.origin);
      }
    }

    //now search sale orders with name = oringin
    let sales = await this.odooEm
      .search<SaleOrder>(new SaleOrder(), [["name", "in", origins]])
      .toPromise();

    this.reservations = sales;

    console.log("yyylines", lines);
    console.log("yyyoringins", origins);
    console.log("yyysales", sales);
    this.loading = false;
  }

  populateAttributeTableItems() {
    // Populate attribute table items for UI filtering out single-value attributes

    this.attributeTableItems = this.templateAttributeLines
      .filter((line) => line.value_ids.values.length > 1) // Filter out single-value attributes
      .filter((line) => !line.attribute_id.name.toLowerCase().includes("extra")) // Filter out extra attributes
      .map((line) => {
        const currentValue = this.variantAttributes.find(
          (va) => va.attribute_id.id === line.attribute_id.id
        ); //current values
        const possibleValues = line.value_ids.values.map((v) => ({
          id: v.id,
          name: v.name,
        })); // possible values to choose from
        const isNumeric = this.isNumericAttribute(line.attribute_id.id); // check if the attribute is numeric (in this case we do not use select input so we don't need to fetch possible values)
        const matchingCurrentValue = possibleValues.find(
          (v) => v.name === currentValue?.name
        ); // Find the matching value in possibleValues for both current and new values. at the start they are the same

        return {
          //populate the attribute table item
          attributeId: line.attribute_id.id,
          attributeName: line.attribute_id.name || "",
          currentValueId: isNumeric ? null : matchingCurrentValue?.id || 0,
          currentValue: currentValue?.name || "",
          newValueId: isNumeric ? null : matchingCurrentValue?.id || null,
          newValue: currentValue?.name || null,
          possibleValues: possibleValues,
        };
      });
  }

  isNumericAttribute(attributeId: number): boolean {
    // Check if attribute is numeric
    return ODOO_IDS.dimensionalAttributes.includes(attributeId);
  }

  isModifiableAttribute(attributeId: number): boolean {
    // Check if attribute is modifiable
    return !ODOO_IDS.nonModifiableAttributes.includes(attributeId);
  }

  onAttributeChange(item: AttributeTableItem) {
    // Handle attribute change in UI
    if (this.isNumericAttribute(item.attributeId)) {
      // if the attribute is numeric, update only the value
      if (item.newValue === item.currentValue) return;
      item.newValue = item.newValue.toString();
    } else {
      // if the attribute is not numeric, update both the name and the ID
      const selectedValue = item.possibleValues.find(
        (v) => v.id === item.newValueId
      );
      if (selectedValue && selectedValue.name === item.currentValue) return;
      if (selectedValue) {
        item.newValueId = selectedValue.id;
        item.newValue = selectedValue.name;
      }
    }
    // Check if any attribute has changes
    this.hasChanges = this.attributeTableItems.some(
      (tableItem) =>
        tableItem.currentValue !== tableItem.newValue ||
        (tableItem.currentValueId !== tableItem.newValueId &&
          !this.isNumericAttribute(tableItem.attributeId))
    );
    this.cdr.detectChanges();
  }

  // Main function to create production and restocking picking
  async createProduction() {
    if (!this.hasChanges) return;
    if (confirm("Sei sicuro di voler modificare l'articolo?")) {
      this.loading = true;

      try {
        await this.checkNewVariant(); // check if the new variant already exists, if not create it

        const productUomQty = this.calculateProductionQuantity(this.newVariant); // Calculate the product quantity to produce
        this.productionCreated = await this.createProductionOrder(
          productUomQty
        );

        await this.createAndLinkMove();

        await this.completeProduction(productUomQty);

        await this.createRestockingPicking();

        // Reset form
        this.showAttributes = false;
        this.quant = null;

        this.hasChanges = false;

        console.log(
          "Production order created, completed, and restocking picking created successfully"
        );
        // Handle success in UI

        alert("Modifica completata con successo");
      } catch (error) {
        console.error("Error in createProduction:", error);
        alert("Errore nella modifica, contattare l'assistenza");
      } finally {
        this.loading = false;
      }
      this.onDone.emit();
    }
  }

  async checkNewVariant() {
    // Check if new variant exists, create if not

    try {
      const ptaviIds =
        this.product.product_template_attribute_value_ids.values.map(
          (v) => v.id
        ); // get the current product template attribute value ids (basically the old variant attributes)

      for (const item of this.attributeTableItems) {
        if (item.newValue && item.newValue != item.currentValue) {
          const v = await this.getOrCreateAttributeValue(item); // for each attribute, get or create the attribute value
          const attributeLine = this.product.attribute_line_ids.values.find(
            (al) => al.display_name === item.attributeName
          ); // find the attribute line for the attribute
          await this.updateAttributeLine(attributeLine, v); // add the attribute value to the product template
          const ptav = await this.createProductTemplateAttributeValue(
            item,
            v,
            attributeLine
          ); // create the product template attribute value for the new variant
          const index =
            this.product.product_template_attribute_value_ids.values.findIndex(
              (v) => v.attribute_id.name.startsWith(item.attributeName)
            ); // find the index of the attribute in the old variant
          ptaviIds[index] = ptav[0].id; // update the ptavi ids with the new ptav id
        }
      }

      const result: any = await this.odooEm.odoorpcService.sendRequest(
        "/api/sale/create_product_variant",
        {
          product_template_attribute_value_ids: JSON.stringify(ptaviIds),
          product_template_id: this.product.product_tmpl_id.id,
        }
      ); // create the new variant. this function can fail in Odoo if variant already exists but we don't give a shit, the result is the new variant anyway
      await this.odooEm.run(666, result.result, "product.product"); // create pz pack for new variant
      const [newVariant] = await this.odooEm
        .search<Product>(new Product(), [["id", "=", result.result]])
        .toPromise();
      this.newVariant = newVariant; // set the new variant
    } finally {
    }
  }

  // Helper functions for checkNewVariant
  private async getOrCreateAttributeValue(item: AttributeTableItem) {
    // get each new value and if it doesn't exist, create it

    const [existingValue] = await this.odooEm
      .search<ProductAttributeValue>(new ProductAttributeValue(), [
        ["name", "=", item.newValue],
        ["attribute_id", "=", item.attributeId],
      ])
      .toPromise();
    if (existingValue) return existingValue;
    // if value does not exist, create it
    return await this.odooEm
      .create<ProductAttributeValue>(new ProductAttributeValue(), {
        name: item.newValue,
        attribute_id: item.attributeId,
      })
      .toPromise();
  }

  private async updateAttributeLine(
    attributeLine: any,
    attributeValue: ProductAttributeValue
  ) {
    // use the fetched value and add it to the product template
    await this.odooEm
      .resolve(this.productTemplate.attribute_line_ids)
      .toPromise();
    await this.odooEm
      .update(attributeLine, { value_ids: [[4, attributeValue.id]] })
      .toPromise();
  }

  private async createProductTemplateAttributeValue(
    item: AttributeTableItem,
    attributeValue: ProductAttributeValue,
    attributeLine: any
  ) {
    // create the product template attribute value for the new variant and fetch it (thi is basically the list of attributes for the new variant)
    await firstValueFrom(
      this.odooEm.create(new ProductTemplateAttributeValue(), {
        product_attribute_value_id: attributeValue.id,
        attribute_line_id: attributeLine.id,
        ptav_active: true,
      })
    );

    return await this.odooEm
      .search<ProductTemplateAttributeValue>(
        new ProductTemplateAttributeValue(),
        [
          ["attribute_id", "=", item.attributeId],
          ["product_attribute_value_id", "=", attributeValue.id],
          ["product_tmpl_id", "=", this.product.product_tmpl_id.id],
          ["ptav_active", "=", true],
        ]
      )
      .toPromise();
  }

  // from here on, helper functions to create the production and restocking picking

  private calculateProductionQuantity(newVariant: Product): number {
    // Calculate production quantity based on dimensional attributes
    const hasDimensionalAttributes = this.attributeTableItems.some((item) =>
      ODOO_IDS.dimensionalAttributes.includes(item.attributeId)
    );

    if (!hasDimensionalAttributes) return this.selectedQuantity; // if there are no dimensional attributes, return the selected quantity

    const oldDimensionalValue = this.calculateDimensionalValue("currentValue"); // calculate the old dimensional coefficient
    const newDimensionalValue = this.calculateDimensionalValue("newValue"); // calculate the new dimensional coefficient

    return this.selectedQuantity * (newDimensionalValue / oldDimensionalValue);
  }

  private calculateDimensionalValue(
    valueType: "currentValue" | "newValue"
  ): number {
    // multiply thee value of every dimensional attribute
    return this.attributeTableItems
      .filter((item) =>
        ODOO_IDS.dimensionalAttributes.includes(item.attributeId)
      )
      .reduce((acc, item) => acc * parseInt(item[valueType]), 1);
  }

  // Create production order
  private async createProductionOrder(
    productUomQty: number
  ): Promise<MrpProduction> {
    const productionData = {
      product_qty: productUomQty,
      product_id: this.newVariant.id,
      product_uom_id: this.newVariant.uom_id.id,
      location_src_id: this.quant.location_id.id,
      location_dest_id: this.quant.location_id.id,
      origin: "modifica attributi",
    };

    return await firstValueFrom(
      this.odooEm.create<MrpProduction>(new MrpProduction(), productionData)
    );
  }

  // Create and link move and move line
  private async createAndLinkMove() {
    try {
      const moveData = {
        product_id: this.product.id,
        product_uom_qty: this.selectedQuantity,
        location_id: this.quant.location_id.id,
        location_dest_id: this.quant.location_id.id,
        name: `${this.product.name} -> ${this.newVariant.name}`,
        raw_material_production_id: this.productionCreated.id,
        product_uom: this.product.uom_id.id,
      };

      const createdMove = await firstValueFrom(
        this.odooEm.create<StockMove>(new StockMove(), moveData)
      );

      const moveLineData = {
        product_id: this.product.id,
        location_id: this.quant.location_id.id,
        location_dest_id: this.quant.location_id.id,
        product_uom_id: this.product.uom_id.id,
        package_id: this.quant.package_id ? this.quant.package_id.id : false,
        qty_done: this.selectedQuantity,
        move_id: createdMove.id,
      };

      await firstValueFrom(
        this.odooEm.create<StockMoveLine>(new StockMoveLine(), moveLineData)
      );

      await firstValueFrom(
        this.odooEm.update<MrpProduction>(this.productionCreated, {
          move_raw_ids: [[4, createdMove.id]],
        })
      );
    } finally {
    }
  }

  // Complete production
  private async completeProduction(productUomQty: number) {
    await firstValueFrom(
      this.odooEm.update<MrpProduction>(this.productionCreated, {
        qty_producing: productUomQty,
      })
    );

    await this.odooEm.call2(
      new MrpProduction().ODOO_MODEL,
      "button_mark_done",
      [[this.productionCreated.id]]
    );
  }

  // Create restocking picking
  private async createRestockingPicking() {
    try {
      const pickingData = {
        picking_type_id: ODOO_IDS.picking_type_generic,
        origin: "Modifica attributi",
        location_id: this.quant.location_id.id,
        location_dest_id: this.quant.location_id.id,
      };

      const newPick = await firstValueFrom(
        this.odooEm.create<StockPicking>(new StockPicking(), pickingData)
      );

      const moveData = {
        name: this.newVariant.name,
        location_id: this.quant.location_id.id,
        location_dest_id: this.quant.location_id.id,
        product_id: this.newVariant.id,
        product_uom_qty: this.productionCreated.product_qty,
        picking_id: newPick.id,
        product_uom: this.newVariant.uom_id.id,
      };

      const createdMove = await firstValueFrom(
        this.odooEm.create<StockMove>(new StockMove(), moveData)
      );

      await firstValueFrom(
        this.odooEm.create<StockMoveLine>(new StockMoveLine(), {
          product_id: this.newVariant.id,
          picking_id: newPick.id,
          qty_done: this.productionCreated.product_qty,
          result_package_id: this.quant.package_id.id,
          location_id: this.quant.location_id.id,
          location_dest_id: this.quant.location_id.id,
          move_id: createdMove.id,
        })
      );

      // Validate the picking
      await this.odooEm.call2(
        new StockPicking().ODOO_MODEL,
        "button_validate",
        [[newPick.id]]
      );
      console.log("Restocking picking validated", newPick);
    } finally {
    }
  }
}
