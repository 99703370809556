<div class="d-flex flex-column h-100">
  <app-navbar [loading]="loading" backroute=".." >

    <a class="navbar-brand me-auto fs-6 text-wrap" style="text-overflow: ellipsis; overflow: hidden; max-width: calc(100%);">
      <div class="fs-6">
        
        {{order?.name}} - {{getPartnerName(order)}}
      </div>
      <div class="small text-light">{{order?.partner_id.value?.street}}</div>
    </a>

    <button class="btn btn-dark me-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-bars"></i>
    </button>

    <button class="btn btn-lg btn-primary text-white me-1" type="button" [disabled]="order?.state == 'sale'"
      (click)="scanWithCamera()" data-bs-toggle="offcanvas" [disabled]="order?.state == 'sale'"
      data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
      <i class="fa-solid fa-grid-2-plus"></i>
    </button>

    <button class="btn btn-lg btn-primary me-1 text-white" type="button" data-bs-toggle="offcanvas"
      [disabled]="order?.state == 'sale'" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
      <i class="fa-regular fa-magnifying-glass-plus"></i>
    </button>

    <div class="dropdown" *ngIf="order">

      <ul class="dropdown-menu dropdown-menu-end">

        <li><a class="dropdown-item" target="_blank"
            href="//o3.galimberti.eu/web#id={{order.id}}&cids=1&menu_id=178&action=296&model=sale.order&view_type=form">Apri
            in Odoo</a></li>
        <li><a class="dropdown-item" target="_blank" (click)="delete(order)">Elimina</a></li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li class="dropdown-item disabled">
          Creato il {{order.date_order | date}} <br> di {{order.user_id.name}}
        </li>
      </ul>
    </div>

  </app-navbar>

  <!-- scanner -->
  <div *ngIf="scanning" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 20000;">
    <app-scanner (onCode)="scanning = false; onCode($event)">
    </app-scanner>
  </div>

  <!-- Product Quantity Editor after adding product by clicking on it-->
  <app-product-quantity-editor *ngIf="showQuantityEditor" [product]="productForEditor" (onSave)="onQuantity($event)"
    (onCancel)="showQuantityEditor = false">
  </app-product-quantity-editor>

  <!-- Product Quantity Editor after updating quantity on line by clicking on it-->
  <app-product-quantity-editor *ngIf="showQuantityEditorOnLine" [product]="productForEditorOnLine" [quantity]="lineQuantity"
    (onSave)="onQuantityUpdate($event)" (onCancel)="showQuantityEditorOnLine = false">
  </app-product-quantity-editor>




  <div #offcanvas id="offcanvas" style="min-width: 70%;max-width: 95%;" class="offcanvas offcanvas-start bg-white"
    tabindex="-1">
    <div class="offcanvas-body p-0">
      <!-- Header with close button -->
      <div class="d-flex flex-nowrap align-items-center" id="menu">
        <h5 class="mb-0 ms-2">{{ package ? 'Contenuto del collo' : 'Risultato scansione' }}</h5>
        <button type="button" class="btn-close ms-auto me-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>

      <div id="inventory" class="rounded-0">
        <div class="p-0">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
          <th>Prodotto</th>
          <th class="text-end">Vendita</th>
          <th class="text-end">Disponibile</th>
              </tr>
            </thead>
            <tbody>
              <!-- Single product -->
              <ng-container *ngIf="product">
                <tr class="cursor-pointer text-nowrap" (click)="onAddProduct(product)" data-bs-dismiss="offcanvas">
                  <td class="py-3 align-middle">{{product.id}}</td>
            <td class="text-wrap align-middle">
              {{product.display_name.replaceAll(", -","")}}
            </td>
            <td class="align-middle text-center">
              {{product._lst_price ? (product._lst_price | number : '1.0-3':'it-IT') : '-'}}
             <br>
              €/{{product.uom_id.name}}
            </td>
            <td class="align-middle text-wrap text-end">
              {{getFree(product) || 0 | number : '1.0-3'}} {{product.uom_id.name}}
              <br>
              {{getDescriptive(product, getFree(product))}}
            </td>
                </tr>
              </ng-container>

              <!-- Package contents -->
              <ng-container *ngIf="package">
                <tr class="cursor-pointer text-nowrap" *ngFor="let q of package?.quant_ids?.values"
            (click)="onAddProduct(q.product_id.value)" data-bs-dismiss="offcanvas">
            <td class="py-3 align-middle">{{q.product_id.value.id}}</td>
            <td class="text-wrap align-middle">
              {{q.product_id.value.display_name.replaceAll(", -","")}}
            </td>
            <td class="align-middle text-center">
              {{q.product_id.value._lst_price ? (q.product_id.value._lst_price | number : '1.0-3':'it-IT') : '-'}}
             <br>
              €/{{q.product_id.value.uom_id.name}}
            </td>
            <td class="align-middle text-wrap text-end">
              {{q.available_quantity | number : '1.0-3' }} {{q.product_id.value.uom_id.name}}
              <br>
              {{getDescriptive(q.product_id.value, q.available_quantity)}}
            </td>
          </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <app-order-inventory-mini *ngIf="!scanning" #inventory class="w-100 pe-1" (addOrderLine)="onAddProduct($event)"
    [noDrag]="true" [noTemplate]="true"></app-order-inventory-mini>



  <div class="bg-light p-3 d-flex align-items-center justify-content-between">
    <!-- Pricelist select group -->
    <div class="input-group flex-nowrap" style="width: auto;">
      <select class="form-control" [ngModel]="order?.pricelist_id?.id" (ngModelChange)="updatePricelist(order, $event)"
        style="width: auto;" title="Listino prezzi">
        <option *ngFor="let pl of pricelists" [value]="pl.id">{{pl.name}}</option>
      </select>
      <span class="input-group-text bg-white">
        <i class="fa fa-tag text-muted"></i>
      </span>
    </div>

    <!-- Edit mode button -->
    <button class="btn btn-outline-primary d-flex align-items-center gap-2" *ngIf="order?.state === 'draft'"
      (click)="toggleEditMode()">
      <i class="fa" [class.fa-pen]="!isEditMode" [class.fa-check]="isEditMode"></i>
      <span>{{ isEditMode ? 'Fine modifica' : 'Modifica' }}</span>
    </button>
    <button class="btn btn-outline-primary d-flex align-items-center gap-2" *ngIf="order?.state === 'sale'"
      (click)= resetOrder(order)>
      <i class="fa-solid fa-arrow-rotate-left"></i>
      Annulla e modifica
    </button>
  </div>

<div class="table-responsive">
  <table class="table align-middle table-bordered overflow-hidden" >
    <thead>
      <tr>
        <th>Prodotto</th>
        <th class="text-center">Qta</th>
        <th class="text-nowrap text-end">€ / UdM</th>
        <th class="text-nowrap text-end">Tot</th>
        <th *ngIf="isEditMode && order?.state === 'draft'"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let l of order?.order_line.values">
        <tr>
          <td class="w-100 align-middle">
            {{l.product_id.value?.display_name.replaceAll(", -","")}}
          </td>
          <td class="py-3 text-nowrap text-center" [class.cursor-pointer]="isEditMode" [class.text-primary]="isEditMode"
            (click)="isEditMode && onUpdateQty(l)">
            {{l.product_uom_qty | number : '1.0-3':'it-IT'}} {{l.product_uom.name}}
            <br>
            {{getDescriptive(l.product_id.value, l.product_uom_qty)}}
          </td>
          <td class="text-nowrap text-end">
            <!-- Price input when in edit mode -->
            <ng-container *ngIf="isEditMode">
              <input type="number" class="form-control form-control-sm text-end" [ngModel]="l.price_unit"
                (keyup.enter)="updateLinePrice(l, $event.target.value); $event.target.blur()"
                [ngModelOptions]="{'updateOn': 'blur'}" (ngModelChange)="updateLinePrice(l, $event)">
            </ng-container>
            <!-- Regular price display when not in edit mode -->
            <ng-container *ngIf="!isEditMode">
              {{l.price_unit | currency : 'EUR'}}
            </ng-container>
          </td>
          <td class="text-nowrap text-end">
            {{l.price_subtotal | currency : 'EUR' }}
          </td>
          <td *ngIf="isEditMode && order?.state === 'draft'" class="text-center">
            <button class="btn btn-sm btn-primary" (click)="deleteLine(l)">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>


<div class="d-flex flex-column border-top p-2 pt-2">

  <div class="d-flex w-100 p-3" *ngIf="order?.state == 'sale'">
    <button class="btn btn-success btn-lg text-white btn-lg me-3 w-100 " (click)="openOutPicking()">
      Vai al trasferimento
    </button>
  </div>

  <button *ngIf="order?.state == 'draft' " class="btn btn-primary btn-lg text-white w-100" type="button"
    (click)="confirm(order)">
    Conferma
  </button>

  <!-- <button (click)="draft(order)" *ngIf="order?.state == 'cancel'" class="btn btn-lg btn-primary text-white w-100"
    type="button">
    Reimposta a bozza
  </button> -->

</div>
</div>
