<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <!-- <div class="offcanvas-header"> -->
    <!-- <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
       </div> -->
      <div class="offcanvas-body p-0">
        
        <div class="d-flex flex-nowrap align-items-center" id="menu">
          <input class="form-control px-2 m-2" autofocus autocomplete="off" placeHolder="Cerca per nome" [ngModel]="inputSearch.value" (ngModelChange)="inputSearch.next($event)">
          <button type="button" class="btn-close ms-auto me-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>  
  
  <div id="inventory" class=" rounded-0" >
    
    <div class=" p-0">
      <table class="table  table-hover" data-test-id="tableInventory">
        <thead class="">
        <tr>
          <th>ID</th>
          <th>Prodotto</th> 
          <!-- <th>Costo</th> -->
          <th>Vendita</th>
          <th>Disponibile</th>
          <!-- <th>Mag</th>
          <th>Libera</th> -->
        </tr>
        </thead>
        
        <tbody id="inventoryList">
            <tr
              *ngFor="let p of products"
              class="cursor-pointer text-nowrap"
              (click)="insert(p)"
              data-bs-dismiss="offcanvas"
            >
              <td class="py-3 align-middle">
                {{p.id}}
              </td>
              <td class="text-wrap align-middle">
                               {{p.display_name.replaceAll(", -","")}} 
              </td>
              <!-- <td>{{p.standard_price  | number : '1.0-3':'it-IT' }} €/{{p.uom_id.name}}</td> -->
              <td class="align-middle text-center ">{{p._lst_price ? (p._lst_price  | number : '1.0-3':'it-IT')  : '-'}}
                <br>
                 €/{{p.uom_id.name}}</td>
              <td class="align-middle text-wrap text-end">{{getFree(p) | number : '1.0-2':'it-IT'}} {{p.uom_id.name}}
                <br>
                {{getDescriptive(p, getFree(p))}}
              </td>
              <!-- <td>{{p.virtual_available | number : '1.0-2':'it-IT'}} {{p.uom_id.name}}</td> -->
            </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>

  </div>