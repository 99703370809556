<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand"> <span> Commesse </span>&nbsp; </a>

  <ng-container>
    <div class="ms-auto dropdown">
      <button class="btn btn-primary text-white dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        Nuovo
      </button>

      <div class="dropdown-menu dropdown-menu-end p-3" style="min-width: 300px;">
        <form>
          <div class="mb-3">
            <label for="sectorSelect" class="form-label fw-bold">Settore</label>
            <select id="sectorSelect" name="area" class="form-select" [(ngModel)]="newDeal.area"
              (change)="filter.next(newDeal.area)">
              <option value="" disabled selected>Seleziona un settore</option>
              <option *ngFor="let a of areas" [ngValue]="a.name">
                {{ a.name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label class="form-label fw-bold">Contatto</label>
            <app-contact-picker2 [mode]="'embedded'" class="embedded"
              (onSelect)="onContact($event)"></app-contact-picker2>
          </div>

          <div class="d-grid gap-2">
            <button [disabled]="!newDeal.partner_id.id" class="btn btn-primary" (click)="onCreate()">
              Crea nuova commessa
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</app-navbar>

<!-- Filters Section -->
<div class="bg-light px-3 py-3">
  <div class="container-fluid">

    <!-- Second Row -->
    <div class="row g-3 align-items-center ">
      <!-- Settore Buttons -->
      <div class="col-md-8">
        <div class="d-flex align-items-center flex-wrap">
          <span class="me-2">Settore:</span>
          <button (click)="filter.next('')" [ngClass]="!filter.getValue() ? 'btn-primary' : 'btn-dark'"
            class="btn ms-2">
            <i class="fa fa-asterisk"></i>
          </button>
          <button *ngFor="let area of areas" (click)="filter.next(area.name)"
            [ngClass]="filter.getValue() == area.name ? getAreaBadgeClass(area.name) : 'btn-dark'" class="btn ms-2">
            {{ area.name }}
          </button>
        </div>
      </div>

      <!-- Reset filtri Button -->
      <div class="col-md-4 d-flex justify-content-end">
        <button class="btn btn-info  text-white" (click)="resetFilters()">
          Reset filtri
        </button>
      </div>
    </div>


    <!-- First Row -->
    <div class="row g-3 align-items-center mt-2">
      <!-- Search Input -->
      <div class="col-md-4">
        <div class="input-group">
          <span class="input-group-text">Cerca</span>
          <input type="text" class="form-control" placeholder="Cliente, commessa o numero di ordine"
            [(ngModel)]="searchDealsInput" #searchInput />
        </div>
      </div>

      <!-- User Filter -->
      <div class="col-md-4">
        <div class="input-group">
          <span class="input-group-text">Utente</span>
          <select class="form-select" [(ngModel)]="selectedUser" (change)="refresh()">
            <option value="">Tutti gli utenti</option>
            <option *ngFor="let user of users" [value]="user">
              {{ user }}
            </option>
          </select>
        </div>
      </div>


      <!-- Solo recenti filter and Date Range Filter -->
      <div class="col-md-4">
        <div class="d-flex align-items-center">
          <!-- Solo recenti filter -->
          <button class="btn btn-info  me-2 text-nowrap" [ngClass]="{ 'bg-primary text-white': justRecents,
            'bg-muted text-white': !justRecents
        
        }" (click)="toggleRecents()">
            Solo recenti
          </button>

          <!-- Date Range Filter -->
          <div class="input-group">
            <span class="input-group-text">Data creazione</span>
            <input type="date" class="form-control" [(ngModel)]="startDate" (change)="refresh()" />
            <span class="input-group-text">a</span>
            <input type="date" class="form-control" [(ngModel)]="endDate" (change)="refresh()" />
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- cards section -->
<div class="w-100 trello-like p-4">
  <ul *ngFor="let stage of stages">
    <li>
      <h3>{{ stage.name }}</h3>
    </li>
    <li (click)="redirectDeal(card.id)" *ngFor="let card of filterCards(stage.id)" class="d-flex flex-column">
      <div class="d-flex flex-column">
        <!-- First row -->
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h6 class="mb-0">{{ card.tracking_code }} - {{ card.partner_id.name | uppercase }}</h6>
          <div *ngIf="card._totalContractAmount" class="badge bg-muted text-white ms-2">
            {{ card._totalContractAmount | currency:'EUR':'symbol':'1.0-0' }}
          </div>
        </div>

        <!-- Second row -->
       <!-- Second row -->
       <div class="d-flex justify-content-between align-items-center mb-3">
        <p class="mb-0 text-muted">{{ card.name }} - {{ card.city }}</p>
        <div class="badge" [ngClass]="getAreaBadgeClass(card.area)">
          {{ card.area }}
        </div>
      </div>
        <!-- Last row -->
         <div>


          <span class="badge bg-primary text-white">
            {{ card.user_id.name }}
          </span>
      </div>
</div>
</li>
</ul>
</div>