import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
import { CustomField, TrelloAttachment, TrelloCard, TrelloCardEntry2 } from "src/app/models/trello-card";
import { OdooEntityManager } from "./odoo-entity-manager.service";
import { firstValueFrom } from "rxjs";
// import { Trello } from 'trello';
declare var Trello:any;

@Injectable({
    providedIn: 'root'
})
export class TrelloService {
    private cache: any = {};
    constructor(
        private odooEM:OdooEntityManager
    ) {

    }
    getInstance() {
        return Trello
    }

    authorize() {
        return new Promise((res,rej) => {

            Trello.authorize({
                type: 'popup',
                name: 'm3.galimberti.eu',
                scope: {
                  read: 'true',
                  write: 'true' },
                expiration: 'never',
                success: async s => {
                    res(true)
                }})
        })
    }

    async create(
        title,
        list,
        fields,
        copycard?:string):Promise<TrelloCard>
    {
        return new Promise(async (res,rej) => {
            await this.authorize()

            // geocode location
            var n = new TrelloCard()
            n.name = title
            n.idList = list
            n.pos = "bottom"
            // n.address = deal.street ? deal.street : null
            // n.due = dueDate
            // n.locationName = deal.street ? deal.street : null
            // if (deal.cordinates)
            //     n.coordinates = deal.cordinates
            n.idCardSource = copycard ? copycard : null
            var c = await this.postCard(n)

            // put custom fields
            if (fields)
                for (var x = 0; x < fields.length;x++)  {
                    var element = fields[x]
                    if (element.value)
                    await this.putCustomField(c.id,element.id ,element.value, element.type)
                }
            res(c)
            
        })
    }
   

    async createAndAttach(
                    deal,
                    list,
                    type,
                    dueDate,
                    fields,
                    copycard?:string,
                    customTitle?:string,
                    origin?:string,
                    pos?: "top" | "bottom"
                ):Promise<TrelloCardEntry2> {

        return new Promise(async (res,rej) => {
            await this.authorize()

            // geocode location
            var n = new TrelloCard()
            n.name = customTitle ? customTitle : deal.getTitle()
            n.idList = list
            n.pos = pos ? pos : "bottom"
            n.address = deal?.street ? deal?.street : null
            n.due = dueDate
            n.locationName = deal?.street ? deal?.street : null
            if (deal?.cordinates)
                n.coordinates = deal.cordinates
            n.idCardSource = copycard ? copycard : null
            var c = await this.postCard(n)

            this.addMeAsMember(c.id)

            // put custom fields
            if (fields) {
                for (var x = 0; x < fields.length;x++)  {
                    var element = fields[x]
                    if (element.value && !element.isDescription)
                        this.putCustomField(c.id, element.id, element.value, element.type)
                }
            }

            // if we start from a template, handle the description "templating"
            if (copycard) {
                for (var x = 0; x < fields.length;x++)  {
                    var f = fields[x]
                    if (f.isDescription) {
                        c.desc = c.desc.replace('[[' + f.name + ']]', f.value)
                    }
                }
                await this.updateCard(c.id, 'desc', c.desc)
            }

            // link deal to card
            var t = {
                card_id : c.id,
                name : type,
                board_id : c.idBoard,
                // lead_id : deal.id,
                origin: origin
            }
            // t.deal_id = [deal.id, ""]
            // t = (await this.restapi.post2(t)) as TrelloCardEntry2
            var pt = await this.odooEM.create<TrelloCardEntry2>(new TrelloCardEntry2(),t).pipe(first()).toPromise()

            await firstValueFrom(this.odooEM.update(deal, {"trello_card_ids": [[4, pt.id]]}))


            res(pt)
        })
      }



    async updateCard(cardId, field, value) {
        var p = {}
        p[field] = value
        await Trello.put('/cards/' + cardId, p)
    }

    async addMeAsMember(cardId):Promise<TrelloCard> {
        var me = await Trello.get('/members/me')
        return await Trello.post('/card/' + cardId + '/idMembers', {value: me.id})
    }

    async getCard(id:string):Promise<TrelloCard> {
        await this.authorize()
        return await Trello.get('/cards/'+ id + '?customFieldItems=true');
    }

    async getCustomFieldsOnBoard(boardId):Promise<CustomField[]> {
        // cache
        // if (this.cache[boardId]) {
        //     console.log("CACHE")
        //     return this.cache[boardId]
        // }
        // console.log("CACHE miss ", boardId, this.cache)

        await this.authorize()
        return await Trello.get('/boards/' + boardId + '/customFields'); 
    }

    async postCard(c:TrelloCard):Promise<TrelloCard> {
        await this.authorize()
        return await Trello.post('/card', c)
    }

    async putCustomField(cardId, fieldId,value,type:"text"|"number"|"list"|"checkbox") {
        await this.authorize()
        var r
        if (type == "list")
            r = {"idValue": value}
        else if (type == "text")
            r = {"value": { "text": value }}
        else if (type == "number")
            r = {"value": { "number": value }}
        else if (type == 'checkbox')
            r = {"value": { "checked": value }}
        else if (type == 'date')
            r = {"value": { "date": value }}
        
        return await Trello.put('/card/' + cardId + '/customField/' + fieldId + "/item", r)
    }

    async postAttachments(card_id:string, as:TrelloAttachment[]) {
        for (var x = 0; x < as.length; x++) {
            var a = as[x]
            await Trello.post('/card/' + card_id + '/attachments', a)
        }
    }


}
