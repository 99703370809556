import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { SaleOrderLine } from './sale-order-line.model';
import { GenericOrder } from './generic-order';
import { OdooRelationship } from './odoo-relationship.model';
import { IConnectable, TrelloCardEntry2 } from './trello-card';
import { Lead } from './crm.lead.model';
import { CrmTag } from './crm.tag.model';
import { DriveFolder } from './drive.folder';
import { StockMove } from './stock-move';
import { StockPicking } from './stock-picking';
import { ProcurementGroup } from './procurement.group.model';
import { Contact } from './contact.model';
import { MailActivity } from './mail.message';
import { PriceList } from './price.list.model';
import { MrpProduction } from './mrp-production';
import { CrmLeadPart } from './crm.lead.part.model';



export class SaleOrderMini extends GenericOrder  {
  
  public readonly ODOO_MODEL = 'sale.order';
  public readonly ORDER_TYPE = SaleOrder;
  public readonly ORDER_TYPE_NAME = 'SaleOrder';
  public readonly ORDER_LINE_TYPE = SaleOrderLine;

  id: number;
  create_ddt: boolean;
  // ddt_ids: OdooMultiRelationship<any>;
  incoterm: OdooRelationship;
  commitment_date: string = "";
  date_order : string = ""
  note : string = ""
  opportunity_id: OdooRelationship<Lead> = new OdooRelationship<Lead>()
  client_order_ref: string  = ""
  currency_id  : OdooRelationship = new OdooRelationship<any>()
  ga_amount_total_agreed: number = 0;
  ga_order: number = 0;
  ga_address: string = ""
  pricelist_id : OdooRelationship<PriceList> = new OdooRelationship<PriceList>()

  // order_group_id:OdooRelationship<SaleOrderGroup> = new OdooRelationship<SaleOrderGroup>()
  origin:string = ""
  _moves: StockMove[];
  _sales: SaleOrder[];
  _open: boolean;
  
  picking_ids: OdooMultiRelationship<StockPicking> = null;


  ga_title:string = ""
  procurement_group_id: OdooRelationship<ProcurementGroup> = new OdooRelationship<ProcurementGroup>()
  purchase_order_count: number = 0;
  delivery_count: number = 0;
  delivery_status:""|"pending"|"partial"|"full" = ""

  ODOO_FIELDS?: string[];
  _checked;
  
  constructor(id?: number) {
    super(id);
    this.incoterm = new OdooRelationship();
    // this.ddt_ids = new OdooMultiRelationship(Placeholder);
  }
  


  create() {
    return new SaleOrderMini()
  }

}


export class SaleOrder extends GenericOrder implements IConnectable<SaleOrder> {
  
  public readonly ODOO_MODEL = 'sale.order';
  public readonly ORDER_TYPE = SaleOrder;
  public readonly ORDER_TYPE_NAME = 'SaleOrder';
  public readonly ORDER_LINE_TYPE = SaleOrderLine;

  id: number;
  confirmation_date: string;
  create_ddt: boolean;
  // ddt_ids: OdooMultiRelationship<any>;
  incoterm: OdooRelationship;
  order_line: OdooMultiRelationship<SaleOrderLine>;
  parcels: number;
  payment_note: string;
  reference: string;
  signature: boolean;
  volume: number;
  weight: number;
  commitment_date: string = "";
  date_order : string = ""
  validity_date : string = ""
  note : string = ""
  picking_policy:string = ""
  pricelist_id : OdooRelationship<PriceList> = new OdooRelationship<PriceList>()
  opportunity_id: OdooRelationship<Lead> = new OdooRelationship<Lead>()
  client_order_ref: string  = ""
  currency_id  : OdooRelationship = new OdooRelationship<any>()
  amount_untaxed: number;

  mrp_production_ids: OdooMultiRelationship<MrpProduction> = new OdooMultiRelationship<MrpProduction>(MrpProduction);
  _part_name : string = ""
  
  
  ga_amount_total_agreed: number = 0;
  ga_order: number = 0;
  ga_address: string = ""
  ga_lead_part_ids: number[] = []
  // order_group_id:OdooRelationship<SaleOrderGroup> = new OdooRelationship<SaleOrderGroup>()
  origin:string = ""
  tag_ids: OdooMultiRelationship<CrmTag> = new OdooMultiRelationship<CrmTag>(CrmTag)
  _pickings: StockPicking[];
  _moves: StockMove[];
  _sales: SaleOrder[];
  _open: boolean;
  ga_title:string = ""
  trello_ids: OdooMultiRelationship<TrelloCardEntry2> = new OdooMultiRelationship(TrelloCardEntry2);
  trello_card_ids: OdooMultiRelationship<TrelloCardEntry2> = new OdooMultiRelationship(TrelloCardEntry2);
  _procurementGroup: ProcurementGroup;
  _purchases: ProcurementGroup[];
  _delivery_state: string;
  procurement_group_id: OdooRelationship<ProcurementGroup> = new OdooRelationship<ProcurementGroup>()
  partner_invoice_id:OdooRelationship<Contact> = new OdooRelationship<Contact>();
  drive_ids: OdooMultiRelationship<DriveFolder> = new OdooMultiRelationship(DriveFolder);
  drive_folder_ids: OdooMultiRelationship<DriveFolder> = new OdooMultiRelationship(DriveFolder);
  _activity: any = null;
  activity_ids: OdooMultiRelationship<MailActivity> = new OdooMultiRelationship<MailActivity>(MailActivity);
  purchase_order_count: number = 0;
  delivery_count: number = 0;
  delivery_status:""|"pending"|"partial"|"full" = ""
  

  ODOO_FIELDS?: string[];
  _checked;
  _resolving: boolean = false;
  _resolved: boolean = false;
  _resolvedProcurement:boolean = false;


  constructor(id?: number) {
    super(id);
    this.incoterm = new OdooRelationship();
    this.order_line = new OdooMultiRelationship<SaleOrderLine>(SaleOrderLine);
    // this.ddt_ids = new OdooMultiRelationship(Placeholder);
  }
  


  create() {
    return new SaleOrder()
  }

}

export class SaleOrderFlash extends GenericOrder implements IConnectable<SaleOrder> {
  
  public readonly ODOO_MODEL = 'sale.order';
  public readonly ORDER_TYPE = SaleOrder;
  public readonly ORDER_TYPE_NAME = 'SaleOrder';

  id: number;
  confirmation_date: string;
  reference: string;
  date_order : string = ""
  note : string = ""
  opportunity_id: OdooRelationship<Lead> = new OdooRelationship<Lead>()
  origin:string = ""
  ga_title:string = ""
  trello_ids: OdooMultiRelationship<TrelloCardEntry2> = new OdooMultiRelationship(TrelloCardEntry2);
  trello_card_ids: OdooMultiRelationship<TrelloCardEntry2> = new OdooMultiRelationship(TrelloCardEntry2);
  _delivery_state: string;
  drive_ids: OdooMultiRelationship<DriveFolder> = new OdooMultiRelationship(DriveFolder);
  drive_folder_ids: OdooMultiRelationship<DriveFolder> = new OdooMultiRelationship(DriveFolder);
  _activity: any = null;
  activity_ids: OdooMultiRelationship<MailActivity> = new OdooMultiRelationship<MailActivity>(MailActivity);
  delivery_status:""|"pending"|"partial"|"full" = ""


  create() {
    return new SaleOrderFlash()
  }

}

