<div class="d-flex flex-column vh-100">
  <!-- Navbar (always shown) -->
  <app-navbar [loading]="loading" backroute="..">
    <a class="navbar-brand">
      Resto legno {{this.sale?.name}}
    </a>
  </app-navbar>

  <!-- Main content area -->
  <div class="flex-grow-1 overflow-auto p-3">
    <!-- Sale order selection -->
    <div *ngIf="!activePicking">
      <h2>Resto barre da vendita</h2>
      <label>Inserisci il numero della vendita che ha generato il resto</label>
      <div class="mt-3 d-flex align-items-center">
        <span class="pe-1">V</span>
        <input class="form-control" type="string" [(ngModel)]="saleNumber">
        <button class="btn btn-primary text-white ms-2" (click)="loadSale()">Cerca</button>
      </div>

      <div *ngIf="sale" class="mt-3">
        <div class="mb-2">
          <h5 class="mb-0">Trovato ordine:</h5>
        </div>
        <div class="mb-2">
          <h1><strong>{{sale?.partner_id?.name}}</strong></h1>
        </div>
        <div class="mb-3">
          <h1><strong>{{sale?.ga_title}}</strong></h1>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary text-white" (click)="loadPicking()">Crea resto</button>
        </div>
      </div>
    </div>

     <!-- Product selection -->
     <div *ngIf="groupedProducts.length > 0 && !activeProduct">
      <h3 class="mb-4 mt-3">Seleziona la sezione di orgine del prodotto: </h3>
      <div *ngFor="let group of groupedProducts" class="mb-5">
        <h4 class="mb-3">{{group.templateName}}</h4>
      <div class="table-responsive">
        <table class="table align-middle">
          <tbody>
            <tr *ngFor="let variant of group.variants">
              <br>
              <td *ngFor="let value of variant.variantValues" 
                (click)="onProduct(variant.product)"
                class="align-middle py-3">
              {{value}}
            </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


    <!-- Piece insert -->
    <div *ngIf="activeProduct">
      <h3>{{activeProduct.display_name}}</h3>
      <div class="table-responsive mt-3">
        <table class="table">
          <thead>
            <tr>
              <th>Lunghezza</th>
              <th>Collo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of pieces; let i = index">
              <td>
                <input type="number" class="form-control" [(ngModel)]="p.length">
              </td>
              <td>
                <input class="form-control" [(ngModel)]="p.package">
              </td>
              <td>
                <button class="btn btn-danger btn-sm" (click)="removePiece(i)" *ngIf="pieces.length > 1">
                  <i class="fa-duotone fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <button class="btn btn-sm" (click)="back()">
          <i class="fa-duotone fa-circle-chevron-left fa-4x text-dark"></i>
        </button>
        <button class="btn  btn-primary" (click)="addPiece()">
          <i class="fa-duotone fa-plus me-2"></i>Aggiungi pezzo
        </button>

        <button class="btn btn-sm" [disabled]="order?.state == 'sale'" (click)="save()">
          <i class="fa-duotone fa-circle-check fa-4x"></i>
        </button>
      </div>
    </div>
  </div>
</div>