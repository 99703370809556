import {  AfterViewInit,  Component,  ElementRef,  OnInit,  ViewChild,} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { Product } from "src/app/models/product.model";
import { ProductPackaging } from "src/app/models/product.packaging.model";
import { ProductTemplateAttributeValue } from "src/app/models/product.template.attribute.value.model";
import { StockMove } from "src/app/models/stock-move";
import { StockMoveLine } from "src/app/models/stock-move-line";
import { StockPicking } from "src/app/models/stock-picking";
import { StockQuantPackage } from "src/app/models/stock-quant-package";
import { OdooEntityManager } from "src/app/shared/services/odoo-entity-manager.service";
import * as bootstrap from "bootstrap";
import Decimal from "decimal.js";
import { StockPickingType } from "src/app/models/stock-picking-type.model";
import { StockLocation } from "src/app/models/stock-location";
import { ODOO_IDS } from "src/app/models/deal";
import {
  StockBackorderConfirmation,
  StockBackorderConfirmationLine,
} from "src/app/models/stock.backorder.confirmation.model";

@Component({
  selector: "app-picking-in",
  templateUrl: "./picking-in.component.html",
  styleUrls: ["./picking-in.component.scss"],
})
export class PickingInComponent implements OnInit, AfterViewInit {
  picking: StockPicking;
  id: any;
  loading: boolean;
  groups: any;
  // packages: any[] = []
  activePackage: StockQuantPackage;
  moveLines: StockMoveLine[];
  packagesGroup: {};
  scanningBarcode: boolean = false;
  masterLine: StockMoveLine;
  descriptiveArray: any[];
  original: any;
  addingToPackage: any;
  movesGroup: {};
  moves: StockMove[];
  packages: StockQuantPackage[];
  loadingMove: StockMove;
  offCanvas: any;
  @ViewChild("offcanvas") offCanvasElement: ElementRef<HTMLDivElement>;
  pickingTypes: StockPickingType[];
  locations: StockLocation[];
  allLocations: StockLocation[];
  rootLocation: StockLocation;
  rootLocations: string[];
  editingPackage: string | null = null;
  newPackageName: string = "";
  hasErrors: boolean = false;

  noPackage: StockQuantPackage = new StockQuantPackage();

  constructor(
    private odooEM: OdooEntityManager,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.offCanvas = new bootstrap.Offcanvas(
      this.offCanvasElement.nativeElement
    );
  }

  async ngOnInit(): Promise<void> {
    // load available picking types
    this.pickingTypes = await firstValueFrom(
      this.odooEM.search<StockPickingType>(new StockPickingType())
    );

    // this.rootLocation = ""
    this.allLocations = await firstValueFrom(
      this.odooEM.search<StockLocation>(new StockLocation(), [
        ["location_id", "=", ODOO_IDS.M],
      ])
    );
    this.rootLocations = this.getRootLocations();

    // this.allLocations = getRootLocations
    this.route.params.subscribe(async (params) => {
      this.loading = true;
      this.id = params["picking_id"];
      await this.load();
      this.loading = false;
    });
  }

  async addToExistingPackByName(packName: string) {
    await this.addToExistingPack(
      this.packages.find((p) => p?.name == packName)
    );
  }

  async addToExistingPack(pack: StockQuantPackage) {
    this.addingToPackage = pack;

    // lazy load
    // if (!this.moves) {
    // this.loading = true
    // console.log("PACKAGES", this.packages)
    // let moves = await firstValueFrom(this.odooEM.search<StockMove>(new StockMove(), [['picking_id', '=', this.picking.id]]))
    // console.log("MOVES")
    // await this.odooEM.resolveArrayOfSingle(new Product(),moves,"product_id").toPromise();

    // let prods = []
    // moves.forEach(m => {
    //     prods.push(m.product_id.value)
    // })

    // await firstValueFrom(this.odooEM.resolveArray(new ProductTemplateAttributeValue(), prods, "product_template_variant_value_ids"))
    // await firstValueFrom(this.odooEM.resolveArray(new ProductPackaging(), prods, "packaging_ids"))
    // this.moves = moves

    this.loading = false;
    // }
    this.showOffcanvas();
  }

  async addLineTo(move: StockMove) {
    // new bootstrap.Offcanvas('#offc').hide()
    // this.loadingMove = move
    this.offCanvas.hide();
    if (!this.addingToPackage) return;

    console.log("addlineto ", this.addingToPackage, move);
    this.loading = true;

    if (move.product_uom_qty - move.quantity_done > 0) {
      // crate new temp move line
      let ml = new StockMoveLine();
      ml.id = -1;
      ml.product_id.id = move.product_id.id;
      ml.product_id.value = move.product_id.value;
      ml.qty_done = move.product_uom_qty - move.quantity_done;
      ml.move_id.id = move.id;
      ml.move_id.value = move;
      ml.result_package_id = this.addingToPackage;
      ml.result_package_id.id = this.addingToPackage.id;
      ml.result_package_id.value = this.addingToPackage;
      ml.picking_id.id = move.picking_id.id;
      this.onLine(ml);
    } else {
      alert("Riga gia' completata");
    }

    // await this.load()

    // this.addToExistingPack(this.addingToPackage)
    // this.loadingMove = null
    this.loading = false;
  }

  async updateDescriptiveOr(master, d, value) {
    d[0] = value;
    let uom_qty = value * d[1].qty;

    console.log("d", d, uom_qty);
    await this.updateMaster(master, "qty_done", uom_qty);
  }

  getRootLocations(): string[] {
    var ls = [...new Set(this.allLocations.map((x) => x.name.split("-")[0]))];
    return ls;
  }

  getChildLocations(rootLocationPrefix: string) {
    return this.allLocations.filter((x) =>
      x.name.startsWith(rootLocationPrefix)
    );
  }

  async load() {
    var ps = await this.odooEM
      .search<StockPicking>(new StockPicking(), [["id", "=", this.id]])
      .toPromise();
    if (ps.length == 0) throw "Error";
    this.picking = ps[0];

    this.moveLines = await firstValueFrom(
      this.odooEM.search<StockMoveLine>(new StockMoveLine(), [
        ["picking_id", "=", this.picking.id],
      ])
    );
    await this.odooEM
      .resolveArrayOfSingle(new StockMove(), this.moveLines, "move_id")
      .toPromise();
    await this.odooEM
      .resolveArrayOfSingle(new Product(), this.moveLines, "product_id")
      .toPromise();

    let moves = [];
    this.moveLines.map((l) => {
      if (l.move_id.value) {
        if (!moves.find((x) => x.product_id.id == l.product_id.id)) {
          l.move_id.value.product_id.value = l.product_id.value;
          moves.push(l.move_id.value);
        }
      }
    });

    let prods = [];
    this.moveLines.forEach((m) => {
      prods.push(m.product_id.value);
    });

    await firstValueFrom(
      this.odooEM.resolveArray(new ProductPackaging(), prods, "packaging_ids")
    );
    await firstValueFrom(
      this.odooEM.resolveArray(
        new ProductTemplateAttributeValue(),
        prods,
        "product_template_variant_value_ids"
      )
    );
    await firstValueFrom(
      this.odooEM.resolveArrayOfSingle(
        new StockQuantPackage(),
        this.moveLines,
        "result_package_id"
      )
    );

    this.moves = moves;
    this.packages = this.moveLines.map((x) => x.result_package_id.value);
    this.packagesGroup = this.groupItemBy(
      this.moveLines,
      "result_package_id.name"
    );
  }

  groupByPackage(xs, key) {
    return xs.reduce(function (rv, x) {
      if (!x[key]) {
        x[key] = x.name;
      }
      (rv[x[key]] = rv[x[key]] || []).push(x);

      return rv;
    }, {});
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      if (!x[key]) {
        x[key] = x.name;
      }
      (rv[x["result_package_id"]["id"]] =
        rv[x["result_package_id"]["id"]] || []).push(x);

      return rv;
    }, {});
  };

  public groupItemBy = function (array, property) {
    var hash = {},
      props = property.split(".");
    for (var i = 0; i < array.length; i++) {
      if (array[i].qty_done == 0) continue;

      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);

      if (key == undefined) {
        key = "Nessuno";
      }
      if (!hash[key]) hash[key] = [];

      console.log("KEY", key, array[i]);
      hash[key].push(array[i]);
    }
    return hash;
  };

  getNoPackage() {
    if (this.packagesGroup) return this.packagesGroup["undefined"];
  }

  groupByProductTemplate = function (xs) {
    return xs.reduce(function (rv, x) {
      // if (!x.product_tmpl_id.name) {
      //   x[x.product_tmpl_id.name] = x.product_tmpl_id.name
      // }
      (rv[x.product_tmpl_id.name] = rv[x.product_tmpl_id.name] || []).push(x);

      return rv;
    }, {});
  };

  updateMaster(master, prop, val) {
    this.original = { ...master };
    master[prop] = val;
    this.descriptiveArray = this.getDescriptiveArrayOr(master);
    this.loading = false;
  }

  getDescriptive(move: StockMove, q) {
    console.log("AAAAAA getDescriptive", move, q);
    //use absolute value for calculations
    q = Math.abs(q);

    console.log("MOVE", move);
    if (q == 0) return 0;
    if (!move.product_id.value) return;
    if (!move.product_id.value.packaging_ids.values.length)
      // dont want to show
      return q + " " + move.product_id.value.uom_id.name;

    var ps = move.product_id.value.packaging_ids.values
      .slice()
      .sort((a, b) => b.qty - a.qty);
    // var q = line.move_id.value.product_uom_qty

    var d = "";

    ps = ps.filter((x) => !x.name.includes("netti") && x.purchase == true);

    var totale = new Decimal(q);

    ps.forEach((p, i) => {
      if (!p.purchase || p.name.includes("netti")) return;

      if (totale.toNumber() <= 0) return;

      let quo;
      //if last
      if (i == ps.length - 1) quo = totale.div(p.qty);
      else quo = totale.divToInt(p.qty);
      totale = totale.minus(quo.mul(p.qty));

      if (quo.toNumber() > 0)
        d =
          d +
          "" +
          Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") +
          " " +
          p.name +
          "\r\n";
    });

    return d;
  }

  // New method to remove a line
  async removeLine(line: StockMoveLine) {
    if (confirm("Sei sicuro di voler rimuovere questa riga?")) {
      this.loading = true;
      try {
        await firstValueFrom(
          this.odooEM.delete(new StockMoveLine(), [line.id])
        );
        await this.load();
      } catch (error) {
        console.error("Error removing line:", error);
        alert("Si è verificato un errore durante la rimozione della riga.");
      } finally {
        this.loading = false;
      }
    }
  }

  // New method to rename a package
  startRenamingPackage(packageName?: string) {
    this.editingPackage = packageName;
    this.newPackageName = packageName;
    console.log("xxxxxstartRenamingPackage", packageName, this.editingPackage);
  }

  async confirmRenamePackage() {
    if (this.newPackageName && this.editingPackage !== this.newPackageName) {
      if (confirm("Sei sicuro di voler rinominare questo pacco?")) {
        this.loading = true;
        try {
          let targetPackage: StockQuantPackage | null = null;
  
          // First, check if the new package name already exists
          const existingPackages = await firstValueFrom(
            this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [
              ["name", "=", this.newPackageName],
            ])
          );
  
          if (existingPackages.length > 0) {
            console.log("Package with new name already exists:", existingPackages[0]);
            targetPackage = existingPackages[0];
          }
  
          if (this.editingPackage === "Nessuno") {
            // Case: Assign unpackaged items to an existing or new package
            if (!targetPackage) {
              console.log("Creating new package:", this.newPackageName);
              targetPackage = await firstValueFrom(
                this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
                  name: this.newPackageName,
                })
              );
              console.log("New package created:", targetPackage);
            }
  
            // Assign the target package to unpackaged lines
            for (const line of this.moveLines) {
              if (!line.result_package_id || !line.result_package_id.id) {
                console.log("Updating line:", line.id, "with package:", targetPackage.id);
                await firstValueFrom(
                  this.odooEM.update<StockMoveLine>(line, {
                    result_package_id: targetPackage.id,
                  })
                );
              }
            }
          } else {
            // Case: Rename existing package
            if (targetPackage) {
              // If a package with the new name already exists, move items to that package
              console.log("Moving items to existing package:", targetPackage.id);
              const packagesToUpdate = await firstValueFrom(
                this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [
                  ["name", "=", this.editingPackage],
                ])
              );
              
              if (packagesToUpdate.length > 0) {
                for (const line of this.moveLines) {
                  if (line.result_package_id && line.result_package_id.id === packagesToUpdate[0].id) {
                    await firstValueFrom(
                      this.odooEM.update<StockMoveLine>(line, {
                        result_package_id: targetPackage.id,
                      })
                    );
                  }
                }
                // Optionally, you might want to delete the old empty package
                // await firstValueFrom(this.odooEM.delete(new StockQuantPackage(), [packagesToUpdate[0].id]));
              }
            } else {
              // If no package with the new name exists, just rename the current package
              console.log("Renaming package from", this.editingPackage, "to", this.newPackageName);
              const packagesToRename = await firstValueFrom(
                this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [
                  ["name", "=", this.editingPackage],
                ])
              );
              
              if (packagesToRename.length > 0) {
                console.log("Found package to rename:", packagesToRename[0]);
                await firstValueFrom(
                  this.odooEM.update<StockQuantPackage>(packagesToRename[0], {
                    name: this.newPackageName,
                  })
                );
              } else {
                console.error("Package not found:", this.editingPackage);
                throw new Error("Package not found");
              }
            }
          }
          
          await this.load();
          console.log("Rename operation completed successfully");
        } catch (error) {
          console.error("Error renaming package:", error);
          alert("Si è verificato un errore durante la rinomina del pacco.");
        } finally {
          this.loading = false;
          this.editingPackage = null;
        }
      }
    } else {
      this.editingPackage = null;
    }
  }

  cancelRenamePackage() {
    this.editingPackage = null;
    this.newPackageName = "";
  }

  // New method to delete a package
  async deletePackage(packageName: string) {
    if (
      confirm(
        "Sei sicuro di voler eliminare questo pacco e tutte le sue righe?"
      )
    ) {
      this.loading = true;
      try {
        const packagesToDelete = await firstValueFrom(
          this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [
            ["name", "=", packageName],
          ])
        );
        if (packagesToDelete.length > 0) {
          const packageId = packagesToDelete[0].id;
          const linesToDelete = await firstValueFrom(
            this.odooEM.search<StockMoveLine>(new StockMoveLine(), [
              ["result_package_id", "=", packageId],
              ["picking_id", "=", this.picking.id],
            ])
          );

          // Delete all lines associated with this package
          for (const line of linesToDelete) {
            await firstValueFrom(
              this.odooEM.delete(new StockMoveLine(), [line.id])
            );
          }

          // Do not delete the package itself, maybe it's used in other pickings

          await this.load();
        }
      } catch (error) {
        console.error("Error deleting package:", error);
        alert("Si è verificato un errore durante l'eliminazione del pacco.");
      } finally {
        this.loading = false;
      }
    }
  }

  getDescriptiveDone(line) {
    return this.getDescriptiveArrayOr(line).map((l) => {
      return Number.parseFloat(l[0]).toLocaleString() + " " + l[1].name;
    });
  }

  getDescriptiveMissing(line: StockMove) {
    //need to show the sign
    let d = this.getDescriptive(
      line,
      line.product_uom_qty - line.quantity_done
    );

    if (line.product_uom_qty - line.quantity_done < 0) {
      d = "-" + d;
    }

    return d;
  }

  getCompletion(move: StockMove): string {
    const totalQuantityDone = move.quantity_done;

    const requestedQuantity = Number(move.product_uom_qty);
    if (totalQuantityDone != requestedQuantity) {
      this.hasErrors = true;
      console.log("calculating total quantities for errors",totalQuantityDone, requestedQuantity);
    }

    if (totalQuantityDone === requestedQuantity) {
      return "text-success";
    } else if (totalQuantityDone < requestedQuantity) {
      return "text-warning";
    } else {
      return "text-danger";
    }
  }

  async check(f) {
    let r = await f;
    if (r.error) {
      this.loading = false;
      alert(r.error.data.message);
      throw r.error.data.message;
    }
    return r;
  }

  async confirm() {
    this.loading = true;
    this.hasErrors = false;

    //run getCompletion to check for errors for all moves
    this.moves.forEach((move) => {
      this.getCompletion(move);
    });

    if (
      this.hasErrors &&
      !confirm(
        "Ci sono delle righe con quantità diverse da quelle ordinate, vuoi continuare lo stesso?"
      )
    ) {
      this.loading = false;

      return;
    }

    if (
      !this.hasErrors &&
      !confirm("Confermi il completamento del trasferimento?")
    ) {
      this.loading = false;
      return;
    }

    let r = await this.check(
      this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [
        [this.picking.id],
      ])
    );
    if (r.result?.res_model == "stock.backorder.confirmation") {
      if (confirm(
        "Attenzione: L'ordine non è stato completato interamente.\n\n" +
        "- Premi OK per creare un ordine di consegna parziale (backorder) per gli articoli mancanti.\n" +
        "- Premi ANNULLA per chiudere l'acquisto senza creare un ordine per gli articoli mancanti."
      )){
        await this.closeWithBackorder();
      }
      else{
        await this.closeWithoutBackorder();
      }
    }

    await this.load();
    this.loading = false;
  }

  async closeWithoutBackorder() {
    var o = {
      pick_ids: [this.picking.id],
    };
    var bo = await firstValueFrom(
      this.odooEM.create<StockBackorderConfirmation>(
        new StockBackorderConfirmation(),
        o
      )
    );
    //here ia have to call process_cancel_backorder method
    await firstValueFrom(this.odooEM.call(new StockBackorderConfirmation(),"process_cancel_backorder",[bo.id],{
          button_validate_picking_ids: [this.picking.id],
        }
      )
    );

  }

  async closeWithBackorder() {
    var o = {
      pick_ids: [this.picking.id],
    };
    var bo = await firstValueFrom(
      this.odooEM.create<StockBackorderConfirmation>(
        new StockBackorderConfirmation(),
        o
      )
    );

    var ol = {
      to_backorder: true,
      backorder_confirmation_id: bo.id,
      picking_id: this.picking.id,
    };
    await firstValueFrom(
      this.odooEM.create(new StockBackorderConfirmationLine(), ol)
    );

    await firstValueFrom(
      this.odooEM.call(new StockBackorderConfirmation(), "process", [bo.id], {
        button_validate_picking_ids: [this.picking.id],
      })
    );

    this.load();
  }

  async deleteLine(line: StockMoveLine) {
    if (!confirm("Confermi eliminazione ?")) return;

    this.loading = true;
    var r = await firstValueFrom(
      this.odooEM.delete(new StockMoveLine(), [line.id])
    );
    this.masterLine = null;
    await this.load();
    this.loading = false;
  }

  onLine(line: StockMoveLine) {
    this.masterLine = line;
    this.descriptiveArray = this.getDescriptiveArrayOr(line);
  }

  scanBarcode() {
    this.scanningBarcode = true;
  }

  showOffcanvas() {
    this.offCanvas.show();
  }

  async onScanBarcode(c) {
    this.scanningBarcode = false;

    if (!c || this.packagesGroup[c]) {
      return;
    }

    this.loading = true;

    if (c == this.noPackage || c == 'nopacco') {
      this.addToExistingPack(this.noPackage);
      this.loading = false;
      return;
    }

    let r = await firstValueFrom(
      this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [
        ["name", "=", c],
      ])
    );
    if (r.length == 0) {
      // this.packagesGroup[c] = []
      let p = new StockQuantPackage();
      p.id = -1;
      p.name = c;
      // this.packages.push(this.addingTo)
      this.addToExistingPack(p);
    } else {
      // this.packagesGroup[c] = []
      this.addToExistingPack(r[0]);
    }
    this.loading = false;
  }

  back() {
    if (this.original) this.masterLine.qty_done = this.original.qty_done;
    this.original = null;
    this.masterLine = null;

    // reopen offcanvas if we are in the middle of adding to package
    if (this.addingToPackage) {
      this.showOffcanvas();
    }
  }

  getDescriptiveArrayOr(line: StockMoveLine): any[] {
    if (!line.product_id.value) return [];

    if (!line.product_id.value.packaging_ids.values) {
      return [];
    }

    var ps = line.product_id.value.packaging_ids.values
      .slice()
      .sort((a, b) => b.qty - a.qty);
    var q = line.qty_done;

    var d = "";
    var a = [];

    ps = ps.filter((x) => x.purchase);

    ps.forEach((p, i) => {
      let x = Number.parseFloat(
        new Decimal(q).div(p.qty).toPrecision(5).toString()
      ).toString();
      a.push([x, p, 0]);
    });
    return a;
  }

  async save() {
    // we need to check for -1 id .. since are temp object to persist
    this.loading = true;

    // console.log("SAAVE", this.masterLine.result_package_id.value)

    // if (this.masterLine.result_package_id.value == this.noPackage) {
    //   alert("jkj")
    // }
    // return

    if (this.masterLine.result_package_id.id == -1) {
      let r = await firstValueFrom(
        this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
          name: this.masterLine.result_package_id.value.name,
        })
      );
      this.masterLine.result_package_id.id = r.id;
    }

    // now we can save the line
    if (this.masterLine.id == -1) {
      let r = await firstValueFrom(
        this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
          qty_done: this.masterLine.qty_done,
          move_id: this.masterLine.move_id.id,
          product_id: this.masterLine.product_id.id,
          result_package_id: this.masterLine.result_package_id.id,
          picking_id: this.masterLine.picking_id.id,
        })
      );
      this.masterLine.id = r.id;
    } else {
      await firstValueFrom(
        this.odooEM.update<StockMoveLine>(this.masterLine, {
          qty_done: this.masterLine.qty_done,
          // 'price_unit' : this.masterLine.price_subtotal / this.masterLine.product_uom_qty
        })
      );
      this.masterLine = null;
    }

    await this.load();

    // reopen offcanvas if we are in the middle of adding to package
    if (this.addingToPackage) {
      this.showOffcanvas();
    }
    this.masterLine = null;
    this.loading = false;
  }
}
