import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { Lead } from "../models/crm.lead.model";
import { AccountMove } from "../models/account-move.model";
import { AccountMoveLine } from "../models/account-move-line.model";
import { Product } from "../models/product.model";
import { OdooEntityManager } from "../shared/services/odoo-entity-manager.service";
import { ODOO_IDS } from "../models/deal";
import { firstValueFrom } from "rxjs";
import { AccountTax } from "../models/account-tax.model";
import { MailActivity } from "../models/mail.message";
import { AccountPaymentTerm } from "../models/account-payment-term.model";

@Component({
  selector: "app-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent implements OnInit, AfterViewInit {
  @Input() lead: Lead;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  @Input() change: EventEmitter<boolean> = new EventEmitter();
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  // una var srtring che puo essere "pippo" o "pluto"
  collapseContract = "collapsed" as "collapsed" | "expanded" | "full";
  collapseSal = "collapsed" as "collapsed" | "expanded" | "full";
  contractProducts: Product[] = [];
  invoicesProducts: Product[] = [];
  paymentTerms: AccountPaymentTerm[] = [];
  taxes: AccountTax[] = [];
  allInvoices: AccountMove[];
  contrattiInvoices: AccountMove[] = [];
  salInvoices: AccountMove[] = [];
  toggledContratti: boolean = false;
  toggledSal: boolean = false;


  O_ids = ODOO_IDS;

  // totals
  totalContract: number = 0;
  totalSAL: number = 0;
  totalSALPosted: number = 0;
  toAdd: number = 0;
  toInvoice: number = 0;
  garanziaBehind: number = 0;

  constructor(private odooEM: OdooEntityManager) {}

  async ngAfterViewInit(): Promise<void> {
    await this.loadInvoices();
    await this.loadContractProducts();
    await this.loadInvoicesProducts();
    await this.loadTaxes();
    await this.loadPaymentTerms();
  }

  async ngOnInit() {
    // Any initialization logic

    this.odooEM.messages.subscribe((x) => {
      // if (x == "AccountMove") {
      // }
    });
  }

  async loadInvoices() {
    console.log("LEAD from CONTRACTS!", this.lead);
    this.loading.emit(true);
    try {
      this.allInvoices = await firstValueFrom(
        this.odooEM.search<AccountMove>(new AccountMove(), [
          ["invoice_origin", "=", this.lead.tracking_code.toString()],
        ])
      );

      // this.contrattiInvoices = allInvoices.filter(inv => inv.journal_id[0] === ODOO_IDS.contratti_id);
      // this.salInvoices = allInvoices.filter(inv => inv.journal_id[0] === ODOO_IDS.sal_id);

      let contrattiInvoices = this.allInvoices.filter(
        (inv) => inv.journal_id[0] === ODOO_IDS.contratti_id
      );
      let salInvoices = this.allInvoices.filter(
        (inv) => inv.journal_id[0] === ODOO_IDS.sal_id
      );

      // Resolve activities for SAL Invoices
      await firstValueFrom(
        this.odooEM.resolveArray(
          new MailActivity(),
          salInvoices,
          "activity_ids"
        )
      );

      // Resolve invoice lines for all invoices
      await firstValueFrom(
        this.odooEM.resolveArray(
          new AccountMoveLine(),
          this.allInvoices,
          "invoice_line_ids"
        )
      );

      // Resolve payment terms for all invoices
      await firstValueFrom(
        this.odooEM.resolveArrayOfSingle(
          new AccountPaymentTerm(),
          this.allInvoices,
          "invoice_payment_term_id"
        )
      );

      // Resolve product and taxes for all invoice lines
      const lines = this.allInvoices.flatMap(
        (invoice) => invoice.invoice_line_ids.values
      );
      await firstValueFrom(
        this.odooEM.resolveArrayOfSingle(new Product(), lines, "product_id")
      );
      await firstValueFrom(
        this.odooEM.resolveArray(new AccountTax(), lines, "tax_ids")
      );

      contrattiInvoices = contrattiInvoices.sort(
        (a, b) =>
          new Date(a.invoice_date).getTime() -
          new Date(b.invoice_date).getTime()
      );

      salInvoices = salInvoices.sort(
        (a, b) =>
          new Date(a.invoice_date).getTime() -
          new Date(b.invoice_date).getTime()
      );

      this.contrattiInvoices = contrattiInvoices;
      this.salInvoices = salInvoices;

      await this.calculateTotals();
    } catch (error) {
      console.error("Error loading invoices:", error);
    } finally {
      this.loading.emit(false);
    }

    console.log(
      "INVOCIES ARE TOGGLED? ",
      this.toggledContratti,
      this.toggledSal
    );
  }

  async loadContractProducts() {
    this.loading.emit(true);
    try {
      this.contractProducts = await firstValueFrom(
        this.odooEM.search<Product>(new Product(), [
          ["categ_id", "in", [ODOO_IDS.contratti_categ_id]],
        ])
      );
    } catch (error) {
      console.error("Error loading contract products:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async loadInvoicesProducts() {
    this.loading.emit(true);
    try {
      this.invoicesProducts = await firstValueFrom(
        this.odooEM.search<Product>(new Product(), [
          ["categ_id", "in", [ODOO_IDS.fatture_categ_id]],
        ])
      );
    } catch (error) {
      console.error("Error loading invoices products:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async loadTaxes() {
    this.loading.emit(true);
    try {
      this.taxes = await firstValueFrom(
        this.odooEM.search<AccountTax>(new AccountTax(), [
          ["type_tax_use", "in", ["sale"]],
        ])
      );
    } catch (error) {
      console.error("Error loading taxes:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async loadPaymentTerms() {
    this.loading.emit(true);
    try {
      this.paymentTerms = await firstValueFrom(
        this.odooEM.search<AccountPaymentTerm>(new AccountPaymentTerm(), [
          ["active", "=", true]
        ])
      );
    } catch (error) {
      console.error("Error loading payment terms:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  toggleContratti() {
    console.log(this.collapseContract);
    if (this.collapseContract == "collapsed")
      this.collapseContract = "expanded";
    else if (this.collapseContract == "expanded") {
      if (this.contrattiInvoices.length > 0) {
        this.contrattiInvoices.forEach((invoice) => (invoice._expanded = true));
        this.collapseContract = "full";
      }
    } else if (this.collapseContract == "full") {
      this.collapseContract = "collapsed";
      if (this.contrattiInvoices.length > 0) {
        this.contrattiInvoices.forEach(
          (invoice) => (invoice._expanded = false)
        );
      }
    }
  }
  toggleSal() {
    console.log(this.collapseSal);
    if (this.collapseSal == "collapsed") this.collapseSal = "expanded";
    else if (this.collapseSal == "expanded") {
      if (this.salInvoices.length > 0) {
        this.salInvoices.forEach((invoice) => (invoice._expanded = true));
        this.collapseSal = "full";
      }
    } else if (this.collapseSal == "full") {
      this.collapseSal = "collapsed";
      {
        if (this.salInvoices.length > 0) {
          this.salInvoices.forEach((invoice) => (invoice._expanded = false));
        }
      }
    }
  }


  toggleInvoiceDetails(invoice: AccountMove) {
    invoice._expanded = !invoice._expanded
  }

  async createInvoice(journalId: number) {
    this.loading.emit(true);
    try {
      const newInvoice = new AccountMove();
      const today = new Date().toISOString().split("T")[0];
      const invoiceData = {
        invoice_origin: this.lead.tracking_code.toString(),
        invoice_date: today,
        journal_id: journalId,
        partner_id: this.lead.partner_id.id,
        state: "draft",
        move_type: "out_invoice",
        invoice_line_ids: [
          [
            0,
            0,
            {
              quantity: 1,
              price_unit: 1,
              // if journalId is in contrattiInvoices, use the first product in contractProducts
              // otherwise use the first product in invoicesProducts

              product_id: journalId === ODOO_IDS.contratti_id ? this.contractProducts[0].id : this.invoicesProducts[0].id,
              name: "",
            },
          ],
        ],
      };
      // Create the new invoice
      const createdInvoice = await firstValueFrom(
        this.odooEM.create<AccountMove>(newInvoice, invoiceData)
      );

      // Reload invoices to get the updated list
      await this.loadInvoices();


      // Find the newly created invoice in the refreshed list
      const invoiceToExpand = this.allInvoices.find(
        (inv) => inv.id === createdInvoice.id
      );

      //If the invoice is in the contratti journal and Contratti is collapsed, expand it
      if (journalId === ODOO_IDS.contratti_id && this.collapseContract == "collapsed") {
        this.collapseContract = "expanded";
      }
      else if (journalId === ODOO_IDS.sal_id && this.collapseSal == "collapsed") {
        this.collapseSal = "expanded";
      }

      if (invoiceToExpand) {
        invoiceToExpand._expanded = true;
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async addInvoiceLine(invoice: AccountMove) {
    this.loading.emit(true);
    try {
      const newLine = new AccountMoveLine();
      const lineData = {
        move_id: invoice.id,
        quantity: 1,
        price_unit: 1,
        // if invoice.id is in contrattiInvoices, use the first product in contractProducts
        // otherwise use the first product in invoicesProducts
        product_id: invoice.journal_id[0] === ODOO_IDS.contratti_id ? this.contractProducts[0].id : this.invoicesProducts[0].id,
        name: "",
      };
      const createdLine = await firstValueFrom(
        this.odooEM.create<AccountMoveLine>(newLine, lineData)
      );
      // Resolve the move_id relation on the created line
      await firstValueFrom(
        this.odooEM.resolveSingle(new AccountMove(), createdLine.move_id)
      );

      // Add the created line to the invoice's line items
      invoice.invoice_line_ids.ids.push(createdLine.id);
      invoice.invoice_line_ids.values.push(createdLine);

      // Call updateLine to refresh the line data
      await this.updateLine(createdLine);
    } catch (error) {
      console.error("Error adding invoice line:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async removeLine(invoice: AccountMove, line: AccountMoveLine) {
    if (confirm("Sei sicuro di voler rimuovere questa riga?")) {
      this.loading.emit(true);
      try {
        await firstValueFrom(
          this.odooEM.delete(new AccountMoveLine(), [line.id])
        );
        invoice.invoice_line_ids.values =
          invoice.invoice_line_ids.values.filter((l) => l.id !== line.id);
        this.refreshMoveFromLine(line);
      } catch (error) {
        console.error("Error removing invoice line:", error);
      } finally {
        this.loading.emit(false);
      }
    }
  }

  // dirty way to refresh quickly the move when a line change
  async refreshMoveFromLine(line: AccountMoveLine) {
    let m = this.allInvoices.find((c) => c.id == line.move_id.id);

    let res = await firstValueFrom(
      this.odooEM.search(new AccountMove(), [["id", "=", m.id]])
    );
    if (!res.length || res.length == 0) return;
    let freshm = res[0];

    console.log("xx fersh", freshm);
    Object.keys(freshm).forEach((k) => {
      console.log("xx1 ", k);
      // update what is not a relation and not an internal state _
      if (!(freshm[k] instanceof Object) && k[0] != "_") {
        console.log("xx2 ", k, freshm[k] instanceof Object);
        m[k] = freshm[k];
      }
      // console.log("XX",k, freshm[k])
    });
  }

  async updateLine(line: AccountMoveLine) {
    this.loading.emit(true);

    try {
      const updatedLine = await firstValueFrom(
        this.odooEM.search<AccountMoveLine>(new AccountMoveLine(), [
          ["id", "=", line.id],
        ])
      );

      await firstValueFrom(this.odooEM.resolve(updatedLine[0].tax_ids));

      if (updatedLine && updatedLine.length > 0) {
        Object.keys(line).forEach((k) => {
          // update what is not a relation and not an internal state _
          if (k[0] != "_") {
            console.log(
              "xx2 ",
              updatedLine,
              k,
              updatedLine[0][k] instanceof Object
            );
            line[k] = updatedLine[0][k];
          }
        });
      }
      //Object.assign(line, updatedLine[0]);

      // refresh parent move totals
      await this.refreshMoveFromLine(line);

      this.calculateTotals();
    } catch (error) {
      console.error("Error updating line:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async updateProduct(line: AccountMoveLine, newProductId: number) {
    this.loading.emit(true);
    try {
      // Store the current line data
      const currentLineData = {
        price_unit: line.price_unit,
        tax_ids: line.tax_ids,
        name: line.name
      };

      //search product in contracts or invoices products
  
      const newProduct = this.contractProducts.find( p => p.id === newProductId) || this.invoicesProducts.find( p => p.id === newProductId);
      if (!newProduct) {
        throw new Error("Selected product not found in contract products");
      }
  
      // Update the product
      await firstValueFrom(
        this.odooEM.update(line, {
          product_id: newProductId,
          product_uom_id: newProduct.uom_id?.id,
        })
      );
  
      // Fetch the updated line to get any server-side changes
      const updatedLines = await firstValueFrom(
        this.odooEM.search<AccountMoveLine>(new AccountMoveLine(), [
          ["id", "=", line.id],
        ])
      );
  
      if (updatedLines && updatedLines.length > 0) {
        const updatedLine = updatedLines[0];
  
        // Merge the updated line with our preserved data
        const mergedData = {
          //...updatedLine,
          price_unit: currentLineData.price_unit,
          tax_ids: currentLineData.tax_ids.ids,
          name: currentLineData.name
        };
  
        // Update the line with our merged data
        await firstValueFrom(
          this.odooEM.update(line, mergedData)
        );
  
        // Update the local line object
        //Object.assign(line, mergedData);
        line.price_unit = mergedData.price_unit;
        line.name = mergedData.name;
        line.tax_ids.ids = mergedData.tax_ids;
  
        // Resolve any relations that might have changed
        await firstValueFrom(this.odooEM.resolve(line.tax_ids));
  
        // Refresh the parent move totals
        await this.refreshMoveFromLine(line);
  
        // Recalculate totals
        this.calculateTotals();
      }
  
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async updateNarration(invoice: AccountMove, newNarration: string) {
    this.loading.emit(true);
    try {
      await firstValueFrom(
        this.odooEM.update(invoice, { narration: newNarration })
      );
      invoice.narration = newNarration;
    } catch (error) {
      console.error("Error updating narration:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  // Add method to update payment term
  async updatePaymentTerm(invoice: AccountMove, newPaymentTermId: number) {
    this.loading.emit(true);
    try {
      await firstValueFrom(
        this.odooEM.update(invoice, { 
          invoice_payment_term_id: newPaymentTermId 
        })
      );
      // Update local invoice object
      invoice.invoice_payment_term_id.id = newPaymentTermId;
      // Resolve the payment term relationship
      await firstValueFrom(
        this.odooEM.resolveSingle(
          new AccountPaymentTerm(), 
          invoice.invoice_payment_term_id
        )
      );
    } catch (error) {
      console.error("Error updating payment term:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async updateName(line: AccountMoveLine, newName: string) {
    this.loading.emit(true);
    try {
      await firstValueFrom(this.odooEM.update(line, { name: newName }));
      line.name = newName;
    } catch (error) {
      console.error("Error updating name:", error);
    } finally {
      this.loading.emit(false);
    }
  }
  // async updateQuantity(line: AccountMoveLine, newQuantity: number) {
  //   this.loading.emit(true);
  //   try {
  //     await firstValueFrom(this.odooEM.update(line, { quantity: newQuantity }));
  //     await this.updateLine(line);
  //   } catch (error) {
  //     console.error("Error updating quantity:", error);
  //   } finally {
  //     this.loading.emit(false);
  //   }
  // }

  async updatePrice(line: AccountMoveLine, newPrice: number) {
    this.loading.emit(true);
    try {
      // Find the invoice associated with the line
      const invoice = this.allInvoices.find(
        (inv) => inv.id === line.move_id.id
      );

      await firstValueFrom(this.odooEM.update(line, { price_unit: newPrice }));
      await this.updateLine(line);
    } catch (error) {
      console.error("Error updating price:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  // async updateDiscount(line: AccountMoveLine, newDiscount: number) {
  //   this.loading.emit(true);
  //   try {
  //     await firstValueFrom(this.odooEM.update(line, { discount: newDiscount }));
  //     await this.updateLine(line);
  //   } catch (error) {
  //     console.error("Error updating discount:", error);
  //   } finally {
  //     this.loading.emit(false);
  //   }
  // }

  async updateTax(line: AccountMoveLine, newTaxId: number | null) {
    this.loading.emit(true);
    try {
      // Find the invoice associated with the line
      const invoice = this.allInvoices.find(
        (inv) => inv.id === line.move_id.id
      );

      const taxIds = newTaxId ? [[6, 0, [newTaxId]]] : [[6, 0, []]];
      await firstValueFrom(this.odooEM.update(line, { tax_ids: taxIds }));
      await this.updateLine(line);
    } catch (error) {
      console.error("Error updating tax:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async updateInvoiceDate(invoice: AccountMove, newDate: string) {
    this.loading.emit(true);
    try {
      await firstValueFrom(
        this.odooEM.update(invoice, { invoice_date: newDate })
      );
      invoice.invoice_date = newDate;
    } catch (error) {
      console.error("Error updating invoice date:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async toggleInvoiceActivity(invoice: AccountMove) {
    this.loading.emit(true);
  try {
    if (invoice.activity_ids?.ids?.length) {
      if (confirm("Sei sicuro di voler rimuovere questa attività?")) {
        await firstValueFrom(
          this.odooEM.delete(new MailActivity(), invoice.activity_ids.ids)
        );
        invoice.activity_ids.ids = [];
        invoice.activity_ids.values = [];
      }
    } else {
      // Check if tax is set in every line
      const taxNotSet = invoice.invoice_line_ids.values.some(line => line.tax_ids.ids.length === 0);
      
      if (taxNotSet) {
        alert("Attenzione: non è stata impostata l'aliquota IVA su tutte le righe della fattura.");
        return;
      }

      // If all taxes are set, ask for confirmation
      if (confirm("Aggiungere questa fattura a quelle da emettere? Verrà notificata l'amministrazione")) {
          const activity = {
            res_model_id: 446,
            res_id: invoice.id,
            res_model: "account.move",
            activity_type_id: 4,
            date_deadline: invoice.invoice_date.toString(),
            user_id: 2,
            res_name: invoice.invoice_origin,
          };

          const createdActivity = await firstValueFrom(
            this.odooEM.create<MailActivity>(new MailActivity(), activity)
          );
          invoice.activity_ids.ids = [createdActivity.id];
          invoice.activity_ids.values = [createdActivity];
        }
      }
    } catch (error) {
      console.error("Error toggling invoice activity:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  async completeInvoiceActivity(invoice: AccountMove) {
    this.loading.emit(true);
    try {
      if (
        confirm(
          "Stai confermando la fatturazione. Potrai poi modificare solo la descrizione. Procedi?"
        )
      ) {
        if (invoice.activity_ids.ids.length > 0) {
          await firstValueFrom(
            this.odooEM.call(new MailActivity(), "action_done", [
              invoice.activity_ids.ids[0],
            ])
          );
          invoice.activity_ids.ids = [];
          invoice.activity_ids.values = [];
        }

        await firstValueFrom(this.odooEM.update(invoice, { state: "posted" }));
        invoice.state = "posted";
      }
      this.calculateTotals();

    } catch (error) {
      console.error("Error completing invoice activity:", error);
    } finally {
      this.loading.emit(false);
    }

  }

  async removeInvoice(invoice: AccountMove) {
    if (confirm("Sei sicuro di voler rimuovere questa fattura?")) {
      this.loading.emit(true);
      try {
        await firstValueFrom(
          this.odooEM.delete(new AccountMove(), [invoice.id])
        );
        this.contrattiInvoices = this.contrattiInvoices.filter(
          (inv) => inv.id !== invoice.id
        );
        this.salInvoices = this.salInvoices.filter(
          (inv) => inv.id !== invoice.id
        );
        this.calculateTotals();
      } catch (error) {
        console.error("Error removing invoice:", error);
      } finally {
        this.loading.emit(false);
      }
    }
  }

  calculateTaxAmount(line: AccountMoveLine): number {
    const taxAmount = line.price_total - line.price_subtotal;
    line._tax_amount = taxAmount;
    return taxAmount;
  }

  calculateTotals() {
    // Total amount from contracts and variants (untaxed)
    this.totalContract = this.contrattiInvoices.reduce(
      (acc, inv) => acc + inv.amount_untaxed,
      0
    );

    // Total amount from all SAL invoices (untaxed)
    this.totalSAL = this.salInvoices.reduce(
      (acc, inv) => acc + inv.amount_untaxed,
      0
    );

    // Total amount from posted (confirmed) SAL invoices (untaxed)
    this.totalSALPosted = this.salInvoices
      .filter((inv) => inv.state === "posted")
      .reduce((acc, inv) => acc + inv.amount_untaxed, 0);

    // Calculate 'toAdd' and 'toInvoice'
    this.toAdd = this.totalContract - this.totalSAL;
    this.toInvoice = this.totalContract - this.totalSALPosted;

    // Handle negative values
    if (this.toAdd < 0) {
      this.toAdd = 0;
    }
    if (this.toInvoice < 0) {
      this.toInvoice = 0;
    }

    //totale rit a garanzia = totale articolo con id = garanzia_id nella sezione SAL
    
  this.garanziaBehind = -1 * this.salInvoices
  .filter((inv) => inv.state === "posted")
  .reduce((acc, inv) => {
    console.log('xxxxProcessing invoice:', inv.id);
    const garanziaTotals = inv.invoice_line_ids.values
      .filter((l) => {
        const isGaranzia = l.product_id.id && l.product_id.id === ODOO_IDS.garanzia_id;
        console.log('xxxLine:', l.id, 'Is Garanzia:', isGaranzia, 'Product ID:', l.product_id?.id);
        return isGaranzia;
      })
      .reduce((lineAcc, line) => {
        console.log('xxxxGaranzia line:', line.id, 'Price Total:', line.price_unit);
        return lineAcc + (line.price_unit || 0);
      }, 0);
    console.log('xxxxInvoice Garanzia Total:', garanziaTotals);
    return acc + garanziaTotals;
  }, 0);

console.log('xxxFinal garanziaBehind:', this.garanziaBehind);

      console.log("DEAL TOTALS", this.totalContract, this.totalSAL, this.totalSALPosted, this.toAdd, this.toInvoice, this.garanziaBehind);


  }

  copyContrattiToClipboard() {
    const data = this.formatInvoicesData(this.contrattiInvoices);
    this.copyToClipboard(data);
  }

  copySalToClipboard() {
    const data = this.formatInvoicesData(this.salInvoices);
    this.copyToClipboard(data);
  }

  private formatInvoicesData(invoices: AccountMove[]): string {
    let tableData = 'Data\tDescrizione\tImponibile\tIVA\tTotale\tStato\n';
  
    invoices.forEach(invoice => {
      tableData += `${this.formatDate(invoice.invoice_date)}\t`;
      tableData += `${this.cleanAndEscapeText(invoice.narration || 'N/A')}\t`;
      tableData += `${this.formatNumber(invoice.amount_untaxed)}\t`;
      tableData += `${this.formatNumber(invoice.amount_tax)}\t`;
      tableData += `${this.formatNumber(invoice.amount_total)}\t`;
      tableData += `${this.getInvoiceState(invoice.state)}\n`;
  
      // Add invoice lines
      invoice.invoice_line_ids.values.forEach(line => {
        tableData += `${this.cleanAndEscapeText(line.product_id ? line.product_id.name : 'N/A')}\t`;
        tableData += `${this.cleanAndEscapeText(line.product_id ? line.name : 'N/A')}\t`;
        tableData += `${this.formatNumber(line.price_unit)}\t`;
        tableData += `${line.tax_ids.values.length > 0 ? line.tax_ids.values[0].name : 'N/A'}\t`;
        tableData += `${this.formatNumber(line.price_total)}\t\n`;
      });
  
      // Add an empty line between invoices for better readability
      tableData += '\n';
    });
  
    return tableData;
  }
  
  private formatDate(date: string): string {
    if (!date) return 'N/A';
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  }
  
  private formatNumber(amount: number): string {
    // Format the number to 2 decimal places and replace the period with a comma
    return amount.toFixed(2).replace('.', ',');
  }
  
  private getInvoiceState(state: string): string {
    const states = {
      'draft': 'Bozza',
      'posted': 'Confermata',
      'cancel': 'Annullata'
    };
    return states[state] || state;
  }
  
  private cleanAndEscapeText(text: string): string {
    // Remove HTML tags
    let cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");
    // Replace tabs and newlines with spaces
    cleanText = cleanText.replace(/\t/g, ' ').replace(/\n/g, ' ');
    // Trim extra spaces
    cleanText = cleanText.replace(/\s+/g, ' ').trim();
    return cleanText;
  }

  private copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Optional: Show a notification that the data has been copied
    alert('Dati copiati negli appunti');
  }



}
